import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import analysisReducer from './analysis/analysis.slice';
import authReducer, { authPersistConfig } from './auth/auth.slice';
import boardFiltersReducer from './board-filters/board-filters.slice';
import boardsReducer, { boardsPersistConfig } from './boards/boards.slice';
import intlReducer, { intlPersistConfig } from './intl/intl.slice';
import jobQueueReducer, { jobQueuePersistConfig } from './job-queue/job-queue.slice';
import maintenanceMessagesReducer, {
  maintenanceMessagesPersistConfig,
} from './maintenance-messages/maintenance-messages.slice';
import newsobjectSearchReducer from './newsobject-search/newsobject-search.slice';
import recentlyUsedReducer, {
  recentlyUsedPersistConfig,
} from './recently-used/recently-used.slice';
import searchReducer, { searchPersistConfig } from './search/search.slice';
import settingsReducer, { settingsPersistConfig } from './settings/settings.slice';
import topicFiltersReducer, {
  topicFiltersPersistConfig,
} from './topic-filters/topic-filters.slice';
import upsellReducer, { upsellPersistConfig } from './upsell/upsell.slice';

const reducers = combineReducers({
  analysis: analysisReducer,
  recentlyUsed: persistReducer(recentlyUsedPersistConfig, recentlyUsedReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  maintenanceMessages: persistReducer(maintenanceMessagesPersistConfig, maintenanceMessagesReducer),
  search: persistReducer(searchPersistConfig, searchReducer),
  boards: persistReducer(boardsPersistConfig, boardsReducer),
  boardFilters: boardFiltersReducer,
  topicFilters: persistReducer(topicFiltersPersistConfig, topicFiltersReducer),
  newsobjectSearch: newsobjectSearchReducer,
  intl: persistReducer(intlPersistConfig, intlReducer),
  settings: persistReducer(settingsPersistConfig, settingsReducer),
  upsell: persistReducer(upsellPersistConfig, upsellReducer),
  jobQueues: persistReducer(jobQueuePersistConfig, jobQueueReducer),
});

export type RootReducer = typeof reducers;
export type Store = ReturnType<typeof reducers>;

export default reducers;
