import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AppBarStyled = styled(Grid)(({ theme }) => ({
  flex: '0 0 auto',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    maxHeight: 0,
  },

  '@media print': {
    display: 'none',
  },
}));
