import React, { Suspense } from 'react';
import { Switch } from 'react-router';
import { Router } from 'react-router-dom';

import { isDev } from 'utils/env.utils';

import { history } from 'services';

import { GenericErrorBoundary } from 'components/@boundaries';
import { RouteProtected, RoutePublic } from 'components/@common/Route';
import ApplicationContainer from 'components/@screen/ApplicationContainer';
import { LoadingState } from 'components/@state/LoadingState';

import error from './error';
import routes from './routes';

const NotFound = React.lazy(() => import('./not-found'));

const Pages = () => (
  <GenericErrorBoundary boundary="pages" fullscreen>
    <Router history={history}>
      <ApplicationContainer>
        <Suspense fallback={<LoadingState />}>
          <Switch>
            {routes.map(route => {
              const routeProps = {
                exact: route.exact,
                path: route.path,
                component: route.component,
                authenticated: route.authenticated,
                userFeatureFlag: route.userFeatureFlag,
              };

              if (route.authenticated) {
                return <RouteProtected {...routeProps} key={route.path?.toString()} />;
              } else {
                return <RoutePublic {...routeProps} key={route.path?.toString()} />;
              }
            })}
            <RoutePublic component={NotFound} />
            {isDev() && <RoutePublic exact path={error.path} component={error.component} />}
          </Switch>
        </Suspense>
      </ApplicationContainer>
    </Router>
  </GenericErrorBoundary>
);

export default Pages;
