import React from 'react';

import { UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

const componentPromise = import('./NewsroomsRoute');
const Component = React.lazy(() => componentPromise);

export default {
  component: Component,
  exact: true,
  path: Routes.KioskNewsrooms,
  authenticated: true,
  userFeatureFlag: UserFeatureFlags.KIOSK_NEWSROOMS,
};
