export enum MediaType {
  File = 'file',
  Media = 'media',
}

export enum MediaFileType {
  'DOCX' = 'DOCX',
  'XLSX' = 'XLSX',
  'PNG' = 'PNG',
  'JPG' = 'JPG',
  'PDF' = 'PDF',
}

export type MediaFile = {
  id: number;
  url: string;
  fileName: string;
  fileSize: number;
  fileType: MediaFileType;
};

export type Media = {
  id: number;
  url: string;
};

export type UploadMediaParams = {
  file: File;
  type: MediaType;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
};
