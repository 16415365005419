import { Components, tableSortLabelClasses, Theme } from '@mui/material';

export const MuiTableSortLabel: Components<Theme>['MuiTableSortLabel'] = {
  styleOverrides: {
    root: () => ({
      '&:hover': {
        opactiy: 1,
        [`&.${tableSortLabelClasses.icon}`]: {
          opacity: 1,
        },
      },
      '&.Mui-active': {
        opacity: 1,
        fontWeight: 'bold',
      },
      '& icon': {
        opacity: 1,
        '&:hover': {
          opacity: 1,
        },
      },
    }),
  },
};
