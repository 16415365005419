import { Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import { User } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

import Logo from 'components/@navigation/Logo';
import { MainMenu, SecondaryMenu } from 'components/@navigation/Menu';
import QuickActions from 'components/@navigation/QuickActions';
import UserSettings from 'components/@navigation/UserSettings';

import { InnerContainer, Wrap } from './Web.style';

interface Props {
  showAppBarContents: boolean;
  user: User;
}

const Web = ({ showAppBarContents, user }: Props) => {
  return (
    <Wrap>
      <InnerContainer alignItems="center">
        <Box ml={2} display="inline-block">
          <Link to={Routes.KioskFrontPage}>
            <Logo />
          </Link>
        </Box>
        {showAppBarContents && (
          <>
            <Box>
              <MainMenu />
            </Box>
            {!!user && (
              <Stack direction="row" alignItems="center" justifyContent="flex-end">
                <QuickActions />
                <UserSettings user={user} />
              </Stack>
            )}
          </>
        )}
      </InnerContainer>
      <SecondaryMenu />
    </Wrap>
  );
};

export default Web;
