import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

type BoardsState = {
  skipInfoStep: boolean;
};

const INITIAL_STATE: BoardsState = {
  skipInfoStep: false,
};

export const boardsPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.boards`,
  version: 1,
  storage,
};

const boardsSlice = createSlice({
  name: 'boards',
  initialState: INITIAL_STATE,
  reducers: {
    setSkipInfoStep: (state, action: PayloadAction<boolean>) => {
      state.skipInfoStep = action.payload;
    },
  },
});

export const { actions: boardsActions } = boardsSlice;

export default boardsSlice.reducer;
