import React, { useCallback, useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useCurrentUser } from 'queries/user/useCurrentUser';
import { useFeatures } from 'services/feature-flags/hooks';

import { Routes } from 'pages/routes.constants';
import { Route as RouteProps } from 'pages/routes.types';

import { getRouteProtection, Unauthorized } from './route-protection';

const RouteProtected = ({ path, userFeatureFlag, ...rest }: RouteProps) => {
  const user = useCurrentUser();
  const features = useFeatures();
  const isEnabled = useMemo(
    () => (userFeatureFlag ? features?.isEnabled(userFeatureFlag) : true),
    [features, userFeatureFlag],
  );

  const checkAuthorized = useCallback(() => {
    if (!user || !features?.flags?.length) {
      return Unauthorized;
    }
    return getRouteProtection(path, features?.flags);
  }, [features?.flags, path, user]);

  // If the feature is not enabled, redirect to not found
  if (!isEnabled) return <Redirect to={Routes.NotFound} />;

  const { isAuthorized, redirectTo } = checkAuthorized();
  return isAuthorized ? <Route path={path} {...rest} /> : <Redirect to={redirectTo} />;
};

export default React.memo(RouteProtected);
