import type { CancelToken } from 'axios';

import { MaintenanceMessage } from 'types/maintenance-message.types';

import RestApiService from '.';

export function fetchMaintenanceMessages(this: RestApiService, cancelToken?: CancelToken) {
  return this.api.get<MaintenanceMessage>('/maintenancemessages', {
    params: {
      platform: 'WEB',
    },
    cancelToken,
  });
}
