import { SnackbarTypes } from 'types/notification.types';

import Snackbar from 'components/@common/Snackbar';
import { IconNames } from 'components/@icons';

import { NotificationWithType } from './types';

const iconLookup: Record<SnackbarTypes, IconNames> = {
  [SnackbarTypes.Warning]: 'IcoWarning',
  [SnackbarTypes.Error]: 'IcoWarning',
  [SnackbarTypes.Success]: 'IcoCheckCircle',
  [SnackbarTypes.Info]: 'IcoInfo',
};

interface Props {
  notification: NotificationWithType;
}

const DefaultNotification = ({ notification }: Props) => (
  <Snackbar
    type={notification.type}
    title={notification.title}
    message={notification.message}
    icon={iconLookup[notification.type]}
    actions={notification.actions}
  />
);

export default DefaultNotification;
