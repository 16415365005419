import type { AxiosResponse } from 'axios';

import { ApiResponse } from 'types/api.types';
import { SearchHistory, SearchHistoryParams, SearchHistorySearchParams } from 'types/search.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchSearchHistory(
  this: RestApiService,
  userId: User['id'],
  params: SearchHistorySearchParams,
) {
  return this.api.get<ApiResponse<SearchHistory>>(`/users/${userId}/searchhistory`, { params });
}

export function postSearchHistory(
  this: RestApiService,
  userId: User['id'],
  previousSearch: SearchHistoryParams,
) {
  return this.api.post<SearchHistoryParams, AxiosResponse<SearchHistory>>(
    `/users/${userId}/searchhistory`,
    previousSearch,
  );
}
