import { Button, styled } from '@mui/material';

export const ActionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral01[700],
  textDecoration: 'underline',

  '&:hover': {
    color: theme.palette.neutral01[500] + ' !important',
    textDecoration: 'underline !important',
  },
}));

ActionButton.defaultProps = {
  variant: 'text',
  color: 'secondary',
};

export const ActionSpacer = styled('span')({
  width: 12,
  height: 4,
  display: 'inline-block',
});
