import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import {
  CreateTopicNotificationsParams,
  Params,
  Topic,
  TopicNotificationInstance,
  TopicNotifications,
  TopicNotificationSettings,
  UpdateTopicNotificationsParams,
  UserTopicParams,
} from 'types/topic.types';
import { User } from 'types/user.types';

import RestApiService from '.';

// Topics
export default {
  fetchTopics(this: RestApiService, params?: Params) {
    const cleanParams = removeEmptyValuesFromParams(params);

    return this.api.get<ApiResponse<Topic[]>>(`/topics`, { params: cleanParams });
  },
  fetchUserTopics(this: RestApiService, userId: User['id'], params?: UserTopicParams) {
    const cleanParams = removeEmptyValuesFromParams(params);

    return this.api.get<Topic[]>(`/users/${userId}/topics`, {
      params: cleanParams,
    });
  },
  fetchAllTopicNotifications(this: RestApiService, userId: User['id']) {
    return this.api.get<TopicNotificationInstance[]>(`/users/${userId}/topics/notifications`);
  },
  followTopic(this: RestApiService, userId: User['id'], topicId: Topic['id']) {
    return this.api.put(`/users/${userId}/topics/${topicId}/follow`);
  },
  followTopics(this: RestApiService, userId: User['id'], topicIds: Topic['id'][]) {
    return this.api.post(`/users/${userId}/topics/follow`, { topicIds });
  },
  createTopicsNotifications(
    this: RestApiService,
    userId: User['id'],
    params: TopicNotificationSettings,
  ) {
    return this.api.post(`/users/${userId}/topics/notifications`, {
      ...params,
    });
  },
  unFollowTopic(this: RestApiService, userId: User['id'], topicId: Topic['id']) {
    return this.api.put(`/users/${userId}/topics/${topicId}/unfollow`);
  },
  fetchTopicNotifications(this: RestApiService, userId: User['id'], topicId: Topic['id']) {
    return this.api.get<TopicNotifications>(`/users/${userId}/topics/${topicId}/notifications`);
  },
  createTopicNotifications(
    this: RestApiService,
    userId: User['id'],
    topicId: Topic['id'],
    params: CreateTopicNotificationsParams,
  ) {
    return this.api.post(`/users/${userId}/topics/${topicId}/notifications`, {
      ...params,
    });
  },
  updatetopicNotifications(
    this: RestApiService,
    userId: User['id'],
    topicId: Topic['id'],
    params: UpdateTopicNotificationsParams,
  ) {
    return this.api.put(`/users/${userId}/topics/${topicId}/notifications`, {
      ...params,
    });
  },
  deleteTopicNotification(this: RestApiService, userId: User['id'], topicId: Topic['id']) {
    return this.api.delete(`/users/${userId}/topics/${topicId}/notifications`);
  },
};
