import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import { SettingsConfig } from 'components/@settings/config';

interface SettingsState {
  sidebarSections: Record<string, boolean>;
}

const initialState: SettingsState = {
  sidebarSections: SettingsConfig.reduce((acc: Record<string, boolean>, { title }) => {
    return {
      ...acc,
      [title]: true,
    };
  }, {}),
};

const migrations = {
  '1': (state: SettingsState) => {
    return {
      sidebarSections: state.sidebarSections,
    };
  },
};

export const settingsPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.settings`,
  version: 1,
  storage,
  // @ts-ignore
  migrate: createMigrate(migrations, { debug: false }),
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    TOGGLE_SIDEBAR_SECTION: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        sidebarSections: {
          ...state.sidebarSections,
          [payload]: !state.sidebarSections[payload],
        },
      };
    },
  },
});

export const { actions: settingsActions } = settingsSlice;

export default settingsSlice.reducer;
