// For more info see: https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage

import type { TypedStartListening } from '@reduxjs/toolkit';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import type { AppDispatch } from '../../store';
import type { Store } from '../../store/reducers';

export const listenerMiddleware = createListenerMiddleware();

type AppStartListening = TypedStartListening<Store, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppStartListening;
