import { Components, Theme } from '@mui/material';

export const MuiDrawer: Components<Theme>['MuiDrawer'] = {
  styleOverrides: {
    paperAnchorDockedLeft: ({ theme }) => ({
      position: 'absolute',
      width: theme.mixins.sideBar.width,
      marginTop: 0,
      height: '100%',
      top: 0,
      paddingBottom: theme.spacing(4),
      bottom: `calc(${theme.mixins.appBar?.mobileHeight} + ${theme.spacing(4)})`,
      [theme.breakpoints.up('sm')]: {
        bottom: 0,
      },
    }),
  },
};
