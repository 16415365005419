import { AxiosResponse } from 'axios';

import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { RecurringNewsletterNewsObjectsSearchParams } from 'types/newsletter.types';
import { NewsObjectCompact } from 'types/newsobject.types';
import {
  CreateRecurringTemplateParams,
  RecurringTemplate,
  RecurringTemplateOccurrence,
} from 'types/recurring-template.types';
import {
  NewsletterTemplateOccurrencesSearchParams,
  NewsletterTemplateSearchParams,
} from 'types/template.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export default {
  fetchRecurringTemplates(
    this: RestApiService,
    userId: User['id'],
    params: NewsletterTemplateSearchParams,
  ) {
    const cleanParams = removeEmptyValuesFromParams(params);

    return this.api.get<ApiResponse<RecurringTemplate[]>>(`/users/${userId}/recurringtemplates`, {
      params: cleanParams,
    });
  },

  fetchRecurringTemplateOccurrences(
    this: RestApiService,
    userId: User['id'],
    recurringTemplateId: RecurringTemplate['id'],
    params?: NewsletterTemplateOccurrencesSearchParams,
  ) {
    const cleanParams = removeEmptyValuesFromParams(params);

    return this.api.get<ApiResponse<RecurringTemplateOccurrence[]>>(
      `users/${userId}/kiosk/recurringtemplates/${recurringTemplateId}/occurrences`,
      { params: cleanParams },
    );
  },

  fetchRecurringNewsletterNewsObjects(
    this: RestApiService,
    userId: User['id'],
    recurringId: RecurringTemplate['id'],
    params: RecurringNewsletterNewsObjectsSearchParams,
  ) {
    const cleanParams = removeEmptyValuesFromParams(params);

    return this.api.get<ApiResponse<NewsObjectCompact[]>>(
      `/users/${userId}/kiosk/recurringtemplates/${recurringId}/newsobjects`,
      { params: cleanParams },
    );
  },

  createRecurringTemplate(
    this: RestApiService,
    userId: User['id'],
    params: CreateRecurringTemplateParams,
  ) {
    return this.api.post<CreateRecurringTemplateParams, AxiosResponse<RecurringTemplate>>(
      `/users/${userId}/recurringtemplates`,
      params,
    );
  },

  updateRecurringTemplate(
    this: RestApiService,
    recurringTemplateId: RecurringTemplate['id'],
    params: Partial<CreateRecurringTemplateParams>,
  ) {
    return this.api.put(`/recurringtemplates/${recurringTemplateId}`, params);
  },

  deleteRecurringTemplate(this: RestApiService, recurringTemplateId: RecurringTemplate['id']) {
    return this.api.delete(`/recurringtemplates/${recurringTemplateId}`);
  },

  deleteRecurringTemplates(this: RestApiService, recurringTemplateIds: RecurringTemplate['id'][]) {
    return this.api.delete<null>('/recurringtemplates', { data: recurringTemplateIds });
  },
};
