import { Location } from 'history';
import { matchPath } from 'react-router';

import config from 'config';

import { sessionStorageService } from 'services';

import routes from 'pages/routes';

const isCurrentRouteAuthenticated = (location?: Location) => {
  const pathname = location?.pathname;
  const authenticatedRoutes = routes.filter(r => r.authenticated).map(r => r.path);

  return (
    pathname &&
    !!authenticatedRoutes.find(
      routes => !!matchPath(pathname, { path: routes, exact: true, strict: false }),
    )
  );
};

export const setRedirectURL = (location: Location) => {
  const authenticatedRoute = isCurrentRouteAuthenticated(location);

  if (authenticatedRoute && sessionStorageService) {
    const redirectRoute = `${location.pathname}${location.search}`;

    sessionStorageService.setItem(config.SESSION_STORAGE_ID_REDIRECT, redirectRoute);
  }
};
