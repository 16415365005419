import * as Sentry from '@sentry/react';

import {
  castDateRangeType,
  DateRangeType,
  getDefaultDateRangePeriod,
  maxDateForEmbargo,
  serializeDateRange,
} from 'utils/date.utils';

import { DEFAULT_PERIOD_TYPE } from 'constants/search.constants';
import { CURRENT_FILTERS, CurrentFiltersErrorType } from 'services/sentry/constants';
import { SearchFilters } from 'types/current-filter.types';

import { INITIAL_STATE as initialSearchFilterState, SearchState } from './search.slice';

export function parseFromApiToState(
  currentFilters: Partial<SearchFilters>,
  embargoDuration?: string | null,
): SearchState['filters'] {
  const clonedInitialState = JSON.parse(JSON.stringify(initialSearchFilterState.filters));
  try {
    const { dateRangeType, ...filters } = currentFilters;
    const parsedDateRangeType = castDateRangeType(dateRangeType);
    const parsedRange =
      parsedDateRangeType === DateRangeType.CUSTOM ? DEFAULT_PERIOD_TYPE : parsedDateRangeType;
    const maxDate = maxDateForEmbargo(embargoDuration);
    const dateRange = getDefaultDateRangePeriod(
      parsedRange || DEFAULT_PERIOD_TYPE,
      maxDate,
      !!embargoDuration,
    );

    return {
      ...clonedInitialState,
      ...filters,
      period: serializeDateRange(dateRange),
    };
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setTag(CURRENT_FILTERS, CurrentFiltersErrorType.CurrentSearchFiltersParsingFromApiFail);
      scope.setLevel('error');
      Sentry.captureException(error);
    });

    // Just return the initial state so the user can use the filters if we end up in the catch
    return clonedInitialState;
  }
}

export function parseFromStateToApi(filters: SearchState['filters']): SearchFilters {
  try {
    const {
      period: { type: dateRangeType },
      ...result
    } = filters;
    return { ...result, dateRangeType: castDateRangeType(dateRangeType) || DEFAULT_PERIOD_TYPE };
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setTag(CURRENT_FILTERS, CurrentFiltersErrorType.CurrentSearchFiltersParsingToApiFail);
      scope.setLevel('error');
      Sentry.captureException(error);
    });

    // Just return empty object so we clear out the corrupted state on the API if we end up in this catch
    return JSON.parse(JSON.stringify(initialSearchFilterState.filters));
  }
}
