import { UserFeatureFlags } from 'types/user.types';

function genericNotValidError(domain: string, givenValue: unknown) {
  throw new Error(`FeatureFlagService - ${domain}: "${givenValue}" is not a valid ${domain}`);
}

class FeatureFlagService {
  flags?: UserFeatureFlags[];
  allFlags: UserFeatureFlags[];

  constructor(allFlags: UserFeatureFlags[], flags?: UserFeatureFlags[]) {
    this.flags = flags;
    this.allFlags = allFlags;
  }

  setUserFeatureFlags = (flags: UserFeatureFlags[]) => {
    this.flags = flags;
  };

  isEnabled = (flag: UserFeatureFlags) => {
    if (!this.allFlags.includes(flag)) {
      genericNotValidError('userFeatureFlag', flag);
    }
    return !!this.flags?.includes(flag);
  };

  isDisabled = (flag: UserFeatureFlags) => {
    if (!this.allFlags.includes(flag)) {
      genericNotValidError('userFeatureFlag', flag);
    }
    return !this.flags?.includes(flag);
  };
}

export default FeatureFlagService;
