import { useEffect } from 'react';

import { IMPORT_ERRORS } from 'services/sentry/constants';

const reloadMessages = [...IMPORT_ERRORS, '[React Intl] Could not find required'];

const HAS_REFRESHED_KEY = 'has_refreshed';

// after a deploy, we could run into an issue where users still have the old JS files so we need to force the client to load in the new files
const useRefreshOnDeployError = (message?: string | null) => {
  useEffect(() => {
    const shouldReload = reloadMessages.some(reloadMessage => message?.includes(reloadMessage));

    if (shouldReload) {
      // We reload the page, so the user's browser is forced to pull in the new html & js
      if (sessionStorage.getItem(HAS_REFRESHED_KEY) === 'true') return;

      try {
        sessionStorage.setItem(HAS_REFRESHED_KEY, 'true');
        setTimeout(location.reload, 100);
      } catch (error) {}
    }
  }, [message]);
};

export default useRefreshOnDeployError;
