import { Box, IconButton, Typography, useTheme } from '@mui/material';

import Icon from 'components/@icons/Icon';

interface Props {
  message?: string;
  onDismiss?(): void;
}

const PageAlertBanner = ({ message, onDismiss }: Props) => {
  const theme = useTheme();
  if (!message) return null;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      minHeight="48px"
      px={2}
      py="12px"
      bgcolor={theme.palette.accent02[500]}
    >
      <Icon name="IcoWarning" sx={{ minWidth: '24px' }} color="accent02.300" />
      <Typography
        sx={{
          textAlign: 'center',
          mx: 4,
          color: theme.palette.accent02[300],
        }}
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      />
      <IconButton onClick={onDismiss} size="large">
        <Icon name="IcoClose" />
      </IconButton>
    </Box>
  );
};

export default PageAlertBanner;
