import { Components, Theme } from '@mui/material';

export const MuiListSubheader: Components<Theme>['MuiListSubheader'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: '10px',
      color: theme.palette.neutral01[400],
      paddingTop: '16px',
      marginBottom: '6px',
      lineHeight: '12px',
    }),
  },
};
