import * as Sentry from '@sentry/react';
import { Store } from 'redux';

import { getDefaultDateRangePeriod, maxDateForEmbargo, serializeDateRange } from 'utils/date.utils';

import { DEFAULT_PERIOD_TYPE } from 'constants/search.constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService, featureFlagService, notificationService } from 'services';
import { queryClient } from 'services/react-query/config';
import { PRELOAD_ERROR, PreLoadErrorType } from 'services/sentry/constants';
import { FiltersEnum, searchActions } from 'store/search/search.slice';
import { parseFromApiToState } from 'store/search/search.utils';
import { FilterUsage, SearchFilters } from 'types/current-filter.types';
import { SnackbarTypes } from 'types/notification.types';
import { User, UserFeatureFlags, UserSettings } from 'types/user.types';

const fetchSearchFilters = async (user: User, settings: UserSettings, store: Store) => {
  try {
    const embargoDuration = settings.contentSettings.embargoDuration;
    const filters = await queryClient.fetchQuery({
      queryKey: QueryKeys.currentFilter.byUsage(FilterUsage.Search),
      queryFn: async () =>
        (await apiService.fetchCurrentFilters<SearchFilters>(user.id, FilterUsage.Search)).data,
    });
    if (filters) {
      store.dispatch(
        searchActions.FETCH_CURRENT_SEARCH_FILTERS_SUCCESS({
          id: filters.id,
          currentFilters: parseFromApiToState(filters.currentFilters, embargoDuration),
        }),
      );
    } else {
      const maxDate = maxDateForEmbargo(embargoDuration);
      const dateRange = getDefaultDateRangePeriod(DEFAULT_PERIOD_TYPE, maxDate, !!embargoDuration);
      store.dispatch(
        searchActions.CLEAR_FILTERS({
          [FiltersEnum.Period]: serializeDateRange(dateRange),
        }),
      );
    }
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setTag(PRELOAD_ERROR, PreLoadErrorType.CurrentSearchFiltersPreloadFail);
      scope.setLevel('warning');
      Sentry.captureException(e);
    });

    notificationService.add({
      title: 'Error',
      message: "We couldn't load your search filters",
      type: SnackbarTypes.Error,
    });
  }
};

export const fetchPersonalization = async (user: User, settings: UserSettings, store: Store) => {
  // if we are not allowed to personalize, skip
  if (!featureFlagService.isEnabled(UserFeatureFlags.PERSONALIZATION)) return;

  await fetchSearchFilters(user, settings, store);
};
