import 'utils/google-translate-extension-fix';
import 'utils/polyfill.utils';

import { Buffer } from 'buffer';
import { createRoot } from 'react-dom/client';

import { initServices } from 'services';
import { initEnv } from 'services/boot/env';

import App from './App';

globalThis.Buffer = Buffer;

initEnv()
  .then(() => {
    initServices();
    // TODO: Render our App in StrictMode when we have migrated to Vite + React-router v6
    createRoot(document.getElementById('root')!).render(<App />);
  })
  .catch(() => (
    <p style={{ color: 'red', textAlign: 'center' }}>Error while fetching global config</p>
  ));
