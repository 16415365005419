import { Components, Theme } from '@mui/material';

export const MuiAlertTitle: Components<Theme>['MuiAlertTitle'] = {
  styleOverrides: {
    root: {
      fontWeight: 700,
      marginBottom: 0,
    },
  },
};
