import { Components, Theme } from '@mui/material';

export const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minWidth: 'initial',
      marginRight: theme.spacing(1),
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    }),
  },
};
