import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

interface UpsellState {
  shown: boolean;
}

const initialState: UpsellState = {
  shown: false,
};

export const upsellPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.upsell`,
  version: 1,
  storage,
};

const upsellSlice = createSlice({
  name: 'upsell',
  initialState,
  reducers: {
    DISMISS: () => {
      return {
        shown: true,
      };
    },
  },
});

export const { actions: upsellActions } = upsellSlice;

export default upsellSlice.reducer;
