import { Box, Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useFeatures } from 'services/feature-flags/hooks';
import { useMedia } from 'theme/hooks';
import { UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

import Icon, { IconNames } from 'components/@icons';

const ACTION_LIST: {
  name: string;
  text?: I18nKey;
  icon: IconNames;
  route: Routes;
  userFeatureFlag: UserFeatureFlags;
}[] = [
  {
    name: 'search',
    text: 'label.search' as I18nKey,
    icon: 'IcoSearch',
    route: Routes.Search,
    userFeatureFlag: UserFeatureFlags.SEARCH,
  },
  {
    name: 'saved items',
    icon: 'IcoBookmark',
    route: Routes.SavedItems,
    userFeatureFlag: UserFeatureFlags.SAVED_NEWS,
  },
];

const QuickActions = () => {
  const features = useFeatures();
  const intl = useIntl();
  const { sm, md } = useMedia();

  const actionList = ACTION_LIST.filter(
    action => !action.userFeatureFlag || features.isEnabled(action.userFeatureFlag),
  );

  return (
    <Box alignItems="center" display="flex">
      {actionList.map(action => {
        const hasText = action.text && !sm && !md;

        return (
          <Box key={action.name} ml={2}>
            <Button
              color="primary"
              variant="outlined"
              component={Link}
              to={action.route}
              startIcon={<Icon name={action.icon} />}
              sx={{
                ...(!hasText && {
                  p: '7px',
                  '.MuiButton-startIcon': {
                    m: 0,
                  },
                }),
              }}
            >
              {hasText && intl.formatMessage({ id: action.text })}
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};

export default QuickActions;
