import { createBrowserHistory } from 'history';

import config from 'config';

import RestApiService from 'services/api';
import {
  createManager,
  createPublicManager,
  createStrapiManager,
} from 'services/api/axios/instance';
import PublicRestApiService from 'services/api/public';
import FeatureFlagService from 'services/feature-flags/FeatureFlagService';
import NotificationService from 'services/notifications/notificationService';
import StorageService from 'services/storage';

import StrapiRestApiService from './api/strapi';

export let notificationService: NotificationService;
export let apiService: RestApiService;
export let strapiApiService: StrapiRestApiService;
export let publicApiService: PublicRestApiService;
export let featureFlagService: FeatureFlagService;
export let sessionStorageService: StorageService;
export let localStorageService: StorageService;
export const history = createBrowserHistory();

export const initServices = () => {
  notificationService = new NotificationService();
  apiService = new RestApiService({
    api: createManager({ baseURL: config.API_BASE }),
  });
  strapiApiService = new StrapiRestApiService({
    api: createStrapiManager({ baseURL: `${config.STRAPI_API_BASE}/api` }),
  });
  publicApiService = new PublicRestApiService({
    api: createPublicManager({ baseURL: config.API_BASE }),
  });
  featureFlagService = new FeatureFlagService(config.API_FEATURE_FLAGS);
  sessionStorageService = new StorageService(window.sessionStorage);
  localStorageService = new StorageService(window.localStorage);
};
