import { AxiosResponse } from 'axios';

import RestApiService from 'services/api';
import { getFormData } from 'services/api/utils/getFormData';
import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import {
  Document,
  DocumentCreateParams,
  DocumentsSearchParams,
  DocumentUpdateParams,
  FileType,
} from 'types/documents.types';
import { User } from 'types/user.types';

export const ALLOWED_FILE_TYPES: { mime: string; filetype: FileType }[] = [
  { mime: 'application/pdf', filetype: FileType.PDF },
  { mime: 'application/msword', filetype: FileType.DOCX },
  {
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    filetype: FileType.DOCX,
  },
  { mime: 'application/vnd.ms-excel', filetype: FileType.XLSX },
  {
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    filetype: FileType.XLSX,
  },
  { mime: 'image/jpeg', filetype: FileType.JPG },
  { mime: 'image/jpg', filetype: FileType.JPG },
  { mime: 'image/png', filetype: FileType.PNG },
];

export function createDocument(this: RestApiService, params: DocumentCreateParams) {
  return this.api.post<DocumentCreateParams, AxiosResponse<Document>>(`/documents`, params);
}

export function updateDocument(
  this: RestApiService,
  documentId: Document['id'],
  params: DocumentUpdateParams,
) {
  return this.api.put<DocumentUpdateParams, AxiosResponse<Document>>(
    `/documents/${documentId}`,
    params,
  );
}

export function uploadDocument(this: RestApiService, documentId: Document['id'], file: File) {
  const filetype = ALLOWED_FILE_TYPES.find(({ mime }) => mime === file.type)?.filetype || 'UNKNOWN';

  return this.api.post<FormData, AxiosResponse<string>>(
    `/documents/${documentId}/files`,
    getFormData({ file, filetype, filename: file.name }),
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
}

export function fetchUserDocuments(
  this: RestApiService,
  userId: User['id'],
  params?: DocumentsSearchParams,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<Document[]>>(`/users/${userId}/documents`, {
    params: cleanParams,
  });
}

export function deleteDocument(this: RestApiService, documentId: Document['id']) {
  return this.api.delete(`/documents/${documentId}`);
}

export function detachDocument(
  this: RestApiService,
  documentId: Document['id'],
  userId: User['id'],
) {
  return this.api.delete<null>(`/documents/${documentId}/collaborators/${userId}`);
}
