import { Collaboration, CollaborationParams } from 'types/collaboration.types';
import { Tag } from 'types/tag.types';

export enum FileType {
  'DOCX' = 'DOCX',
  'XLSX' = 'XLSX',
  'PNG' = 'PNG',
  'JPG' = 'IMAGE_JPG',
  'PDF' = 'PDF',
}

export type File = {
  filename: string;
  uri: string;
  filesize: number;
  filetype: FileType;
};

export type Document = {
  id: number;
  newsObjectUuid: string;
  title: string;
  description: string;
  createDate: string;
  files: File[];
  tags: Tag[];
  collaboration: Collaboration;
};

export type DocumentCreateParams = {
  title: string;
  description: string;
  tagIds: number[];
  collaboration: CollaborationParams;
};

export type DocumentUpdateParams = { id: Document['id'] } & DocumentCreateParams;

export type DocumentsSearchParams = Partial<{
  count: number;
  offset: number;
  searchtext: string;
  order: string;
}>;
