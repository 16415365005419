import { Components, Theme } from '@mui/material';

export const MuiListItemText: Components<Theme>['MuiListItemText'] = {
  defaultProps: {
    primaryTypographyProps: {
      variant: 'paragraph02',
      color: 'currentColor',
    },
  },
  styleOverrides: {
    root: {
      margin: 0,
    },
  },
};
