import { UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

import { IconNames } from 'components/@icons';

type MenuItem = {
  name: string;
  key: I18nKey;
  route: Routes;
  icon?: IconNames;
  aliasRoutes?: Routes[];
  userFeatureFlag: UserFeatureFlags;
  isNew?: boolean;
};

type WebConfigItem = {
  name: string;
  key: I18nKey;
  route: Routes;
  aliasRoutes?: Routes[];
  isNew?: boolean;
  children: MenuItem[];
};

export const WebConfig: WebConfigItem[] = [
  {
    name: 'explore',
    key: 'navigation.main.explore',
    route: Routes.Explore,
    children: [
      {
        name: 'kiosk',
        key: 'navigation.main.kiosk',
        route: Routes.KioskFrontPage,
        aliasRoutes: [Routes.KioskShared, Routes.KioskFrontPage],
        userFeatureFlag: UserFeatureFlags.KIOSK,
      },
      {
        name: 'boards',
        key: 'navigation.main.boards',
        route: Routes.Boards,
        userFeatureFlag: UserFeatureFlags.BOARDS,
      },
      {
        name: 'labels',
        key: 'navigation.main.labels',
        route: Routes.Labels,
        userFeatureFlag: UserFeatureFlags.LABELS,
      },
      {
        name: 'documents',
        key: 'navigation.main.documents',
        route: Routes.Documents,
        userFeatureFlag: UserFeatureFlags.MY_DOCUMENTS,
      },
    ],
  },
  {
    name: 'publish',
    key: 'navigation.main.publish',
    route: Routes.Publish,
    children: [
      {
        name: 'feeds',
        key: 'navigation.main.feeds',
        route: Routes.Feeds,
        userFeatureFlag: UserFeatureFlags.FEEDS,
      },
      {
        name: 'newsletters',
        key: 'navigation.main.newsletters',
        route: Routes.Newsletters,
        userFeatureFlag: UserFeatureFlags.NEWSLETTERS,
      },
      {
        name: 'reports',
        key: 'navigation.main.reports',
        route: Routes.Reports,
        userFeatureFlag: UserFeatureFlags.ANALYSIS,
      },
    ],
  },
  {
    name: 'analysis',
    key: 'navigation.main.analysis',
    route: Routes.Analysis,
    children: [
      {
        name: 'exploratory_analysis',
        key: 'navigation.main.exploratory_analysis',
        route: Routes.ExploratoryAnalysis,
        userFeatureFlag: UserFeatureFlags.ANALYSIS_PREVIEW,
      },
      {
        name: 'saved_analyses',
        key: 'navigation.main.saved_analyses',
        route: Routes.SavedAnalyses,
        userFeatureFlag: UserFeatureFlags.ANALYSIS_PREVIEW,
      },
    ],
  },
  {
    name: 'belga_share',
    key: 'navigation.main.belga_share',
    route: Routes.Newsrooms,
    aliasRoutes: [Routes.Journalist],
    children: [
      {
        name: 'newsrooms',
        key: 'navigation.main.press_releases',
        route: Routes.Newsrooms,
        userFeatureFlag: UserFeatureFlags.NEWSROOMS,
      },
      {
        name: 'journalists',
        key: 'navigation.main.journalist',
        route: Routes.Journalist,
        userFeatureFlag: UserFeatureFlags.JOURNALIST_NEWSROOM,
        // TODO: Remove after a while, added in October 2024
        isNew: true,
      },
    ],
  },
];

export const MobileConfig: MenuItem[] = [
  {
    name: 'kiosk',
    key: 'navigation.mobile.kiosk',
    route: Routes.KioskFrontPage,
    icon: 'IcoKiosk',
    userFeatureFlag: UserFeatureFlags.KIOSK,
  },
  {
    name: 'boards',
    key: 'navigation.main.boards',
    route: Routes.Boards,
    icon: 'IcoOverview',
    userFeatureFlag: UserFeatureFlags.BOARDS,
  },
  {
    name: 'labels',
    key: 'navigation.mobile.labels',
    route: Routes.Labels,
    icon: 'IcoTags',
    userFeatureFlag: UserFeatureFlags.LABELS,
  },
  {
    name: 'search',
    key: 'navigation.mobile.search',
    route: Routes.Search,
    icon: 'IcoSearch',
    userFeatureFlag: UserFeatureFlags.SEARCH,
  },
];
