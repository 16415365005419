import { DateRangeType } from 'utils/date.utils';

import { SearchModes } from 'types/search.types';

import { Option } from 'components/@inputs/Select';

export const DEFAULT_SEARCH_MODE = SearchModes.ALL_OF;
export const DEFAULT_ORDER = '-PUBLISHDATE';
export const DEFAULT_PERIOD_TYPE = DateRangeType.LAST_24_HOURS;
export const MAX_SEARCH_HISTORY_AMOUNT = 4;
export const SEARCH_INFO_MORE_URL =
  'https://lucene.apache.org/solr/guide/8_6/the-standard-query-parser.html#wildcard-searches';

export const SORT_OPTIONS: Option[] = [
  {
    value: '-PUBLISHDATE',
    label: 'filter.order.new_to_old',
  },
  {
    value: 'PUBLISHDATE',
    label: 'filter.order.old_to_new',
  },
  {
    value: '-RELEVANCE',
    label: 'filter.order.relevance',
  },
  {
    value: 'SOURCE,-PUBLISHDATE',
    label: 'filter.order.source',
  },
];
