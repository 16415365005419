import type { AxiosResponse } from 'axios';

import { getFormData } from 'services/api/utils/getFormData';
import { NewsletterImageParams } from 'types/newsletter-item.types';
import { NewsletterTemplate } from 'types/template.types';
import {
  NewsletterTemplateItem,
  NewsletterTemplateItemFieldsParams,
  NewsletterTemplateItemOrderParams,
  NewsletterTemplateItemParams,
} from 'types/template-item.types';

import RestApiService from '.';

export default {
  createTemplateItem(
    this: RestApiService,
    templateId: NewsletterTemplate['id'],
    params: NewsletterTemplateItemParams,
  ) {
    return this.api.post<NewsletterTemplateItemParams, AxiosResponse<NewsletterTemplateItem>>(
      `/newslettertemplates/${templateId}/items`,
      params,
    );
  },

  updateTemplateItem(
    this: RestApiService,
    templateId: NewsletterTemplate['id'],
    itemId: NewsletterTemplateItem['id'],
    params: NewsletterTemplateItemParams,
  ) {
    return this.api.put<NewsletterTemplateItemParams, AxiosResponse<NewsletterTemplateItem>>(
      `/newslettertemplates/${templateId}/items/${itemId}`,
      params,
    );
  },

  updateTemplateItems(
    this: RestApiService,
    templateId: NewsletterTemplate['id'],
    items: NewsletterTemplateItemParams[],
  ) {
    return this.api.put<
      NewsletterTemplateItemParams[],
      AxiosResponse<NewsletterTemplateItemParams[]>
    >(`/newslettertemplates/${templateId}/items`, items);
  },

  deleteTemplateItem(
    this: RestApiService,
    templateId: NewsletterTemplate['id'],
    itemId: NewsletterTemplateItem['id'],
  ) {
    return this.api.delete<null>(`/newslettertemplates/${templateId}/items/${itemId}`);
  },

  orderTemplateItems(
    this: RestApiService,
    templateId: NewsletterTemplate['id'],
    itemOrders: NewsletterTemplateItemOrderParams[],
  ) {
    return this.api.put<NewsletterTemplateItemOrderParams[], AxiosResponse<NewsletterTemplate>>(
      `/newslettertemplates/${templateId}/items/order`,
      itemOrders,
    );
  },

  updateTemplateItemFields(
    this: RestApiService,
    templateId: NewsletterTemplate['id'],
    itemId: NewsletterTemplateItem['id'],
    params: NewsletterTemplateItemFieldsParams,
  ) {
    return this.api.patch<NewsletterTemplateItemFieldsParams, null>(
      `/newslettertemplates/${templateId}/items/${itemId}`,
      params,
    );
  },

  uploadTemplateImage(
    this: RestApiService,
    templateId: NewsletterTemplate['id'],
    templateItemId: NewsletterTemplateItem['id'],
    params: NewsletterImageParams,
  ) {
    return this.api.post(
      `/newslettertemplates/${templateId}/items/${templateItemId}/uploads`,
      getFormData({ file: params.file }),
    );
  },
};
