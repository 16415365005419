import { DateRangeType } from 'utils/date.utils';

import { SearchState } from 'store/search/search.slice';

export enum NewsbrandFilterEnum {
  ALL = 'ALL',
  USER = 'USER',
  POPULAR = 'POPULAR',
}

export enum FilterUsage {
  Kiosk = 'kiosk',
  Search = 'search',
  Realtime = 'realtime',
}

export type RealTimeFilters = Partial<{
  newsbrands: NewsbrandFilterEnum;
}>;

export type SearchFilters = Omit<SearchState['filters'], 'period'> & {
  dateRangeType: DateRangeType;
};

export enum KioskFilterKeys {
  Countries = 'countries',
  Languages = 'languages',
}

export interface KioskFilters {
  [KioskFilterKeys.Countries]: string[];
  [KioskFilterKeys.Languages]: string[];
}

export type CurrentFilters = RealTimeFilters | SearchFilters | KioskFilters;

export interface CurrentFilter<T = CurrentFilters> {
  id: number;
  currentFilters: T;
}
