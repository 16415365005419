import { PaletteOptions } from '@mui/material/styles';

import { Leaves } from 'types/util.types';

export const palette: PaletteOptions = {
  mode: 'light',
  divider: '#F0F1FA',
  background: {
    default: '#fff',
    paper: '#fff',
  },
  text: {
    primary: '#0A0B0F',
    secondary: '#666875',
    disabled: '#A1A2B2',
  },
  primary: {
    light: '#8FAFFF',
    main: '#3349E8',
    dark: '#1C287A',
  },
  secondary: {
    light: '#F0F1FA',
    main: '#D8D9E3',
    dark: '#A1A2B2',
  },
  global01: {
    '100': '#000947',
    '200': '#000C61',
    '300': '#1C287A',
    '400': '#3349E8',
    '500': '#8FAFFF',
    '600': '#D9E4FF',
    '700': '#F5F8FF',
  },
  neutral01: {
    '100': '#000000',
    '200': '#0A0B0F',
    '300': '#666875',
    '400': '#A1A2B2',
    '500': '#D8D9E3',
    '600': '#F0F1FA',
    '700': '#FFFFFF',
  },
  accent01: {
    '300': '#D0021B',
    '400': '#ED1826',
    '500': '#FFE3E5',
  },
  accent02: {
    '300': '#792C00',
    '400': '#EE8A2B',
    '500': '#FFC56D',
    '700': '#FFF9F0',
  },
  accent03: {
    '400': '#00A843',
  },
  accent04: {
    light: '#FAF4FF',
    main: '#F1DFFF',
    dark: '#B066EB',
  },
  accent05: {
    light: '#FFFCF0',
    main: '#F2EBD0',
    dark: '#DECA7C',
  },
  error: {
    light: '#FFE3E5',
    main: '#ED1826',
    dark: '#D0021B',
  },
  info: {
    light: '#F5F8FF',
    main: '#D9E4FF',
    dark: '#000C61',
  },
  warning: {
    light: '#FFC56D',
    main: '#EE8A2B',
    dark: '#792C00',
  },
  success: {
    main: '#00A843',
    light: '#BCEBCE',
    dark: '#006629',
  },
  graph: {
    '000': '#3349E8',
    '100': '#3DCBDE',
    '200': '#5AEFA7',
    '300': '#6DD764',
    '400': '#DFEE36',
    '500': '#B066EB',
    '600': '#BF256B',
    '700': '#ED1826',
    '800': '#FF8743',
    '900': '#FFC56D',
  },
  boxShadow: 'rgba(131, 146, 148, 0.2)',
};

export type ColorNames = Leaves<typeof palette, 2>;
