import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMaintenanceMessages } from 'queries/maintenance/useMaintenanceMessages';
import { getDismissedMessages } from 'store/maintenance-messages/maintenance-messages.selectors';
import { maintenanceMessagesActions } from 'store/maintenance-messages/maintenance-messages.slice';

export const useMaintenanceMessageBanner = () => {
  const dispatch = useDispatch();
  const dismissedMessages = useSelector(getDismissedMessages);
  const { data: maintenanceMessage } = useMaintenanceMessages();

  const handleDismiss = useCallback(() => {
    if (!!maintenanceMessage) {
      dispatch(maintenanceMessagesActions.DISMISS_MESSAGE(maintenanceMessage.id));
    }
  }, [dispatch, maintenanceMessage]);

  const canShow = useMemo(
    () =>
      !!maintenanceMessage &&
      !!maintenanceMessage.message &&
      maintenanceMessage.published &&
      !dismissedMessages.includes(maintenanceMessage.id),

    [dismissedMessages, maintenanceMessage],
  );

  return {
    maintenanceMessagesDisplayed: canShow,
    maintenanceMessage: canShow ? maintenanceMessage : undefined,
    onDismiss: handleDismiss,
  };
};
