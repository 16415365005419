import { UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

import { IconNames } from 'components/@icons';

type Section = {
  title: I18nKey;
  children: MenuItem[];
};

type MenuItem = {
  key: I18nKey;
  route: Routes;
  icon: IconNames;
  userFeatureFlag: UserFeatureFlags;
};

export const SettingsConfig: Section[] = [
  {
    title: 'settings.section.preferences',
    children: [
      {
        key: 'label.general',
        route: Routes.SettingsGeneral,
        icon: 'IcoSettings',
        userFeatureFlag: UserFeatureFlags.USER_SETTINGS,
      },
      {
        key: 'label.notifications',
        route: Routes.SettingsNotifications,
        icon: 'IcoNotifications',
        userFeatureFlag: UserFeatureFlags.KIOSK_NEWSROOMS,
      },
    ],
  },
  {
    title: 'settings.section.curator_tools',
    children: [
      {
        key: 'settings.navigation.keywords',
        route: Routes.SettingsKeywords,
        icon: 'IcoKeyword',
        userFeatureFlag: UserFeatureFlags.EDIT_KEYWORDS,
      },
      {
        key: 'settings.navigation.brands',
        route: Routes.SettingsBrands,
        icon: 'IcoBrand',
        userFeatureFlag: UserFeatureFlags.EDIT_BRANDS,
      },
      {
        key: 'settings.navigation.templates',
        route: Routes.SettingsTemplates,
        icon: 'IcoFile',
        userFeatureFlag: UserFeatureFlags.EDIT_TEMPLATES,
      },
      {
        key: 'settings.navigation.recurring_templates',
        route: Routes.SettingsRecurringTemplates,
        icon: 'IcoFile',
        userFeatureFlag: UserFeatureFlags.EDIT_TEMPLATES,
      },
    ],
  },
  {
    title: 'settings.section.contact_management',
    children: [
      {
        key: 'settings.navigation.contacts',
        route: Routes.SettingsContacts,
        icon: 'IcoProfile',
        userFeatureFlag: UserFeatureFlags.EDIT_CONTACT_MANAGEMENT,
      },
      {
        key: 'settings.navigation.contact_groups',
        route: Routes.SettingsContactGroups,
        icon: 'IcoGroup',
        userFeatureFlag: UserFeatureFlags.EDIT_CONTACT_MANAGEMENT,
      },
    ],
  },
  {
    title: 'settings.section.organisation_management',
    children: [
      {
        key: 'settings.navigation.users',
        route: Routes.SettingsUsers,
        icon: 'IcoProfile',
        userFeatureFlag: UserFeatureFlags.EDIT_USER_MANAGEMENT,
      },
      {
        key: 'subscriptions.title',
        route: Routes.SettingsSubscriptions,
        icon: 'IcoNewspaper',
        userFeatureFlag: UserFeatureFlags.EDIT_USER_MANAGEMENT,
      },
    ],
  },
  {
    title: 'settings.section.statistics',
    children: [
      {
        key: 'settings.navigation.newsletter_statistics',
        route: Routes.SettingsNewsletterStatistics,
        icon: 'IcoAnalysis',
        userFeatureFlag: UserFeatureFlags.NEWSLETTER_STATISTICS,
      },
    ],
  },
  {
    title: 'settings.section.billing',
    children: [
      {
        key: 'settings.navigation.purchased_articles',
        route: Routes.SettingsPurchasedArticles,
        icon: 'IcoEuro',
        userFeatureFlag: UserFeatureFlags.PAY_PER_ARTICLE,
      },
      {
        key: 'settings.navigation.activity_reports',
        route: Routes.SettingsActivityReports,
        icon: 'IcoEuro',
        userFeatureFlag: UserFeatureFlags.ACTIVITY_REPORT,
      },
    ],
  },
];
