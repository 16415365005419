import { AxiosResponse, CancelToken } from 'axios';
import qs from 'query-string';

import {
  belgaContextHeaders,
  removeEmptyValuesFromParams,
} from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import {
  Attachment,
  GetNewsObjectParams,
  GetNewsObjectsPriceParams,
  GetPurchasedNewsObjectsParams,
  NewsObject,
  NewsObjectContext,
  NewsObjectMetaData,
  NewsObjectPrice,
  PostSearchParams,
  PurchasedNewsObject,
  PurchaseNewsObjectsRequestBody,
  SearchParams,
  SnippetParams,
} from 'types/newsobject.types';
import { PressContact } from 'types/newsroom.types';
import { Tag } from 'types/tag.types';

import RestApiService from '.';

export function fetchNewsObject(
  this: RestApiService,
  id: NewsObject['uuid'],
  params?: GetNewsObjectParams,
  context?: NewsObjectContext,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<NewsObject>(`/newsobjects/${id}`, {
    params: cleanParams,
    headers: belgaContextHeaders(context),
  });
}

export function fetchNewsObjects(
  this: RestApiService,
  dirtyParams?: SearchParams,
  context?: NewsObjectContext,
  cancelToken?: CancelToken,
) {
  const params = removeEmptyValuesFromParams<SearchParams>(dirtyParams);
  return this.api.get<ApiResponse<NewsObject[]>>(`/newsobjects`, {
    headers: belgaContextHeaders(context),
    params,
    cancelToken,
  });
}

export function fetchNewsObjectsByPost(
  this: RestApiService,
  params: {
    dirtyParams?: PostSearchParams;
    context?: NewsObjectContext;
    cancelToken?: CancelToken;
    ignoreError?: boolean;
  },
) {
  const cleanParams = removeEmptyValuesFromParams<PostSearchParams>(params.dirtyParams);
  return this.api.post<PostSearchParams, AxiosResponse<ApiResponse<NewsObject[]>>>(
    `/newsobjects`,
    cleanParams,
    {
      headers: belgaContextHeaders(params.context),
      cancelToken: params.cancelToken,
      timeout: 60_000,
      meta: {
        ignoreError: params.ignoreError,
      },
    },
  );
}

export function deleteNewsObjectFromTag(
  this: RestApiService,
  newsObjectUuid: NewsObject['uuid'],
  tagId: number,
) {
  return this.api.delete(`/newsobjects/${newsObjectUuid}/tags/${tagId}`);
}

export function updateNewsObjectTags(
  this: RestApiService,
  newsObjectUuid: NewsObject['uuid'],
  tagIds: Tag['id'][],
) {
  return this.api.put(`/newsobjects/${newsObjectUuid}/tags`, tagIds);
}

export function updateNewsObjectKeywords(
  this: RestApiService,
  newsObjectUuid: NewsObject['uuid'],
  keywords: string[],
  eTag?: string,
) {
  const headers = eTag ? { 'If-Match': eTag } : undefined;

  return this.api.put<{ data: string[] }, AxiosResponse<ApiResponse<string[]>>>(
    `/newsobjects/${newsObjectUuid}/keywords`,
    {
      data: keywords,
    },
    { headers },
  );
}

export function getNewsObjectKeywords(this: RestApiService, newsObjectUuid: string) {
  return this.api.get<ApiResponse<string[]>>(`/newsobjects/${newsObjectUuid}/keywords`);
}

export function getNewsObjectDuplicates(
  this: RestApiService,
  uuid: NewsObject['uuid'],
  context?: NewsObjectContext,
) {
  return this.api.get<ApiResponse<NewsObject[]>>(`/newsobjects/${uuid}/duplicates`, {
    headers: belgaContextHeaders(context),
  });
}

export function fetchRelatedNewsObjects(
  this: RestApiService,
  uuid: NewsObject['uuid'],
  context?: NewsObjectContext,
) {
  return this.api.get<ApiResponse<NewsObject[]>>(`/newsobjects/${uuid}/related`, {
    headers: belgaContextHeaders(context),
  });
}

export function fetchNewsObjectSnippets(
  this: RestApiService,
  uuid: NewsObject['uuid'],
  params?: SnippetParams,
) {
  return this.api.get<Attachment[]>(`/newsobjects/${uuid}/snippets`, {
    params,
  });
}

export function fetchNewsObjectsPrice(this: RestApiService, params?: GetNewsObjectsPriceParams) {
  return this.api.get<NewsObjectPrice[]>(`/commerce/prices/newsobjects`, {
    params,
    paramsSerializer: p => qs.stringify(p),
  });
}

export function createPurchaseNewsObjects(
  this: RestApiService,
  body: PurchaseNewsObjectsRequestBody,
) {
  return this.api.post<null>(`/commerce/purchases/newsobjects`, body);
}

export function getPurchasedNewsObjects(
  this: RestApiService,
  params?: GetPurchasedNewsObjectsParams,
) {
  return this.api.get<ApiResponse<PurchasedNewsObject[]>>(`/commerce/purchases/newsobjects`, {
    params,
  });
}

export function fetchNewsObjectPressContacts(
  this: RestApiService,
  uuid: NewsObject['uuid'],
  context?: NewsObjectContext,
) {
  return this.api.get<ApiResponse<PressContact[]>>(`/newsobjects/${uuid}/presscontacts`, {
    headers: belgaContextHeaders(context),
  });
}

export function fetchNewsObjectMetaData(this: RestApiService, uuid: NewsObject['uuid']) {
  return this.api.get<NewsObjectMetaData>(`/newsobjects/${uuid}/metadata`);
}
