import type { AxiosResponse } from 'axios';

import { Organization } from 'types/organization.types';
import { QuickIntegration, QuickIntegrationParams } from 'types/quick-integration.types';

import RestApiService from '.';

export function fetchQuickIntegration(
  this: RestApiService,
  organizationId: Organization['id'],
  params: QuickIntegrationParams,
) {
  return this.api.get<QuickIntegration>(`/organizations/${organizationId}/quickintegrations`, {
    params,
  });
}

export function createQuickIntegration(
  this: RestApiService,
  organizationId: Organization['id'],
  params: QuickIntegrationParams,
) {
  return this.api.post<QuickIntegrationParams, AxiosResponse<QuickIntegration>>(
    `/organizations/${organizationId}/quickintegrations`,
    params,
  );
}

export function deleteQuickIntegration(
  this: RestApiService,
  organizationId: Organization['id'],
  params: QuickIntegrationParams,
) {
  return this.api.delete<QuickIntegrationParams, AxiosResponse<QuickIntegration>>(
    `/organizations/${organizationId}/quickintegrations`,
    {
      params,
    },
  );
}
