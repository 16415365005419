import { AppBar, BottomNavigation } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { testId } from 'utils/test-id-generator.utils';

import { User } from 'types/user.types';

import Icon from 'components/@icons';
import { MobileMenu } from 'components/@navigation/Menu';
import MoreDialog from 'components/@navigation/MoreDialog';

import { Item } from '../Menu/Mobile.style';

interface Props {
  user: User;
}

const Mobile = ({ user }: Props) => {
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={{
        top: 'auto',
        bottom: 0,
        width: '100%',
        zIndex: 9999,

        '@media print': {
          display: 'none',
        },
      }}
    >
      <BottomNavigation
        showLabels={true}
        sx={theme => ({
          filter: `drop-shadow(0px -1px 4px ${theme.palette.boxShadow})`,
        })}
      >
        <MobileMenu isMoreOpen={isMoreOpen} onClose={() => setIsMoreOpen(false)}>
          <Item
            showLabel
            {...testId('navigation', 'more')}
            isActive={isMoreOpen}
            label={<FormattedMessage id="navigation.mobile.more" />}
            icon={<Icon name="IcoMore" color="neutral01.300" />}
            onClick={() => setIsMoreOpen(isOpen => !isOpen)}
          />

          <MoreDialog user={user} isOpen={isMoreOpen} onClose={() => setIsMoreOpen(false)} />
        </MobileMenu>
      </BottomNavigation>
    </AppBar>
  );
};

export default Mobile;
