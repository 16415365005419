import { Collaboration, CollaborationParams, CollaborationType } from 'types/collaboration.types';
import { SharedUser, User } from 'types/user.types';

export const collaborationToParams = (
  collaboration?: Collaboration,
  isOrganization = false,
): CollaborationParams =>
  collaboration
    ? {
        type: collaboration.type,
        editorIds: collaboration.editors?.map(e => e.userId) || [],
        ownerIds: collaboration.owners?.map(e => e.userId) || [],
        organizationIds: collaboration.organizations?.map(e => e.id) || [],
      }
    : {
        type: isOrganization ? CollaborationType.Organization : CollaborationType.Private,
        editorIds: [],
        ownerIds: [],
        organizationIds: [],
      };

export const userToSharedUser = ({
  id: userId,
  firstName,
  lastName,
  email,
  organization: { name: organizationName },
}: User): SharedUser => ({
  userId,
  firstName,
  lastName,
  email,
  organizationName,
});
