/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import { Route } from 'pages/routes.types';

import AccountDisabled from './account-disabled';
import Boards from './board';
import BoardsAllContent from './board/all-content';
import BoardsBelgaContent from './board/belga-content';
import Documents from './documents';
import ExploratoryAnalysis from './exploratory-analysis';
import Feeds from './feeds';
import Journalist from './journalist';
import Agenda from './kiosk/agenda';
import KioskBelgaImage from './kiosk/belga-image';
import KioskFrontPage from './kiosk/frontpage';
import KioskGopressArchive from './kiosk/gopress-archive';
import KioskGoPressArchiveDetail from './kiosk/gopress-archive-detail';
import KioskFeedOverview from './kiosk/live-feeds';
import KioskFeedDetail from './kiosk/live-feeds-detail';
import KioskNewsbrands from './kiosk/newsbrands';
import KioskNewsbrandsDetail from './kiosk/newsbrands-detail';
import KioskNewsletters from './kiosk/newsletters';
import KioskNewslettersDetail from './kiosk/newsletters-detail';
import KioskNewslettersDetailSearch from './kiosk/newsletters-detail-search';
import KioskNewsrooms from './kiosk/newsrooms';
import KioskNewsroomsDetail from './kiosk/newsrooms-detail';
import OldPressReviewsDetail from './kiosk/old-pressreviews-detail';
import KioskPressReleaseDetail from './kiosk/press-release-detail';
import KioskPressReleases from './kiosk/press-releases';
import KioskPublicationDetail from './kiosk/publication-detail';
import KioskPublications from './kiosk/publications';
import KioskPublicationsSearch from './kiosk/publications-search';
import KioskRealtime from './kiosk/realtime';
import KioskRecommended from './kiosk/recommended';
import KioskRecommendedDetail from './kiosk/recommended-detail';
import KioskReports from './kiosk/reports';
import KioskReportsDetail from './kiosk/reports-detail';
import KioskTopicsDetail from './kiosk/topics-detail';
import KioskTopicsOverview from './kiosk/topics-overview';
import Labels from './labels';
import Maintenance from './maintenance';
import Newsletters from './newsletters';
import Newsrooms from './newsrooms';
import NewsroomsInsights from './newsrooms/NewsroomInsights';
import NewsroomsInsightsPressReleaseDetail from './newsrooms/NewsroomInsightsPressReleaseDetail';
import NewsroomsInsightsPressReleases from './newsrooms/NewsroomInsightsPressReleases';
import NewsroomsPressReleases from './newsrooms/NewsroomPressReleases';
import NewsroomsPressReleasesDetail from './newsrooms/NewsroomPressReleasesDetail';
import NotAllowed from './not-allowed';
import Print from './print';
import RecurringNewsletterLatestEdition from './recurring-newsletter-latest';
import RecurringReportLatestEdition from './recurring-report-latest';
import Register from './register';
import Reports from './reports';
import SavedAnalyses from './saved-analyses';
import SavedAnalysisDetail from './saved-analysis-detail';
import SavedItems from './saved-items';
import Search from './search';
import SessionHijacked from './session-hijack';
import Settings from './settings';
import SettingsActivityReports from './settings/activity-reports';
import SettingsBrands from './settings/brands';
import SettingsContactGroups from './settings/contact-groups';
import SettingsContactGroupsDetail from './settings/contact-groups-detail';
import SettingsContacts from './settings/contacts';
import SettingsGeneral from './settings/general';
import SettingsKeywords from './settings/keywords';
import SettingsNewsletterStatistics from './settings/newsletter-statistics';
import SettingsNotifications from './settings/notifications';
import SettingsPurchasedArticles from './settings/purchased-articles';
import SettingsRecurringTemplates from './settings/recurring-templates';
import SettingsSubscriptions from './settings/subscriptions';
import SettingsTemplates from './settings/templates';
import SettingsTemplatesDetail from './settings/templates-detail';
import SettingsUsers from './settings/users';
import SettingsUsersDetail from './settings/users-detail';
import Share from './share';
import SharedArticles from './shared-articles';
import Unsubscribe from './unsubscribe';

export default [
  Agenda,
  Boards,
  BoardsAllContent,
  BoardsBelgaContent,
  KioskBelgaImage,
  KioskFrontPage,
  KioskNewsbrands,
  KioskNewsbrandsDetail,
  KioskNewsletters,
  KioskNewslettersDetail,
  KioskNewslettersDetailSearch,
  KioskPressReleases,
  KioskNewsrooms,
  KioskNewsroomsDetail,
  OldPressReviewsDetail,
  KioskPublications,
  KioskPublicationsSearch,
  KioskPublicationDetail,
  KioskPressReleaseDetail,
  KioskRealtime,
  KioskTopicsOverview,
  KioskTopicsDetail,
  KioskRecommended,
  KioskRecommendedDetail,
  KioskReports,
  KioskReportsDetail,
  KioskFeedDetail,
  KioskFeedOverview,
  KioskGopressArchive,
  KioskGoPressArchiveDetail,
  ExploratoryAnalysis,
  SavedAnalyses,
  SavedAnalysisDetail,
  SharedArticles,
  SavedItems,
  Labels,
  Print,
  Search,
  Feeds,
  NotAllowed,
  Settings,
  SettingsGeneral,
  SettingsNotifications,
  SettingsKeywords,
  SettingsBrands,
  SettingsContacts,
  Newsrooms,
  NewsroomsInsights,
  NewsroomsInsightsPressReleases,
  NewsroomsInsightsPressReleaseDetail,
  NewsroomsPressReleases,
  NewsroomsPressReleasesDetail,
  SettingsContactGroups,
  SettingsContactGroupsDetail,
  SettingsTemplates,
  SettingsTemplatesDetail,
  SettingsPurchasedArticles,
  SettingsUsers,
  SettingsUsersDetail,
  SettingsSubscriptions,
  SettingsActivityReports,
  SettingsNewsletterStatistics,
  Newsletters,
  Maintenance,
  AccountDisabled,
  RecurringNewsletterLatestEdition,
  RecurringReportLatestEdition,
  SessionHijacked,
  Share,
  SettingsRecurringTemplates,
  Unsubscribe,
  Register,
  Reports,
  Documents,
  Journalist,
] as Route[];
