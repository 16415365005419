import googleAnalytics from '@analytics/google-analytics';
import Analytics from 'analytics';
import React, { createContext, useContext } from 'react';

export interface IAnalyticsContext {
  analytics?: ReturnType<typeof Analytics>;
}

const AnalyticsContext = createContext<IAnalyticsContext>({
  analytics: undefined,
});

const AnalyticsProvider = ({
  trackingId,
  children,
}: {
  trackingId: string;
  children: React.ReactNode;
}) => {
  /* Initialize analytics */
  const analytics = Analytics({
    app: 'belga.press',
    plugins: [
      googleAnalytics({
        measurementIds: [trackingId],
      }),
    ],
  });

  return <AnalyticsContext.Provider value={{ analytics }}>{children}</AnalyticsContext.Provider>;
};

export const useAnalyticsContext = () => useContext(AnalyticsContext);

export const useAnalytics = () => {
  const { analytics } = useAnalyticsContext();

  const trackPageView = (path: string) => {
    analytics?.page({ url: path });
  };

  return {
    trackPageView,
  };
};

export default AnalyticsProvider;
