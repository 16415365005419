import useCopyToClipboard from 'hooks/useCopyToClipboard';

import Icon from 'components/@icons/Icon';

import styles from './RootErrorMessage.style.module.css';
import { sendEmail } from './utils';

interface Props {
  error: Error;
  componentStack: string | null;
}

const RootErrorMessage = ({ error, componentStack }: Props) => {
  const message = error?.message;

  const { copy, isCopied } = useCopyToClipboard();

  const handleSendEmail = () => {
    sendEmail({
      subject: message,
      message: componentStack
        ? `
          Message: ${message} %0d%0a %0d%0a
          ComponentStack: ${componentStack} %0d%0a %0d%0a
          Location: ${window.location.href}
          `
        : '',
    });
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleCopy = () => {
    copy(`${message}\n${componentStack}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.messageContainer}>
        <div className={styles.warningIconContainer}>
          <Icon name="IcoWarningFilled" size={48} color="error.main" />
        </div>
        <div>
          <h1 className={styles.title}>Something went wrong on our end</h1>
          <p className={styles.description}>
            Help us out by sending the error message to our IT team and elaborate what you were
            doing so we can avoid these errors in the future.
          </p>
        </div>
        <div className={styles.buttonsContainer}>
          <button onClick={handleSendEmail} className={`${styles.button} ${styles.buttonPrimary}`}>
            <div className={styles.icon}>
              <Icon name="IcoPublish" />
            </div>
            <p className={styles.buttonText}>Send error to Belga</p>
          </button>

          <button onClick={handleRefresh} className={`${styles.button} ${styles.buttonSecondary}`}>
            <div className={styles.icon}>
              <Icon name="IcoRefresh" />
            </div>
            <p className={styles.buttonText}>Refresh the page</p>
          </button>

          <button onClick={handleCopy} className={`${styles.button} ${styles.buttonSecondary}`}>
            <div className={styles.icon}>
              <Icon name={isCopied ? 'IcoCheck' : 'IcoDuplicate'} />
            </div>
            <p className={styles.buttonText}>
              {isCopied ? 'Copied' : 'Copy error message to clipboard'}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RootErrorMessage;
