class BootError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class UserFetchError extends BootError {}
export class UserSettingsFetchError extends BootError {}
export class UserSessionHijackError extends BootError {}
export class UserDisabledError extends BootError {}
