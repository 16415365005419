import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import {
  ExportUsersParams,
  User,
  UserCreateParams,
  UserSearchparams,
  UserSettings,
  UserSettingsParams,
  UserUpdateParams,
} from 'types/user.types';

import RestApiService from '.';

export function fetchSettings(this: RestApiService, userId: User['id']) {
  return this.api.get<UserSettings>(`/users/${userId}/settings`);
}

export function fetchCurrentUser(this: RestApiService) {
  return this.api.get<User>(`/auth/user`);
}

export function fetchUser(this: RestApiService, userId: User['id']) {
  return this.api.get<User>(`/users/${userId}`);
}

export function fetchUsers(this: RestApiService, params: UserSearchparams) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<User[]>>(`/users`, { params: cleanParams });
}

export function updateSettings(
  this: RestApiService,
  userId: User['id'],
  settings: UserSettingsParams,
) {
  return this.api.put(`/users/${userId}/settings`, settings);
}

export function createUsers(this: RestApiService, params: UserCreateParams[]) {
  return this.api.post(`/users`, params);
}

export function updateUser(this: RestApiService, userId: User['id'], params: UserUpdateParams) {
  return this.api.patch(`/users/${userId}`, params);
}

export function deleteUser(this: RestApiService, userId: User['id']) {
  return this.api.delete<User>(`/users/${userId}`);
}

export async function fetchExportUsers(this: RestApiService, params?: ExportUsersParams) {
  return this.api.get<Blob>(`/users/export`, {
    headers: { Accept: 'text/csv', 'Content-Type': 'text/csv' },
    data: null, // need to set data to explicitly because otherwise axios does not send the content-type header
    responseType: 'blob',
    params,
  });
}

// TODO: Implement this when endpoint is ready
export async function checkEmail(this: RestApiService, params?: { email: string }) {
  // return this.api.get<{ exists: boolean }>(`/users/check-email`, { params });
  await new Promise(resolve => setTimeout(resolve, Math.random() * 1000));
  return { exists: params?.email.match(/@/) !== null || false };
}

// This is only for developer settings. Never use this in production
export async function resetOnboarding(this: RestApiService, userId: User['id']) {
  return this.api.put(`/users/${userId}/resetonboarding`);
}
