import { AxiosInstance } from 'axios';

import { NewsObject } from 'types/newsobject.types';

interface Options {
  api: AxiosInstance;
}

class PublicRestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }
  // newsobject minimal
  public fetchMinimalNewsObject(uuid: string) {
    return this.api.get<NewsObject>(`/newsobjects/${uuid}/minimal`);
  }

  // subscription
  public unsubscribe(hash: string) {
    return this.api.put(`unsubscribe/${hash}`);
  }

  public unsubscribeSector(hash: string) {
    return this.api.put(`/belga/sectors/member/unsubscribe/${hash}`);
  }
}

export default PublicRestApiService;
