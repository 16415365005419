import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import { localeToUserLanguage } from 'services/i18n/config';
import { UserLanguages } from 'types/user.types';

interface IntlState {
  language: UserLanguages;
}

const initialState: IntlState = {
  language: localeToUserLanguage(config.DEFAULT_LOCALE),
};

const migrations = {
  '1': (state: IntlState) => {
    return {
      language: state.language,
    };
  },
};

export const intlPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.intl`,
  version: 1,
  storage,
  whitelist: ['language'],
  // @ts-ignore
  migrate: createMigrate(migrations, { debug: false }),
};

const intlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    UPDATE_LANGUAGE: (state, { payload }: PayloadAction<IntlState['language']>) => {
      state.language = payload;
    },
  },
});

export const { actions: intlActions } = intlSlice;

export default intlSlice.reducer;
