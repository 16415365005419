import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrap = styled(Box)(({ theme }) => ({
  paddingTop: 32,
  zIndex: 9999,
  overflow: 'scroll',
  backgroundColor: theme.palette.neutral01[700],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ErrorWrap = styled(Box)<{ maxWidth: number }>(({ theme, maxWidth }) => ({
  maxWidth: maxWidth,
  backgroundColor: theme.palette.global01[700],
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '4px',
  padding: '32px',
  gap: '24px',
}));

export const Description = styled(Typography)(() => ({
  textAlign: 'center',
  margin: '8px 0 0',
}));
