import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { setRedirectURL } from 'pages/utils';

export const useSetRedirectURL = () => {
  const location = useLocation();

  useEffect(() => {
    setRedirectURL(location);
  }, [location]);
};
