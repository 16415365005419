import { Box, styled } from '@mui/material';

export const NotificationContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: 'inherit',
  boxShadow: `0 2px 8px 0 ${theme.palette.boxShadow}`,
  borderRadius: '8px',
  padding: 0,
  overflow: 'hidden',
  width: '100%',
  maxWidth: '33vw',

  [theme.breakpoints.up('sm')]: {
    minWidth: '344px !important',
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}));

export const CloseButtonContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  top: 0,
  right: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}));
