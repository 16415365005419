import type { AxiosResponse } from 'axios';

import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { Keyword, OrganizationKeyword, Params } from 'types/keyword.types';
import { Organization } from 'types/organization.types';

import RestApiService from '.';

export default {
  fetchOrganizationKeywords(
    this: RestApiService,
    organizationId: Organization['id'],
    params: Params = {},
  ) {
    const cleanParams = removeEmptyValuesFromParams(params);

    return this.api.get<ApiResponse<OrganizationKeyword[]>>(
      `/organizations/${organizationId}/keywords`,
      {
        params: cleanParams,
      },
    );
  },

  createOrganizationKeyword(
    this: RestApiService,
    organizationId: Organization['id'],
    keyword: Keyword,
  ) {
    return this.api.post<{ keyword: Keyword }, AxiosResponse<OrganizationKeyword>>(
      `/organizations/${organizationId}/keywords`,
      {
        keyword,
      },
    );
  },

  updateOrganizationKeyword(
    this: RestApiService,
    organizationId: Organization['id'],
    keywordId: OrganizationKeyword['id'],
    keyword: Keyword,
  ) {
    return this.api.put<{ keyword: Keyword }, AxiosResponse<OrganizationKeyword>>(
      `/organizations/${organizationId}/keywords/${keywordId}`,
      {
        keyword,
      },
    );
  },

  deleteOrganizationKeywords(
    this: RestApiService,
    organizationId: Organization['id'],
    keywordIds: OrganizationKeyword['id'][],
  ) {
    return this.api.delete(`/organizations/${organizationId}/keywords`, { data: keywordIds });
  },
};
