import { Media, MediaFile } from 'types/media.types';
import { User, UserLanguages } from 'types/user.types';

import { IconNames } from 'components/@icons';

import { Brand } from './brand.types';
import { Collaboration, CollaborationParams } from './collaboration.types';
import { MediumType, MediumTypeGroup, NewsObject } from './newsobject.types';
import { Publisher } from './publisher.types';
import { Topic } from './topic.types';

export type NewsroomSubSource = {
  icon?: string;
  id: number;
  mediumType: MediumType;
  mediumTypeGroup: MediumTypeGroup;
  name: string;
};

export enum NewsroomStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum NewsroomType {
  PR = 'PR',
  JOURNALIST = 'JOURNALIST',
  // TODO: Remove Expert when BE removes it from existing newsrooms
  EXPERT = 'EXPERT',
}

export enum NewsroomLanguages {
  Nl = 'NL',
  Fr = 'FR',
  En = 'EN',
}

export type MultiLanguageContent<T> = Partial<Record<NewsroomLanguages, T>>;

// Make sure to add all possible languages here
// This is used to determine the order of the languages
export const NewsroomLanguagePriority: Record<NewsroomLanguages, number> = {
  [NewsroomLanguages.En]: 0,
  [NewsroomLanguages.Nl]: 1,
  [NewsroomLanguages.Fr]: 2,
};

export enum LinkTypes {
  Website = 'website',
  Facebook = 'facebook',
  Linkedin = 'linkedin',
  Twitter = 'twitter',
  Instagram = 'instagram',
  Youtube = 'youtube',
  Phone = 'phone',
  Email = 'email',
}

export const LinkIcons: Record<LinkTypes, IconNames> = {
  [LinkTypes.Website]: 'IcoOnline',
  [LinkTypes.Facebook]: 'IcoFacebook',
  [LinkTypes.Linkedin]: 'IcoLinkedin',
  [LinkTypes.Twitter]: 'IcoTwitterX',
  [LinkTypes.Instagram]: 'IcoInstagram',
  [LinkTypes.Youtube]: 'IcoYoutube',
  [LinkTypes.Phone]: 'IcoCall',
  [LinkTypes.Email]: 'IcoMail',
};

export type SocialLinks = Record<LinkTypes, string | null>;

export type ContactDetails = {
  address: Address;
  sociallinks: SocialLinks;
};

export type NewsroomContent = {
  id: number;
  title: string;
  description?: string;
  sociallinks: SocialLinks;
  address: Address;
};

export type NewsroomContentObject = MultiLanguageContent<NewsroomContent>;

export type Newsroom = {
  id: number;
  subSourceName: string;
  collaboration: Collaboration;
  coverImage: Media;
  noCoverImage?: boolean;
  profilePicture?: Media;
  publisher?: Publisher;
  status: NewsroomStatus;
  type: NewsroomType;
  brand: Brand;
  topics: Topic[];
  publishDate: string;
  updatedAt: string;
  jobRole?: string;
  content: NewsroomContentObject;
  termsAcceptedDate: string | null;
};

export type NewsroomUpdateParams = {
  id: Newsroom['id'];
  subSourceName: string;
  status: NewsroomStatus;
  brandId: Brand['id'];
  collaboration: CollaborationParams;
  topicIds: Topic['id'][];
  jobRole?: string;
  publisherId?: Publisher['id'];
  content: NewsroomContentObject;
  coverImage: Media | undefined | null;
  noCoverImage?: boolean;
  profilePicture?: Media | null;
  termsAcceptedDate: string | null;
};

export type NewsroomCreateParams = {
  subSourceName?: string;
  status: NewsroomStatus;
  publisherId?: Publisher['id'];
  jobRole?: string;
  type: NewsroomType;
  brandId?: Brand['id'];
  collaboration?: CollaborationParams;
  topicIds?: Topic['id'][];
  content?: NewsroomContentObject;
  termsAcceptedDate: string | null;
};

export type CompactNewsroom = {
  id: Newsroom['id'];
  subSourceName: Newsroom['subSourceName'];
  status: NewsroomStatus;
  type: NewsroomType;
  publishDate: Newsroom['publishDate'];
  updatedAt: Newsroom['updatedAt'];
  collaboration: Collaboration;
};

export type NewsroomSearchParams = Partial<{
  count: number;
  offset: number;
  type: NewsroomType;
}>;

// documents
export enum NewsroomDocumentType {
  File = 'FILE',
  Folder = 'FOLDER',
  Embed = 'EMBED',
}

export type NewsroomDocumentsSearchParams = {
  count: number;
  language: NewsroomLanguages;
  offset: number;
  parentId: number | null;
  order: string;
};

export type NewsroomDocument = {
  id: number;
  parentId: number | null;
  name: string;
  updateDate: string;
} & (
  | { type: NewsroomDocumentType.File; file: MediaFile }
  | {
      type: NewsroomDocumentType.Folder;
      thumbnail?: { mediaId: number; url: string };
      meta: { numberOfItems: number };
    }
  | { type: NewsroomDocumentType.Embed; embed: { url: string } }
);

export type UpdateNewsroomDocumentParams = {
  name: string;
  thumbnail?: { mediaId: number | null };
  embed?: { url: string };
};

export type CreateNewsroomDocumentParams = {
  name: NewsroomDocument['name'];
  language: NewsroomLanguages;
  parentId: NewsroomDocument['parentId'];
} & (
  | { type: NewsroomDocumentType.File; file: { id: number } }
  | { type: NewsroomDocumentType.Folder; thumbnail: { mediaId: number } | null }
  | { type: NewsroomDocumentType.Embed; embed: { url: string } }
);

export type PressCoveragesSearchParams = Partial<{
  count: number;
  language: NewsroomLanguages;
  offset: number;
  fetchMoreCount?: number;
  order: string;
}>;

export type CreateNewsroomNotificationParams = {
  languages: NewsroomLanguages[];
};

export type UpdateNewsroomNotificationParams = {
  id: number;
  languages: NewsroomLanguages[];
};

export type NewsroomNotification = {
  id: number;
  languages: NewsroomLanguages[];
};

export type CreatePressContactParams = {
  firstName: string;
  lastName: string;
  email: string;
  websiteUrl?: string;
  phoneNumber?: string;
  profilePicture?: { id: number };
  public: boolean;
  content: MultiLanguageContent<PressContactContent>;
};

export type PressContactImage = {
  id: number;
  url: string;
};

export type PressContactContent = {
  jobRole: string;
  showOnNewsroom: boolean;
};

export type PressContact = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  websiteUrl: string;
  language: UserLanguages;
  phoneNumber: string;
  profilePicture: PressContactImage;
  public: boolean;
  content: MultiLanguageContent<PressContactContent>;
};

export type PressContactsSearchParams = Partial<{
  count: number;
  offset: number;
  search: string;
  order: string;
}>;

export type PressContactUpdateParams = {
  id: PressContact['id'];
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  websiteUrl?: string;
  profilePicture?: Omit<PressContactImage, 'url'>;
  public: boolean;
  content: MultiLanguageContent<PressContactContent>;
};

// KIOSK
export type KioskNewsroomSearchParams = Partial<{
  search: string;
  count: number;
  offset: number;
  order: string; // name | latestPressRelease
  following: boolean;
  notifications: boolean;
  topicIds: Topic['id'][];
  fetchMoreCount: number;
  type: NewsroomType;
}>;

export type KioskNewsroomParams = {
  userId: User['id'];
  newsroomId: KioskNewsroom['id'];
};

export type CompactKioskNewsroom = {
  id: number;
  subSourceName: string;
  status: NewsroomStatus;
  type: NewsroomType;
  topics: Topic[];
  profilePicture?: Media;
  publishDate: string;
  updatedAt: string;
  languages: NewsroomLanguages[];
  brand: Brand;
};

export enum NewsroomTabNames {
  PRESS_RELEASES = 'pressReleases',
  DOCUMENTS = 'documents',
  EVENTS = 'events',
  PRESS_COVERAGES = 'pressCoverages',
  PRESS_CONTACTS = 'pressContacts',
  // Portfolio is only available for journalist newsrooms
  PORTFOLIO = 'portfolio',
}

export type KioskNewsroomMetaData = {
  [NewsroomTabNames.PRESS_RELEASES]: {
    hasPressReleases: boolean;
  };
  [NewsroomTabNames.DOCUMENTS]: {
    hasDocuments: boolean;
  };
  [NewsroomTabNames.EVENTS]: {
    hasEvents: boolean;
    upComingCount: number;
  };
  [NewsroomTabNames.PRESS_COVERAGES]: {
    hasPressCoverages: boolean;
  };
};

export type KioskNewsroom = {
  id: number;
  subSourceName: string;
  subSourceId: number;
  type: NewsroomType;
  brand: Brand;
  coverImage?: Media;
  noCoverImage?: boolean;
  profilePicture?: Media;
  topics: Topic[];
  publishDate: string;
  updatedAt: string;
  meta: KioskNewsroomMetaData;
  jobRole?: string;
  publisher?: Publisher;
  content: NewsroomContentObject;
  pressContacts: PressContact[];
};

export enum KioskNewsroomDocumentCategory {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  FOLDER = 'FOLDER',
  OTHER = 'OTHER',
}

export type KioskNewsroomDocumentsSearchParams = {
  search?: string;
  category: KioskNewsroomDocumentCategory;
  language: NewsroomLanguages;
  count: number;
  offset: number;
  parentId: number | null;
  order: string;
};

export type Coordinates = {
  lat?: number;
  lng?: number;
};

export type Address = {
  street?: string;
  number?: string | null;
  zip?: string;
  city?: string;
  country?: string;
  geometry?: Coordinates;
};

export type Venue = {
  address: Address;
  name: string;
};

export type NewsroomEventContent = {
  title: string;
  description: string;
  accreditation?: string;
  url?: string;
};

export type NewsroomEventCompact = {
  id: number;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  allDay?: boolean;
  venue?: Venue;
  content: MultiLanguageContent<NewsroomEventContent>;
};

export type NewsroomEventParams = {
  content: MultiLanguageContent<NewsroomEventContent>;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  allDay?: boolean;
  venue?: Venue;
  topicIds?: Topic['id'][];
  pressContactIds?: PressContact['id'][];
  attachments?: { fileId: number }[];
};

export type Attachment = MediaFile;

export type NewsroomEvent = {
  id: number;
  content: MultiLanguageContent<NewsroomEventContent>;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  allDay?: boolean;
  venue?: Venue;
  topics: Topic[];
  pressContacts: PressContact[];
  attachments: Attachment[];
};

export type NewsroomEventSearchParams = Partial<{
  count: number;
  offset: number;
  search: string;
  order: string;
  showPast: boolean;
  language: NewsroomLanguages;
}>;

export type KioskNewsroomEventsSearchParams = Partial<{
  count: number;
  language: NewsroomLanguages;
  offset: number;
  order: string;
  showPast: boolean;
  fetchMoreCount: number;
}>;

export type NewsroomPressReleasesInsightsGlobals = {
  reads: {
    average: number;
    diffPercentage: number;
  };
  openRate: {
    averagePercentage: number;
    diffPercentage: number;
  };
  pickups: {
    average: number;
    diffPercentage: number;
  };
};

export enum AudienceEntityType {
  PRIVATE_CONTACTS = 'PRIVATE_CONTACTS',
  CONTACT_GROUP = 'CONTACT_GROUP',
  SECTOR = 'SECTOR',
  TOPIC = 'TOPIC',
  NEWSROOM_FOLLOWERS = 'NEWSROOM_FOLLOWERS',
}

export type PickupNewsObject = Pick<
  NewsObject,
  'uuid' | 'title' | 'publishDate' | 'source' | 'sourceLogo' | 'mediumType' | 'mediumTypeGroup'
>;

export type NewsroomPressReleaseInsightsDetail = {
  id: number;
  title: string;
  publishDate: string;
  thumbnail?: Media;
  reads: {
    total: number;
    average: number;
  };
  pickups: {
    total: number;
    average: number;
    newsobjects: PickupNewsObject[];
  };
  audience: {
    id: number;
    entityType: AudienceEntityType;
    name: string | null;
    reads: {
      total: number;
      amount: number;
    };
  }[];
  potentialReach: {
    total: number;
    newsroomFollowers: number;
    belgaWire: number;
    contacts: number;
    topics: (Pick<Topic, 'id' | 'content'> & { followers: number })[];
  };
};

export type NewsroomPressReleasesInsights = {
  id: number;
  title: string;
  publishDate: string;
  thumbnail?: Media;
  reads: {
    total: number;
    diffPercentage: number;
  };
  openRate: {
    totalPercentage: number;
    diffPercentage: number;
  };
  pickups: {
    total: number;
    diffPercentage: number;
  };
};

export type NewsroomPressReleasesInsightsParams = Partial<{
  count: number;
  offset: number;
  order: string;
  search: string;
}>;

export type NewsroomNotificationInstance = {
  id: number;
  languages: NewsroomLanguages[];
  newsroom: Pick<Newsroom, 'id' | 'subSourceName' | 'brand'> & { languages: NewsroomLanguages[] };
};
