import { Components, inputLabelClasses, Theme } from '@mui/material';

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.label01,
      lineHeight: '16px',
      color: theme.palette.neutral01[300],
      fontWeight: 'bold',
      textTransform: 'uppercase',
      [`&.${inputLabelClasses.focused}`]: {
        color: theme.palette.neutral01[300],
      },
      [`&.${inputLabelClasses.error}`]: {
        color: theme.palette.accent01[400],
        '& span': {
          color: 'inherit',
        },
      },
    }),
    shrink: {
      transform: 'none',
    },
  },
};
