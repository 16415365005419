import { Store } from 'redux';

import { runInDev } from 'utils/env.utils';

import { history } from 'services';
import { logout } from 'services/authentication/logout';
import {
  exchangeCodeForTokens,
  isAccessTokenValid,
  refreshAccessToken,
} from 'services/authentication/tokens';
import { authActions } from 'store/auth/auth.slice';
import { AuthResponse } from 'types/auth.types';

export const validateAuthentication = async (store: Store) => {
  const state = store.getState();
  const validToken = isAccessTokenValid(state);
  // We have a valid access token so nothing to do here
  if (validToken) return;

  let auth: AuthResponse | undefined;

  try {
    // If we are coming from a success callback exchange the code
    if (history.location.pathname === '/success/') {
      runInDev(() => console.info('---------CODE EXCHANGE---------'));
      auth = await exchangeCodeForTokens();
    } else {
      runInDev(() => console.info('---------REFRESH TOKEN---------'));
      // attempt to refresh the access token
      auth = await refreshAccessToken(state);
    }
    // If we have auth store it else bail and logout
    if (auth) {
      store.dispatch(authActions.SET_AUTH_RESPONSE(auth));
    } else {
      await logout();
    }
  } catch (error) {
    runInDev(() => console.error(error));
    await logout();
  }
};
