import qs from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import config from 'config';

export const redirectToKeyCloak = () => {
  const urlParams = {
    response_type: config.API_KEYCLOAK.RESPONSE_TYPE,
    client_id: config.API_CLIENT_ID,
    redirect_uri: config.API_CALLBACK_URL,
    scope: config.API_KEYCLOAK.SCOPE,
    nonce: uuidv4(),
  };

  const stringified = qs.stringify(urlParams);
  const url = `${config.API_BASE_AUTH}/auth?${stringified}`;
  window.location.replace(url);
};
