import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import {
  PersistQueryClientProviderProps,
  removeOldestQuery,
} from '@tanstack/react-query-persist-client';
import { AxiosError } from 'axios';

import config from 'config';

import { ApiError } from 'types/api.types';

const queryCache = new QueryCache();

/**
 * @description USE useQueryClient instead - DO NOT IMPORT DIRECTLY (except in App.tsx)
 * @protected
 * @deprecated
 * @access protected
 */
export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      // Set cacheTime to 1h for persisted Cache
      gcTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: unknown) => {
        if ((error as AxiosError<ApiError>)?.response?.status === 404) return false;
        return failureCount < 2;
      },
      meta: {
        // By default we don't want to store queries in local storage
        store: false,
      },
    },
  },
});

export const LocalStoragePersistor = createSyncStoragePersister({
  key: config.LOCAL_REACT_QUERY_STORAGE_ID,
  storage: window.localStorage,
  retry: removeOldestQuery,
});

export const persistOptions: PersistQueryClientProviderProps['persistOptions'] = {
  buster: `${config.BUILD_ENV}@${config.VERSION}`,
  persister: LocalStoragePersistor,
  // Keep persisted cache for 12 hours
  maxAge: 1000 * 60 * 60 * 12,
  dehydrateOptions: {
    shouldDehydrateMutation: () => false,
    shouldDehydrateQuery: query => !!query.meta?.store,
  },
};
