import qs from 'qs';

import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { AgendaEvent, AgendaEventParams, OrderCoverageParams, Rubric } from 'types/agenda.types';
import { ApiResponse } from 'types/api.types';

import RestApiService from '.';

export function fetchAgendaEvents(this: RestApiService, dirtyParams?: AgendaEventParams) {
  return this.api.get<ApiResponse<AgendaEvent[]>>(`/agenda/events`, {
    params: removeEmptyValuesFromParams(dirtyParams),
    paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma' }),
  });
}

export function fetchAgendaEventById(this: RestApiService, agendaEventId: AgendaEvent['id']) {
  return this.api.get<AgendaEvent>(`/agenda/events/${agendaEventId}`);
}

export function fetchRubrics(this: RestApiService) {
  return this.api.get<ApiResponse<Rubric[]>>(`/agenda/rubrics`);
}

export function orderCoverage(this: RestApiService, params: OrderCoverageParams) {
  return this.api.post(`/agenda/events/${params.eventId}/ordercoverage`, params);
}
