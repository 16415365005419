import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import {
  belgaContextHeaders,
  removeEmptyValuesFromParams,
} from 'services/api/utils/remove-empty-values';
import {
  Analysis,
  AnalysisOverview,
  AnalysisParams,
  ArticleCountResponse,
  AudienceReachedResponse,
  DatasetConstraints,
  DatasetConstraintsParams,
  MediaValueResponse,
  SavedAnalysisBody,
  SavedAnalysisResponse,
  SavedAnalysisSearchParams,
  SentimentResponse,
  SourceGroupResponse,
  SourceResponse,
} from 'types/analysis.types';
import { ApiResponse } from 'types/api.types';
import { NewsObjectContext, SearchParams } from 'types/newsobject.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchAnalysis(
  this: RestApiService,
  params: AnalysisParams,
  options?: AxiosRequestConfig,
) {
  const _params = removeEmptyValuesFromParams(params);

  return this.api.get<AnalysisOverview>(`/analysis`, { ...options, params: _params });
}

export function createSavedAnalysis(this: RestApiService, params: SavedAnalysisBody) {
  return this.api.post<SavedAnalysisBody, AxiosResponse<SavedAnalysisResponse>>(
    `/savedanalyses`,
    params,
  );
}

export function deleteSavedAnalysis(this: RestApiService, analysisId: Analysis['id']) {
  return this.api.delete(`/savedanalyses/${analysisId}`);
}

export function detachSavedAnalysis(
  this: RestApiService,
  analysisId: Analysis['id'],
  userId: User['id'],
) {
  return this.api.delete<null>(`/savedanalyses/${analysisId}/collaborators/${userId}`);
}

export function updateSavedAnalysis(
  this: RestApiService,
  analysisId: Analysis['id'],
  params: SavedAnalysisBody,
) {
  return this.api.put<SavedAnalysisBody, AxiosResponse<SavedAnalysisResponse>>(
    `/savedanalyses/${analysisId}`,
    params,
  );
}

export function fetchUserSavedAnalyses(
  this: RestApiService,
  userId: User['id'],
  params?: SavedAnalysisSearchParams,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<SavedAnalysisResponse[]>>(`/users/${userId}/savedanalyses`, {
    params: cleanParams,
  });
}

export function fetchSavedAnalysisById(this: RestApiService, savedAnalysisId: Analysis['id']) {
  return this.api.get<SavedAnalysisResponse>(`/savedanalyses/${savedAnalysisId}`);
}

export function fetchAnalysisSentiment(
  this: RestApiService,
  params?: AnalysisParams,
  context?: NewsObjectContext,
  options?: AxiosRequestConfig,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<SentimentResponse>('/analysis/sentiment', {
    ...options,
    headers: belgaContextHeaders(context),
    params: cleanParams,
  });
}

export function fetchAnalysisArticleCount(
  this: RestApiService,
  params?: AnalysisParams,
  context?: NewsObjectContext,
  options?: AxiosRequestConfig,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<ArticleCountResponse>('/analysis/articlecount', {
    ...options,
    headers: belgaContextHeaders(context),
    params: cleanParams,
  });
}

export function fetchAnalysisAudienceReached(
  this: RestApiService,
  params?: AnalysisParams,
  context?: NewsObjectContext,
  options?: AxiosRequestConfig,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<AudienceReachedResponse>('/analysis/audiencereached', {
    ...options,
    headers: belgaContextHeaders(context),
    params: cleanParams,
  });
}

export function fetchAnalysisMediaValue(
  this: RestApiService,
  params?: AnalysisParams,
  context?: NewsObjectContext,
  options?: AxiosRequestConfig,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<MediaValueResponse>('/analysis/mediavalue', {
    ...options,
    headers: belgaContextHeaders(context),
    params: cleanParams,
  });
}

export function fetchAnalysisSources(
  this: RestApiService,
  params?: AnalysisParams,
  context?: NewsObjectContext,
  options?: AxiosRequestConfig,
) {
  const _params = removeEmptyValuesFromParams(params);

  return this.api.get<SourceResponse>(`/analysis/sources`, {
    ...options,
    headers: belgaContextHeaders(context),
    params: _params,
  });
}

export function fetchAnalysisSourceGroups(
  this: RestApiService,
  params?: AnalysisParams,
  context?: NewsObjectContext,
  options?: AxiosRequestConfig,
) {
  const _params = removeEmptyValuesFromParams(params);

  return this.api.get<SourceGroupResponse>(`/analysis/sourcegroups`, {
    ...options,
    headers: belgaContextHeaders(context),
    params: _params,
  });
}

export function createAnalysisExport(
  this: RestApiService,
  params?: SearchParams,
  options?: AxiosRequestConfig,
) {
  return this.api.post(
    `/analysis/export`,
    {},
    { ...options, params: removeEmptyValuesFromParams(params) },
  );
}

export function fetchDatasetConstraints(
  this: RestApiService,
  params: DatasetConstraintsParams,
  options?: AxiosRequestConfig,
) {
  return this.api.get<ApiResponse<DatasetConstraints[]>>('/datasetconstraints', {
    ...options,
    params: params,
    paramsSerializer: p => qs.stringify(p),
  });
}
