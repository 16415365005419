import { testId } from 'utils/test-id-generator.utils';

import { User } from 'types/user.types';

import Icon from 'components/@icons';

import { Content, Wrap } from './Trigger.style';

interface Props {
  user: User;
  isOpen?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const Trigger = ({ user: { firstName }, isOpen, onClick }: Props) => {
  return (
    <Wrap
      onClick={onClick}
      justifyContent="center"
      alignItems="center"
      {...testId('navigation-user-dropdown-trigger')}
    >
      <Icon
        name="IcoProfile"
        size={24}
        sx={{ minWidth: '24px', transorm: `rotate(${isOpen ? 180 : 0}deg)` }}
      />
      <Content>{firstName}</Content>
    </Wrap>
  );
};

export default Trigger;
