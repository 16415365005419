import { Middleware } from 'redux';
import { Config, createStateSyncMiddleware } from 'redux-state-sync';

import { runNotInTest } from 'utils/env.utils';

import { authActions } from 'store/auth/auth.slice';

const stateSyncConfig: Config = {
  whitelist: [authActions.SET_AUTH_RESPONSE.type],
  broadcastChannelOption: { type: 'localstorage' },
};

export const devMiddlewares: Middleware[] = [];
// Add the logger in dev
runNotInTest(() => devMiddlewares.push(createStateSyncMiddleware(stateSyncConfig)));
