import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { SnackbarTypes } from 'types/notification.types';

import DefaultNotification from './DefaultNotification';
import { Notification, NotificationWithType } from './types';

const useNotifications = () => {
  const snackbar = useSnackbar();

  const add = useCallback(
    (notification: NotificationWithType) => {
      snackbar?.enqueueSnackbar(<DefaultNotification notification={notification} />, {
        key: notification.title + notification.message,
      });
    },
    [snackbar],
  );

  const success = useCallback(
    (notification: Notification) => add({ ...notification, type: SnackbarTypes.Success }),
    [add],
  );

  const error = useCallback(
    (notification: Notification) => add({ ...notification, type: SnackbarTypes.Error }),
    [add],
  );

  const warning = useCallback(
    (notification: Notification) => add({ ...notification, type: SnackbarTypes.Warning }),
    [add],
  );

  return {
    error,
    success,
    warning,
  };
};

export default useNotifications;
