import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { history } from 'services';
import { UserSessionHijackError } from 'services/boot/errors';
import { ApiError } from 'types/api.types';

import { Routes } from 'pages/routes.constants';

export const HijackException = 'AuthenticationHijackException';
const interceptStatusCodes = [401];

/**
 * Creates a Session Hijack Interceptor that binds to any error response.
 * If the response is 401 and the data exceptionType is AuthenticationHijackException.
 * This allows us to detect if a user is signed in twice with different browsers and ask
 * them to properly sign in again. When this happen no additional call should be made and
 * we redirect to the re-authentication page
 * @param axios
 */
export const createSessionHijackInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.response.use(
    (res: AxiosResponse) => res,
    async (error: AxiosError<ApiError>) => {
      if (shouldInterceptError(error)) {
        const error = new UserSessionHijackError();
        // Redirect to session hijack
        history.push(Routes.SessionHijacked);
        return Promise.reject(error);
      } else {
        // Ensure we return the rejected promise
        return Promise.reject(error);
      }
    },
  );
};

const shouldInterceptError = (error: AxiosError<ApiError>) =>
  error.response &&
  interceptStatusCodes.includes(error.response.status) &&
  error.response.data.exceptionType === HijackException;
