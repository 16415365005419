import { Components, Theme } from '@mui/material';

export const MuiBreadcrumbs: Components<Theme>['MuiBreadcrumbs'] = {
  styleOverrides: {
    separator: {
      margin: 0,
    },
    li: {
      fontSize: 12,
    },
  },
};
