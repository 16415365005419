import { SUPPORT_EMAIL } from 'constants/error-message.constants';

type Email = {
  subject: string;
  message: string;
};

export const sendEmail = ({ subject, message }: Email) => {
  window.location.href = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${message}`;
};
