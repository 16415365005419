import { Components, listItemButtonClasses, Theme } from '@mui/material';

export const MuiListItemButton: Components<Theme>['MuiListItemButton'] = {
  defaultProps: {
    disableRipple: true,
    disableTouchRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      paddingTop: 12,
      paddingBottom: 12,
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          transform: 'scale(1.2)',
        },
      },
      [`&.${listItemButtonClasses.focusVisible}`]: {
        backgroundColor: theme.palette.global01[700],
      },
      '&.active': {
        backgroundColor: theme.palette.global01[700],
        color: theme.palette.global01[200],
        fill: theme.palette.global01[200],
        fontWeight: 600,
        '& svg': {
          color: theme.palette.global01[200],
          fill: theme.palette.global01[200],
        },
      },
    }),
  },
};
