import posthog, { PostHogConfig } from 'posthog-js';
import { PostHogProvider as Provider } from 'posthog-js/react';
import { PropsWithChildren } from 'react';

import config from 'config';

import { useCurrentUser } from 'queries/user/useCurrentUser';

declare global {
  interface Window {
    posthog: typeof posthog;
  }
}

const options: Partial<PostHogConfig> = {
  api_host: 'https://eu.posthog.com',
  persistence: 'memory',
  autocapture: false,
  capture_pageview: false,
  capture_pageleave: false,
  debug: false,
  loaded: () => {
    window.posthog = posthog;
    posthog.debug(false);
  },
};

const PosthogProvider = ({ children }: PropsWithChildren) => {
  const user = useCurrentUser();

  if (!user?.id) {
    return <>{children}</>;
  }

  return (
    <Provider
      apiKey={config.POSTHOG_KEY}
      options={{
        ...options,
        bootstrap: {
          distinctID: user.id.toString(),
          ...options.bootstrap,
        },
      }}
    >
      {children}
    </Provider>
  );
};

export default PosthogProvider;
