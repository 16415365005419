import { Components, tableCellClasses, Theme } from '@mui/material';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.secondary,
      borderBottom: `1px solid ${theme.palette.divider}`,
      lineHeight: '1.5rem',
    }),
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(1, 2),
      [`&.${tableCellClasses.paddingCheckbox}`]: {
        padding: theme.spacing(1),
      },
    }),
    paddingCheckbox: ({ theme }) => ({
      position: 'sticky',
      '&:after': {
        content: '""',
        position: 'absolute',
        height: '15px',
        right: 0,
        top: 'calc(50% - 15px/2)',
        width: '1px',
        backgroundColor: theme.palette.neutral01[600],
      },
    }),
    head: ({ theme }) => ({
      fontSize: '12px',
      fontWeight: 'normal',
      color: theme.palette.text.secondary,
    }),
    body: ({ theme }) => ({
      fontSize: '12px',
      fontWeight: 'normal',
      color: theme.palette.text.secondary,
    }),
    alignRight: {
      flexDirection: 'row',
    },
    stickyHeader: ({ theme }) => ({
      backgroundColor: theme.palette.background.default,
    }),
  },
};
