import { createSelector } from '@reduxjs/toolkit';

import { deserializeDateRange } from 'utils/date.utils';

import { DEFAULT_SEARCH_MODE } from 'constants/search.constants';
import { Store } from 'store/reducers';

import { FiltersEnum } from './search.slice';

export const getPeriodFilter = (state: Store) =>
  deserializeDateRange(state.search.filters[FiltersEnum.Period]);

export const getMediumTypeGroupFilter = (state: Store) =>
  state.search.filters[FiltersEnum.MediumTypeGroup];
export const getSourceFilter = (state: Store) => state.search.filters[FiltersEnum.Source];
export const getSourceGroupFilter = (state: Store) => state.search.filters[FiltersEnum.SourceGroup];
export const getLanguageFilter = (state: Store) => state.search.filters[FiltersEnum.Language];
export const getCountryFilter = (state: Store) => state.search.filters[FiltersEnum.Country];
export const getOrderFilter = (state: Store) => state.search.filters[FiltersEnum.Order];
export const getAdvancedFiltersFilter = (state: Store) =>
  state.search.filters[FiltersEnum.AdvancedFilters];
export const getAllFilters = (state: Store) => state.search.filters;

export const getEditionFilter = (state: Store) => state.search.filters[FiltersEnum.Edition];
export const getSubsourceFilter = (state: Store) => state.search.filters[FiltersEnum.Subsource];
export const getTopicsFilter = (state: Store) => state.search.filters[FiltersEnum.Topics];
export const getCurrentSearchFiltersId = (state: Store) => state.search.currentSearchFiltersId;
export const getSearchText = (state: Store) => state.search.searchText;
export const getHasSearched = (state: Store) => state.search.hasSearched;
export const getOpenNewsObjectUuid = (state: Store) => state.search.openNewsObjectUuid;
export const getSearchMode = (state: Store) =>
  state.search.filters.searchMode || DEFAULT_SEARCH_MODE;
export const getIsManual = (state: Store) => !!state.search.isManual;
export const getPage = (state: Store) => state.search.page;
export const getPerPage = (state: Store) => state.search.perPage;
export const getFromPortfolio = (state: Store) => !!state.search.fromPortfolio;

export const getSearchParams = createSelector(
  getSearchText,
  getHasSearched,
  getSearchMode,
  getIsManual,
  getPage,
  getPerPage,
  getFromPortfolio,
  (searchText, hasSearched, searchMode, isManual, page, perPage, fromPortfolio) => ({
    searchText,
    hasSearched,
    searchMode,
    isManual,
    page,
    perPage,
    fromPortfolio,
  }),
);
