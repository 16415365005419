/**
 * why: Safari 13 support
 * docs: https://formatjs.io/docs/react-intl/upgrade-guide-3x#migrate-to-using-native-intl-apis
 * As of 2024-06-17, ±14 Belga users are still on Safari 13
 */
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).global = window;
