import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import { Contact, SharedOrganization } from 'types/contact.types';
import { ContactGroup } from 'types/contact-group.types';
import { Keyword } from 'types/keyword.types';
import { Tag } from 'types/tag.types';
import { User } from 'types/user.types';

const MAX_LAST_USED_COUNT = 10;
const MAX_LAST_USED_CONTACTS_GROUPS_COUNT = 10;

export enum ContactsGroupsType {
  Contact = 'CONTACT',
  Group = 'GROUP',
  ORGANIZATION = 'ORGANIZATION',
}

export type ContactsGroups = {
  type: ContactsGroupsType;
  id: number;
};
interface RecentlyUsedState {
  labels: Tag['id'][];
  keywords: Keyword[];
  users: User[];
  contactsGroups: ContactsGroups[];
  collaborators: number[];
}

const INITIAL_STATE: RecentlyUsedState = {
  labels: [],
  keywords: [],
  users: [],
  contactsGroups: [],
  collaborators: [],
};

export const recentlyUsedPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.recentlyUsed`,
  storage: storage,
  whitelist: ['labels', 'keywords', 'users', 'contactsGroups', 'collaborators'],
};

const updateRecentlyUsed = <T>(
  list: T[],
  items: T[] | T,
  maxUsedCount: number = MAX_LAST_USED_COUNT,
  compareFn: (item: T) => string = item => `${item}`,
) => {
  if (list.length >= maxUsedCount) list.shift();
  if (Array.isArray(items)) {
    items.forEach(item => {
      const index = list.findIndex(i => compareFn(i) === compareFn(item));

      if (index > -1) list.splice(index, 1);

      list.push(item);
    });
    return list;
  }

  const index = list.findIndex(i => compareFn(i) === compareFn(items));

  if (index > -1) list.splice(index, 1);

  list.push(items);

  return list;
};

const recentlyUsedSlice = createSlice({
  name: 'recently-used',
  initialState: INITIAL_STATE,
  reducers: {
    SET_LAST_USED_LABEL: (state, action: PayloadAction<Tag['id'][] | Tag['id']>) => {
      updateRecentlyUsed(state.labels, action.payload);
    },
    SET_LAST_USED_KEYWORD: (state, action: PayloadAction<string>) => {
      updateRecentlyUsed(state.keywords, action.payload);
    },
    SET_LAST_USED_USERS: (state, action: PayloadAction<User>) => {
      updateRecentlyUsed(
        state.users,
        action.payload,
        MAX_LAST_USED_COUNT,
        item => `${item.id}-${item.firstName}`,
      );
    },
    SET_LAST_USED_CONTACTS: (state, action: PayloadAction<Contact['id']>) => {
      updateRecentlyUsed(
        state.contactsGroups,
        { id: action.payload, type: ContactsGroupsType.Contact },
        MAX_LAST_USED_CONTACTS_GROUPS_COUNT,
        item => `${item.id}-${item.type}`,
      );
    },
    SET_LAST_USED_CONTACT_GROUPS: (state, action: PayloadAction<ContactGroup['id']>) => {
      updateRecentlyUsed(
        state.contactsGroups,
        { id: action.payload, type: ContactsGroupsType.Group },
        MAX_LAST_USED_CONTACTS_GROUPS_COUNT,
        item => `${item.id}-${item.type}`,
      );
    },
    SET_LAST_USED_ORGANIZATIONS: (state, action: PayloadAction<SharedOrganization['id']>) => {
      updateRecentlyUsed(
        state.contactsGroups,
        { id: action.payload, type: ContactsGroupsType.ORGANIZATION },
        MAX_LAST_USED_CONTACTS_GROUPS_COUNT,
        item => `${item.id}-${item.type}`,
      );
    },
    SET_LAST_USED_COLLABORATORS: (state, action: PayloadAction<Contact['id']>) => {
      updateRecentlyUsed(state.collaborators, action.payload);
    },
  },
});

export const { actions: recentlyUsedActions } = recentlyUsedSlice;

export default recentlyUsedSlice.reducer;
