import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useMaintenanceMessageBanner } from 'hooks/useMaintenanceMessageBanner';
import { useCurrentUser } from 'queries/user/useCurrentUser';
import { useMedia } from 'theme/hooks';

import { Routes } from 'pages/routes.constants';

import PageAlertBanner from 'components/@common/PageAlertBanner';
import MobileNavigation from 'components/@navigation/Mobile';
import WebNavigation from 'components/@navigation/Web';

import { AppBarStyled } from './AppBar.style';

const hideAppBarForRoutes: Routes[] = [Routes.RedirectNotAuthorized, Routes.Maintenance];

const AppBarComponent = () => {
  const { sm, md, lg, xl } = useMedia();
  const location = useLocation();
  const user = useCurrentUser();
  const { maintenanceMessage, onDismiss } = useMaintenanceMessageBanner();

  const showAppBarContents = useMemo(
    () =>
      !hideAppBarForRoutes.find(route =>
        matchPath(location.pathname, {
          path: route,
        }),
      ),
    [location.pathname],
  );

  return (
    <AppBarStyled container>
      {!sm && <PageAlertBanner message={maintenanceMessage?.message} onDismiss={onDismiss} />}
      {!md && !lg && !xl ? (
        showAppBarContents && !!user && <MobileNavigation user={user} />
      ) : (
        <WebNavigation showAppBarContents={showAppBarContents} user={user} />
      )}
    </AppBarStyled>
  );
};

export default AppBarComponent;
