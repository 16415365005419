import { SharedOrganization } from 'types/contact.types';

import { SharedUser } from './user.types';

export enum CollaborationType {
  Private = 'PRIVATE',
  Shared = 'SHARED',
  Organization = 'ORGANIZATION',
}

export type Collaboration = {
  type: CollaborationType;
  owners: SharedUser[];
  editors: SharedUser[];
  organizations?: SharedOrganization[];
};

export type CollaborationParams = {
  type: CollaborationType;
  ownerIds?: SharedUser['userId'][];
  editorIds?: SharedUser['userId'][];
  organizationIds?: SharedOrganization['id'][];
};

export enum CollaborationRole {
  Owner = 'Owner',
  Editor = 'Editor',
}
