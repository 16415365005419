export function testId(name: string, uniqueIdentifier?: string) {
  if (uniqueIdentifier !== null && uniqueIdentifier !== undefined && uniqueIdentifier !== '') {
    return {
      'data-testid': `${name}-${uniqueIdentifier}`,
    };
  }

  return {
    'data-testid': `${name}`,
  };
}
