import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.neutral01[700],

  '&.with-app-bar': {
    [theme.breakpoints.down('sm')]: {
      height: `calc(100% - ${theme.mixins.appBar?.mobileHeight})`,
      marginBottom: theme.mixins.appBar?.mobileHeight,
    },
  },
}));
