import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { useIntl } from 'react-intl';

import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useNotifications from 'services/notifications/hooks';

import useRefreshOnDeployError from 'components/@boundaries/useRefreshOnDeployError';

import { FallbackProps } from './boundary.types';
import { ErrorMessage } from './ErrorMessage';
import { sendEmail } from './utils';

const Fallback = ({ error, componentStack, fullscreen }: FallbackProps) => {
  const notifications = useNotifications();
  const intl = useIntl();
  const message = error?.message;

  useRefreshOnDeployError(message);

  const { copy } = useCopyToClipboard({
    onSuccess: () => {
      notifications.success({
        title: intl.formatMessage({ id: 'error_boundary.copy.succcess.title' }),
        message: intl.formatMessage({ id: 'error_boundary.copy.succcess.message' }),
      });
    },
    onError: () => {
      notifications.error({
        title: intl.formatMessage({ id: 'error_boundary.copy.error.title' }),
        message: intl.formatMessage({ id: 'error_boundary.copy.error.message' }),
      });
    },
  });

  const handleCopyClick = async () => {
    copy(`${message}\n${componentStack}`);
  };

  return (
    <ErrorMessage
      error={error}
      title="error.generic.title"
      description="error.generic.description"
      fullscreen={fullscreen}
      primaryCta={{
        label: 'error.generic.primary_cta.send_error',
        icon: 'IcoPublish',
        onClick: () =>
          sendEmail({
            subject: message,
            message: componentStack
              ? `%0d%0a%0d%0aError @ ${window.location.href}:%0d%0a${message}%0d%0a${componentStack}`
              : '',
          }),
      }}
      secondaryCta={[
        {
          label: 'error.generic.secondary_cta.refresh',
          icon: 'IcoReload',
          onClick: () => window.location.reload(),
        },
        {
          label: 'error.generic.secondary_cta.copy',
          icon: 'IcoDuplicate',
          onClick: handleCopyClick,
        },
      ]}
    />
  );
};

type Props = {
  boundary: string;
  children?: JSX.Element | JSX.Element[];
  fullscreen?: boolean;
};

const GenericErrorBoundary = ({ children, boundary, fullscreen }: Props) => (
  <SentryErrorBoundary
    beforeCapture={scope => scope.setTag('boundary', boundary)}
    fallback={props => <Fallback {...props} error={props.error as Error} fullscreen={fullscreen} />}
  >
    {children}
  </SentryErrorBoundary>
);

export default GenericErrorBoundary;
