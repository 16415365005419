import { AxiosInstance } from 'axios';

import { Headers } from 'services/api/axios/headers/headers';
import { isAccessTokenValid, refreshAndStoreAccesstoken } from 'services/authentication/tokens';

import { isApiUrl } from './utils';

export const createAuthRefreshRequestInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use(
    async request => {
      try {
        const { baseURL } = request;

        if (isApiUrl(baseURL) && !isAccessTokenValid()) {
          const accessToken = await refreshAndStoreAccesstoken();
          // Update the headers so we use the new token
          if (request.headers) {
            request.headers[Headers.Authorization] = `Bearer ${accessToken}`;
          }
        }

        return request;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async error => {
      return Promise.reject(error);
    },
  );
};
