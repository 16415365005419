import { Dialog, Grid, styled, Typography } from '@mui/material';

export const DialogStyled = styled(Dialog)(({ theme }) => ({
  display: 'block',
  zIndex: 1400 + ' !important',
  marginBottom: 56,

  '.MuiDialog-paper': {
    padding: '22px 16px',
    backgroundColor: theme.palette.neutral01[700],
  },

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const MoreHeader = styled(Typography)(() => ({
  lineHeight: '37px',
}));

export const UserHeader = styled(Typography)(() => ({
  paddingTop: '24px',
}));

export const MenuColorLink = styled(Grid)(({ theme }) => ({
  ...theme.typography.paragraph01,
  color: theme.palette.global01[400],
  marginTop: 27,
  marginBottom: 27,
}));

export const MenuLink = styled(Grid)(({ theme }) => ({
  ...theme.typography.paragraph01,
  color: theme.palette.neutral01[300],
  borderTop: '1px solid ' + theme.palette.neutral01[600],
  padding: '19px 0 16px',
}));
