import { Components, Theme } from '@mui/material';

export const MuiSkeleton: Components<Theme>['MuiSkeleton'] = {
  defaultProps: {
    animation: 'wave',
    variant: 'rounded',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.neutral01[600],
    }),
  },
};
