import posthog from 'posthog-js';

import { redirectToKeyCloak } from 'utils/redirect.utils';

import { apiService } from 'services';
import { LocalStoragePersistor } from 'services/react-query/config';
import { getStoreState, persistor } from 'store';
import { getRefreshToken } from 'store/auth/auth.selector';
import { Store } from 'store/reducers';

export const logout = async (store: Store = getStoreState()) => {
  try {
    const refreshToken = getRefreshToken(store);
    if (!refreshToken) {
      throw Error('Logout: No refresh token!');
    }
    posthog.reset();
    await apiService.endSession(refreshToken);
  } finally {
    await persistor.purge();
    await LocalStoragePersistor.removeClient();
    redirectToKeyCloak();
  }
};
