import { Components, menuItemClasses, Theme } from '@mui/material';

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  defaultProps: {
    disableTouchRipple: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: theme.typography.fontFamily,
      ...theme.typography.paragraph01,
      color: theme.palette.neutral01[300],
      [`&.${menuItemClasses.selected}, &.${menuItemClasses.selected}:hover`]: {
        [`&.${menuItemClasses.focusVisible}`]: {
          backgroundColor: 'transparent',
        },
        backgroundColor: 'transparent',
        fontWeight: 700,
        color: theme.palette.primary.main,
      },
      [` &.${menuItemClasses.selected}:hover svg`]: {
        fill: theme.palette.primary.main,
      },
      [`&:hover, &.${menuItemClasses.focusVisible}`]: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        '& p, & span, & svg': {
          color: theme.palette.primary.main,
          fill: theme.palette.primary.main,
        },
      },
      '&:hover svg': {
        fill: theme.palette.primary.main,
      },
      '&:empty': {
        display: 'none',
      },
    }),
  },
};
