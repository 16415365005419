import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import config from 'config';

import { runInDev } from 'utils/env.utils';
import { isValidUrl } from 'utils/string.utils';

import { logout } from 'services/authentication/logout';
import { User } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

import Icon from 'components/@icons';

import { DialogStyled, MenuColorLink, MenuLink, MoreHeader, UserHeader } from './MoreDialog.style';

interface Props {
  user: User;
  isOpen: boolean;
  onClose(): void;
}

const MoreDialog = ({ user: { email }, isOpen, onClose }: Props) => {
  const intl = useIntl();
  const history = useHistory();
  const handleLogout = useCallback(() => logout(), []);

  const handleClick = (callback: () => void) => {
    callback();
    if (isOpen) onClose();
  };

  const handleRedirectI18nRoute = (key: I18nKey) => {
    const url = intl.formatMessage({ id: key });

    // Test if the url is a valid url
    if (isValidUrl(url)) {
      window.open(url, '_blank');
    } else {
      runInDev(() => console.warn(`Not a valid url: ${url}`));
    }
  };

  return (
    <DialogStyled open={isOpen} fullScreen hideBackdrop>
      <MoreHeader variant="heading01" color="neutral01.200">
        <FormattedMessage id="navigation.mobile.more" />
      </MoreHeader>

      <UserHeader variant="heading06" fontWeight="bold" color="neutral01.200">
        {email}
      </UserHeader>

      <MenuColorLink
        container
        alignItems="center"
        onClick={() => handleClick(() => handleRedirectI18nRoute('navigation.user.help.url'))}
      >
        <Icon name="IcoHelp" color="global01.400" sx={{ mr: '8px' }} />
        <FormattedMessage id={'navigation.user.help'} />
      </MenuColorLink>

      <MenuColorLink container alignItems="center" onClick={() => handleClick(handleLogout)}>
        <Icon name="IcoLogout" color="global01.400" sx={{ mr: '9px' }} />
        <FormattedMessage id={'navigation.user.logout'} />
      </MenuColorLink>

      <MenuLink
        container
        alignItems="center"
        onClick={() => handleClick(() => history.push(Routes.SavedItems))}
      >
        <Icon name="IcoBookmark" color="neutral01.300" sx={{ mr: '9px' }} />
        <FormattedMessage id={'navigation.user.saved_items'} />
      </MenuLink>

      <MenuLink
        container
        alignItems="center"
        onClick={() =>
          handleClick(() =>
            handleClick(() => window.open(config.API_CHANGE_PASSWORD_URL, '_blank')),
          )
        }
      >
        <Icon name="IcoInfo" color="neutral01.300" sx={{ mr: '9px' }} />
        <FormattedMessage id={'navigation.user.change_password'} />
      </MenuLink>

      <MenuLink
        container
        alignItems="center"
        onClick={() => handleClick(() => history.push(Routes.Settings))}
      >
        <Icon name="IcoProfile" color="neutral01.300" sx={{ mr: '9px' }} />
        <FormattedMessage id={'navigation.user.account_settings'} />
      </MenuLink>
    </DialogStyled>
  );
};

export default MoreDialog;
