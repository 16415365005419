import { Theme, useMediaQuery } from '@mui/material';

const useMedia = () => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'md'), {
    noSsr: true,
  });
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'), {
    noSsr: true,
  });
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.between('lg', 'xl'), {
    noSsr: true,
  });
  const xl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'), { noSsr: true });

  return {
    sm,
    md,
    lg,
    xl,
  };
};

export default useMedia;
