import { ThemeOptions } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';

import { palette } from './colors';
import { typography } from './typography';

export const baseThemeOptions: ThemeOptions = {
  palette,
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  shadows: [
    'none',
    '1px 1px 6px rgba(131, 146, 148, 0.2)',
    '0 2px 6px 0 rgba(131, 146, 148, 0.2)',
    '0 0 2px 0 rgba(0, 0, 0, 0.2)',
    ...Array(22).fill('none'),
  ] as Shadows,
  shape: {
    borderRadius: 4,
  },
};
