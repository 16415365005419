import React, { useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useFeatures } from 'services/feature-flags/hooks';

import { Routes } from 'pages/routes.constants';
import { Route as RouteProps } from 'pages/routes.types';

const RoutePublic = ({ userFeatureFlag, ...rest }: RouteProps) => {
  const features = useFeatures();
  const isEnabled = useMemo(
    () => (userFeatureFlag ? features.isEnabled(userFeatureFlag) : true),
    [features, userFeatureFlag],
  );

  return isEnabled ? <Route {...rest} /> : <Redirect to={Routes.NotFound} />;
};

export default React.memo(RoutePublic);
