import * as Sentry from '@sentry/react';
import React from 'react';

import useRefreshOnDeployError from 'components/@boundaries/useRefreshOnDeployError';

import { FallbackProps } from './boundary.types';
import RootErrorMessage from './RootErrorMessage';

// Since nothing is loaded at this point the fallback can only return basic HTML
// Do not attempt to translate this error or add styles, they are not loaded yet!
const Fallback = ({ error, componentStack }: FallbackProps) => {
  const message = error?.message;

  useRefreshOnDeployError(message);

  return <RootErrorMessage error={error} componentStack={componentStack} />;
};

// This is the everything else failed fallback and is normally never shown to the user
// If there is an issue with the connectors then this will make sure we see it in sentry
const RootBoundary = ({ children }: { children: React.ReactNode }) => (
  <Sentry.ErrorBoundary
    beforeCapture={scope => scope.setTag('boundary', 'root')}
    fallback={props => <Fallback {...props} error={props.error as Error} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default RootBoundary;
