import type { AxiosResponse } from 'axios';
import qs from 'query-string';

import config from 'config';

import { AuthResponse } from 'types/auth.types';

import RestApiService from '.';

const getAxiosConfig = () => ({
  baseURL: config.API_BASE_AUTH,
  headers: {
    Accept: 'application/vnd.api+json, text/plain, */*;',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export function postExchangeCodeForTokens(this: RestApiService, code: string) {
  const params = {
    grant_type: 'authorization_code',
    client_id: config.API_CLIENT_ID,
    redirect_uri: config.API_CALLBACK_URL,
    code,
  };

  // We need to stringify the params, because we are sending it as form-urlencoded
  return this.api.post<string, AxiosResponse<AuthResponse>>(
    '/token',
    qs.stringify(params),
    getAxiosConfig(),
  );
}

export function refreshAuthToken(this: RestApiService, refreshToken: string) {
  const params = {
    grant_type: 'refresh_token',
    client_id: config.API_CLIENT_ID,
    refresh_token: refreshToken,
  };

  // We need to stringify the params, because we are sending it as form-urlencoded
  return this.api.post<string, AxiosResponse<AuthResponse>>(
    '/token',
    qs.stringify(params),
    getAxiosConfig(),
  );
}

export function endSession(this: RestApiService, refreshToken: string) {
  const params = {
    grant_type: 'refresh_token',
    client_id: config.API_CLIENT_ID,
    refresh_token: refreshToken,
  };

  // We need to stringify the params, because we are sending it as form-urlencoded
  return this.api.post('/logout', qs.stringify(params), getAxiosConfig());
}
