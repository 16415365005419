import { AgendaEvent } from 'types/agenda.types';
import { Analysis } from 'types/analysis.types';
import { Board } from 'types/board.types';
import { Brand } from 'types/brand.types';
import { ContactGroup } from 'types/contact-group.types';
import { FilterUsage } from 'types/current-filter.types';
import { Document } from 'types/documents.types';
import { Feed } from 'types/feed.types';
import {
  Folder,
  GopressNewsObject,
  PressReview,
  PressReviewItem,
} from 'types/gopress-archive.types';
import { Newsletter } from 'types/newsletter.types';
import { NewsletterItem } from 'types/newsletter-item.types';
import { GetNewsObjectParams, GetNewsObjectsPriceParams, NewsObject } from 'types/newsobject.types';
import { Newsroom, NewsroomEvent } from 'types/newsroom.types';
import { Organization } from 'types/organization.types';
import { PressRelease } from 'types/press-releases.types';
import { Deliverable } from 'types/publication.types';
import { QuickIntegrationParams } from 'types/quick-integration.types';
import { RecurringTemplate } from 'types/recurring-template.types';
import { Source } from 'types/source.types';
import { Tag } from 'types/tag.types';
import { NewsletterTemplate } from 'types/template.types';
import { Topic } from 'types/topic.types';
import { User } from 'types/user.types';

export enum Keys {
  All = 'All',
  AgendaEvents = 'agendaEvents',
  Coverages = 'coverages',
  Rubrics = 'rubrics',
  Analysis = 'analysis',
  ArticleCount = 'articleCount',
  AudienceReached = 'audienceReached',
  Autolabels = 'autolabels',
  ActivityReports = 'activityReports',
  Boards = 'boards',
  Brands = 'brands',
  Clipping = 'clipping',
  ContactGroups = 'contactGroups',
  Contacts = 'contacts',
  CurrentFilters = 'currentFilters',
  CurrentUser = 'currentUser',
  CheckEmail = 'checkEmail',
  Deliverables = 'deliverables',
  Documents = 'Documents',
  DraftStatus = 'DraftStatus',
  Duplicates = 'Duplicates',
  Events = 'events',
  Editions = 'editions',
  ExploreSections = 'exploreSections',
  Favourite = 'favourite',
  Feeds = 'feeds',
  Graphs = 'graphs',
  Infinite = 'infinite',
  Insights = 'insights',
  Keywords = 'keywords',
  Kiosk = 'kiosk',
  Labels = 'labels',
  MediaValue = 'mediaValue',
  MediumTypeGroups = 'mediumTypeGroups',
  Minimal = 'Minimal',
  NewsletterItems = 'newsletterItems',
  Newsletters = 'newsletters',
  MetaData = 'metaData',
  Newsrooms = 'newsrooms',
  PressReleases = 'pressReleases',
  InsightsGlobals = 'insightsGlobals',
  NewsObject = 'newsObject',
  NewsObjects = 'newsObjects',
  NewsletterOutline = 'newsletterOutline',
  NotificationSettings = 'notificationSettings',
  Occurrences = 'occurrences',
  Organization = 'Organization',
  OrganizationGroups = 'organizationGroups',
  Popular = 'popular',
  Publications = 'publications',
  PublishSettings = 'publishSettings',
  PressReview = 'pressreview',
  PressReviewItem = 'pressReviewItem',
  QuickIntegrations = 'quickIntegrations',
  Related = 'related',
  Saved = 'saved',
  SavedNewsTag = 'savedNewsTag',
  SavedSearches = 'savedSearches',
  SearchHistory = 'searchHistory',
  Sections = 'sections',
  Sentiment = 'sentiment',
  Snippets = 'snippets',
  SourceGroups = 'sourceGroups',
  Sources = 'sources',
  SourceSettings = 'sourceSettings',
  Subsources = 'subsources',
  Tags = 'tags',
  Topics = 'topics',
  Sectors = 'sectors',
  TagSettings = 'tagSettings',
  Templates = 'templates',
  MaintenanceMessages = 'maintenanceMessages',
  User = 'user',
  Users = 'users',
  UniqueVisitors = 'uniqueVisitors',
  PageActivity = 'pageActivity',
  Followers = 'followers',
  EmbedVideoDetails = 'embedVideoDetails',
  Current = 'current',
  Settings = 'settings',
  GopressArchive = 'gopressArchive',
  Folders = 'folders',
  RecurringTemplates = 'recurringTemplates',
  RecurringTemplatesOccurrences = 'recurringTemplatesOccurrences',
  PrefillStatus = 'prefillStatus',
  PressContacts = 'pressContacts',
  Notification = 'notification',
  PressCoverages = 'pressCoverages',
  Price = 'price',
  Purchased = 'purchased',
  JobsAsync = 'jobsAsync',
  Statistics = 'statistics',
  Recipients = 'recipients',
  UniqueReaders = 'uniqueReaders',
  OpenRates = 'openRates',
  ArticlesRead = 'articlesRead',
  DatasetConstraints = 'datasetConstraints',
  Strapi = 'strapi',
  BelgaShareUpsell = 'belgaShareUpsell',
  Publishers = 'publishers',
}

export const QueryKeys = {
  publishers: {
    all: (...rest: unknown[]) => [Keys.Publishers, ...rest],
  },
  reporting: {
    activityReports: (...rest: unknown[]) => [Keys.ActivityReports, ...rest],
    newsletters: {
      uniqueReaders: (...rest: unknown[]) => [
        Keys.Statistics,
        Keys.Newsletters,
        Keys.UniqueReaders,
        ...rest,
      ],
      openRates: (...rest: unknown[]) => [
        Keys.Statistics,
        Keys.Newsletters,
        Keys.OpenRates,
        ...rest,
      ],
      articlesRead: (...rest: unknown[]) => [
        Keys.Statistics,
        Keys.Newsletters,
        Keys.ArticlesRead,
        ...rest,
      ],
      statistics: (...rest: unknown[]) => [Keys.Statistics, Keys.Newsletters, ...rest],
      recipients: (...rest: unknown[]) => [Keys.Statistics, Keys.Recipients, ...rest],
      newsletterRecipients: (newsletterId: number, ...rest: unknown[]) => [
        Keys.Statistics,
        Keys.Recipients,
        newsletterId,
        ...rest,
      ],
    },
  },
  commerce: {
    insights: (...rest: unknown[]) => [Keys.Insights, ...rest],
  },
  user: {
    current: [Keys.CurrentUser, Keys.User],
    settings: [Keys.CurrentUser, Keys.Settings],
    clippingLabel: [Keys.CurrentUser, Keys.Clipping, Keys.Labels],
    savedNewsTag: [Keys.CurrentUser, Keys.SavedNewsTag],
    checkEmail: (email: string) => [Keys.CurrentUser, Keys.CheckEmail, email],
  },
  users: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Analysis, ...rest],
    byId: (userId: User['id']) => [Keys.User, userId],
    organizations: (...rest: unknown[]) => [Keys.User, Keys.Organization, ...rest],
  },
  organization: {
    byId: (organizationId: Organization['id']) => [Keys.Organization, organizationId],
    users: (...rest: unknown[]) => [Keys.Organization, Keys.Users, ...rest],
    groups: (...rest: unknown[]) => [Keys.OrganizationGroups, ...rest],
  },
  documents: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Documents, ...rest],
    byId: (documentId: Document['id']) => [Keys.Documents, documentId],
  },
  analysis: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Analysis, ...rest],
    articleCount: (...rest: unknown[]) => [Keys.Analysis, Keys.ArticleCount, ...rest],
    audienceReached: (...rest: unknown[]) => [Keys.Analysis, Keys.AudienceReached, ...rest],
    mediaValue: (...rest: unknown[]) => [Keys.Analysis, Keys.MediaValue, ...rest],
    overview: (...rest: unknown[]) => [Keys.Analysis, ...rest],
    byId: (savedAnalysisId: Analysis['id']) => [Keys.Analysis, savedAnalysisId],
    sentiment: (...rest: unknown[]) => [Keys.Analysis, Keys.Sentiment, ...rest],
    sourceGroups: (...rest: unknown[]) => [Keys.Analysis, Keys.SourceGroups, ...rest],
    sources: (...rest: unknown[]) => [Keys.Analysis, Keys.Sources, ...rest],
    datasetConstraints: (...rest: unknown[]) => [Keys.Analysis, Keys.DatasetConstraints, ...rest],
  },
  graphs: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Analysis, ...rest],
    byId: (savedAnalysisId: Analysis['id']) => [Keys.Analysis, savedAnalysisId],
  },
  boards: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Boards, ...rest],
    byId: (boardId: Board['uuid']) => [Keys.Boards, boardId],
    notificationSettings: (boardId: Board['uuid']) => [
      Keys.Boards,
      Keys.NotificationSettings,
      boardId,
    ],
    sourceSettings: (boardId: Board['uuid']) => [Keys.Boards, Keys.SourceSettings, boardId],
    sections: [Keys.Boards, Keys.Sections],
    autolabels: (boardId: Board['uuid']) => [Keys.Boards, Keys.Autolabels, boardId],
    validQuery: (query: string) => [Keys.Boards, query],
  },
  labels: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Labels, ...rest],
  },
  feeds: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Feeds, ...rest],
    byId: (feedId: Feed['id'], ...rest: unknown[]) => [Keys.Feeds, feedId, ...rest],
    publishSettings: (feedId: Feed['id'], ...rest: unknown[]) => [
      Keys.Feeds,
      Keys.PublishSettings,
      feedId,
      ...rest,
    ],
    notificationSettings: (userId: User['id'], feedId: Feed['id'], ...rest: unknown[]) => [
      Keys.Feeds,
      Keys.NotificationSettings,
      userId,
      feedId,
      ...rest,
    ],
    newsObjects: (feedId: Feed['id'], ...rest: unknown[]) => [
      Keys.NewsObjects,
      Keys.Infinite,
      Keys.Feeds,
      feedId,
      ...rest,
    ],
  },
  newsletters: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Newsletters, ...rest],
    byId: (newsletterId: Newsletter['id'] | Newsletter['originalId']) => [
      Keys.Newsletters,
      newsletterId,
    ],
    outline: (newsletterId: Newsletter['id']) => [
      Keys.Newsletters,
      Keys.NewsletterOutline,
      newsletterId,
    ],
    publishSettings: (newsletterId: Newsletter['id'], ...rest: unknown[]) => [
      Keys.Newsletters,
      Keys.PublishSettings,
      newsletterId,
      ...rest,
    ],
    draftStatus: (newsletterId: Newsletter['id']) => [
      Keys.Newsletters,
      Keys.DraftStatus,
      newsletterId,
    ],
    tags: (...rest: unknown[]) => [Keys.User, Keys.Newsletters, Keys.Tags, ...rest],
    newsObjects: (newsletterId: Newsletter['id'], ...rest: unknown[]) => [
      Keys.NewsObjects,
      Keys.User,
      Keys.Newsletters,
      newsletterId,
      ...rest,
    ],
  },
  newsletterItems: {
    newsObjects: (
      newsletterId: Newsletter['id'],
      newsletterItemId: NewsletterItem['id'],
      ...rest: unknown[]
    ) => [
      Keys.NewsObjects,
      Keys.Infinite,
      Keys.NewsletterItems,
      newsletterId,
      newsletterItemId,
      ...rest,
    ],
    allNewsObjects: (
      newsletterId: Newsletter['id'],
      newsletterItemId: NewsletterItem['id'],
      ...rest: unknown[]
    ) => [
      Keys.NewsObjects,
      Keys.NewsletterItems,
      Keys.All,
      newsletterId,
      newsletterItemId,
      ...rest,
    ],
    prefillStatus: (newsletterId: Newsletter['id'], newsletterItemId: NewsletterItem['id']) => [
      Keys.NewsletterItems,
      Keys.PrefillStatus,
      newsletterId,
      newsletterItemId,
    ],
  },
  templates: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Templates, ...rest],
    byId: (templateId: NewsletterTemplate['id']) => [Keys.Templates, templateId],
    publishSettings: (templateId: NewsletterTemplate['id'], ...rest: unknown[]) => [
      Keys.Templates,
      Keys.PublishSettings,
      templateId,
      ...rest,
    ],
    subscription: (templateId: NewsletterTemplate['id']) => [Keys.User, Keys.Templates, templateId],
  },
  recurringTemplates: {
    all: (...rest: unknown[]) => [Keys.User, Keys.RecurringTemplates, ...rest],
    occurrences: (recurringTemplateId: RecurringTemplate['id'], ...rest: unknown[]) => [
      Keys.User,
      Keys.RecurringTemplatesOccurrences,
      recurringTemplateId,
      ...rest,
    ],
    newsObjects: (recurringId: RecurringTemplate['id'], ...rest: unknown[]) => [
      Keys.NewsObjects,
      Keys.User,
      Keys.Newsletters,
      recurringId,
      ...rest,
    ],
  },
  newsrooms: {
    all: (...rest: unknown[]) => [Keys.Newsrooms, ...rest],
    byId: (newsroomId: Newsroom['id']) => [Keys.Newsrooms, newsroomId],
    checkSubsourceName: (subSourceName: Newsroom['subSourceName'], ...rest: unknown[]) => [
      Keys.Newsrooms,
      subSourceName,
      ...rest,
    ],
    documents: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
      Keys.Newsrooms,
      newsroomId,
      Keys.Documents,
      ...rest,
    ],
    pressContacts: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
      Keys.Newsrooms,
      newsroomId,
      Keys.PressContacts,
      ...rest,
    ],
    notification: (userId: User['id'], newsroomId: Newsroom['id'], ...rest: unknown[]) => [
      Keys.Newsrooms,
      newsroomId,
      userId,
      Keys.Notification,
      ...rest,
    ],
    pressCoverages: {
      paginated: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
        Keys.Newsrooms,
        newsroomId,
        Keys.PressCoverages,
        ...rest,
      ],
      all: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
        Keys.Newsrooms,
        newsroomId,
        Keys.PressCoverages,
        Keys.All,
        ...rest,
      ],
      infinite: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
        Keys.Newsrooms,
        newsroomId,
        Keys.PressCoverages,
        Keys.Infinite,
        ...rest,
      ],
    },
    events: {
      all: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
        Keys.Newsrooms,
        newsroomId,
        Keys.Events,
        ...rest,
      ],
      byId: (newsroomId: Newsroom['id'], eventId: NewsroomEvent['id'], ...rest: unknown[]) => [
        Keys.Newsrooms,
        newsroomId,
        Keys.Events,
        eventId,
        ...rest,
      ],
    },
    kiosk: {
      infinite: (...rest: unknown[]) => [Keys.Newsrooms, Keys.Kiosk, Keys.Infinite, ...rest],
      byId: (newsroomId: Newsroom['id']) => [Keys.Newsrooms, Keys.Kiosk, newsroomId],
      documents: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
        Keys.Newsrooms,
        Keys.Kiosk,
        newsroomId,
        Keys.Documents,
        Keys.Infinite,
        ...rest,
      ],
      events: {
        all: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
          Keys.Newsrooms,
          Keys.Kiosk,
          newsroomId,
          Keys.Events,
          ...rest,
        ],
        byId: (newsroomId: Newsroom['id'], eventId: NewsroomEvent['id'], ...rest: unknown[]) => [
          Keys.Newsrooms,
          Keys.Kiosk,
          newsroomId,
          Keys.Events,
          eventId,
          ...rest,
        ],
      },
      pressReleases: {
        infinite: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
          Keys.Newsrooms,
          Keys.Kiosk,
          newsroomId,
          Keys.PressReleases,
          Keys.Infinite,
          ...rest,
        ],
        byId: (
          newsroomId: Newsroom['id'],
          pressReleaseId: PressRelease['id'],
          ...rest: unknown[]
        ) => [Keys.Newsrooms, Keys.Kiosk, newsroomId, Keys.PressReleases, pressReleaseId, ...rest],
      },
    },
    minimalPressReleases: {
      all: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
        Keys.Newsrooms,
        newsroomId,
        Keys.PressReleases,
        Keys.Minimal,
        ...rest,
      ],
    },
    pressReleases: {
      all: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
        Keys.Newsrooms,
        newsroomId,
        Keys.PressReleases,
        ...rest,
      ],
      kiosk: (userId: User['id'], ...rest: unknown[]) => [
        Keys.Kiosk,
        userId,
        Keys.PressReleases,
        ...rest,
      ],
      kioskInfinite: (userId: User['id'], ...rest: unknown[]) => [
        Keys.Kiosk,
        userId,
        Keys.PressReleases,
        Keys.Infinite,
        ...rest,
      ],
      infinite: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
        Keys.Newsrooms,
        newsroomId,
        Keys.PressReleases,
        Keys.Infinite,
        ...rest,
      ],
      byId: (
        newsroomId: Newsroom['id'],
        pressReleaseId: PressRelease['id'],
        ...rest: unknown[]
      ) => [Keys.Newsrooms, newsroomId, Keys.PressReleases, pressReleaseId, ...rest],
      publishSettings: (
        newsroomId: Newsroom['id'],
        pressReleaseId: PressRelease['id'],
        ...rest: unknown[]
      ) => [
        Keys.Newsrooms,
        newsroomId,
        Keys.PressReleases,
        pressReleaseId,
        Keys.PublishSettings,
        ...rest,
      ],
    },
    insights: {
      newsroom: {
        globals: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
          Keys.Newsrooms,
          newsroomId,
          Keys.Insights,
          Keys.InsightsGlobals,
          ...rest,
        ],
        uniqueVisitors: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
          Keys.Newsrooms,
          newsroomId,
          Keys.Insights,
          Keys.UniqueVisitors,
          ...rest,
        ],
        pageActivity: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
          Keys.Newsrooms,
          newsroomId,
          Keys.Insights,
          Keys.PageActivity,
          ...rest,
        ],
        followers: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
          Keys.Newsrooms,
          newsroomId,
          Keys.Insights,
          Keys.Followers,
          ...rest,
        ],
      },
      pressReleases: {
        all: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
          Keys.Newsrooms,
          newsroomId,
          Keys.Insights,
          Keys.PressReleases,
          Keys.All,
          ...rest,
        ],
        globals: (newsroomId: Newsroom['id'], ...rest: unknown[]) => [
          Keys.Newsrooms,
          newsroomId,
          Keys.Insights,
          Keys.PressReleases,
          Keys.InsightsGlobals,
          ...rest,
        ],
        byId: (
          newsroomId: Newsroom['id'],
          pressReleaseId: PressRelease['id'],
          ...rest: unknown[]
        ) => [
          Keys.Newsrooms,
          newsroomId,
          Keys.Insights,
          Keys.PressReleases,
          pressReleaseId,
          ...rest,
        ],
      },
    },
    notifications: {
      all: (userId: User['id'], ...rest: unknown[]) => [
        Keys.User,
        userId,
        Keys.Newsrooms,
        Keys.NotificationSettings,
        ...rest,
      ],
    },
  },
  sectors: {
    all: (...rest: unknown[]) => [Keys.Sectors, ...rest],
  },
  brands: {
    all: (...rest: unknown[]) => [Keys.Organization, Keys.Brands, ...rest],
    byId: (brandId: Brand['id']) => [Keys.Brands, brandId],
  },
  newsObjects: {
    all: (...rest: unknown[]) => [Keys.NewsObjects, ...rest],
    // This is needed since the data is structured differently
    infinite: (...rest: unknown[]) => [Keys.NewsObjects, Keys.Infinite, ...rest],
    byId: (
      newsObjectId: NewsObject['uuid'],
      { highlight: _, ...params }: GetNewsObjectParams & Record<string, unknown> = {},
    ) => [Keys.NewsObject, newsObjectId, params],
    duplicates: (newsObjectId: NewsObject['uuid'], ...rest: unknown[]) => [
      Keys.NewsObject,
      Keys.Duplicates,
      newsObjectId,
      ...rest,
    ],
    metaData: (newsObjectId: NewsObject['uuid'], ...rest: unknown[]) => [
      Keys.NewsObject,
      Keys.MetaData,
      newsObjectId,
      ...rest,
    ],
    related: (newsObjectId: NewsObject['uuid'], ...rest: unknown[]) => [
      Keys.NewsObject,
      Keys.Related,
      newsObjectId,
      ...rest,
    ],
    keywords: (newsObjectIds: NewsObject['uuid'][], ...rest: unknown[]) => [
      Keys.NewsObject,
      Keys.Keywords,
      ...newsObjectIds,
      ...rest,
    ],
    snippets: (newsObjectId: NewsObject['uuid'], ...rest: unknown[]) => [
      Keys.NewsObject,
      Keys.Snippets,
      newsObjectId,
      ...rest,
    ],
    minimalById: (newsObjectId: NewsObject['uuid'], ...rest: unknown[]) => [
      Keys.NewsObject,
      Keys.Minimal,
      newsObjectId,
      ...rest,
    ],
    newsletterItem: (
      newsObjectId: NewsObject['uuid'],
      newsletterId: Newsletter['id'],
      newsletterItemId: NewsletterItem['id'],
      ...rest: unknown[]
    ) => [
      Keys.NewsObject,
      newsObjectId,
      [Keys.NewsletterItems, newsletterId, newsletterItemId, ...rest],
    ],
    saved: {
      all: (...rest: unknown[]) => [Keys.NewsObjects, Keys.Saved, ...rest],
    },
    kiosk: {
      all: (...rest: unknown[]) => [Keys.NewsObjects, Keys.Kiosk, ...rest],
      infinite: (...rest: unknown[]) => [Keys.NewsObjects, Keys.Kiosk, Keys.Infinite, ...rest],
    },
    labels: {
      all: (...rest: unknown[]) => [Keys.NewsObjects, Keys.Labels, ...rest],
      infinite: (...rest: unknown[]) => [Keys.NewsObjects, Keys.Labels, Keys.Infinite, ...rest],
    },
    boards: {
      all: (...rest: unknown[]) => [Keys.NewsObjects, Keys.Boards, ...rest],
      infinite: (...rest: unknown[]) => [Keys.NewsObjects, Keys.Boards, Keys.Infinite, ...rest],
    },
    prices: ({ newsuuids = [] }: GetNewsObjectsPriceParams) => [
      Keys.NewsObjects,
      Keys.Price,
      newsuuids.join(','),
    ],
    purchased: (...rest: unknown[]) => [Keys.NewsObjects, Keys.Purchased, ...rest],
    pressContacts: (uuid: NewsObject['uuid'], ...rest: unknown[]) => [
      Keys.NewsObject,
      Keys.PressContacts,
      uuid,
      ...rest,
    ],
  },
  keywords: {
    all: (...rest: unknown[]) => [Keys.Organization, Keys.Keywords, ...rest],
    infinite: (...rest: unknown[]) => [Keys.Organization, Keys.Infinite, Keys.Keywords, ...rest],
  },
  contacts: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Contacts, ...rest],
    allJobsAsync: (...rest: unknown[]) => [Keys.User, Keys.Contacts, Keys.JobsAsync, ...rest],
  },
  contactGroups: {
    all: (...rest: unknown[]) => [Keys.User, Keys.ContactGroups, ...rest],
    byId: (groupId: ContactGroup['id'], ...rest: unknown[]) => [
      Keys.ContactGroups,
      groupId,
      ...rest,
    ],
    contacts: (groupId: ContactGroup['id'], ...rest: unknown[]) => [
      Keys.ContactGroups,
      groupId,
      Keys.Contacts,
      ...rest,
    ],
    allJobsAsync: (...rest: unknown[]) => [Keys.User, Keys.ContactGroups, Keys.JobsAsync, ...rest],
  },
  clipping: {
    related: (newsObjectUuid: NewsObject['uuid'], ...rest: unknown[]) => [
      Keys.NewsObject,
      Keys.Clipping,
      Keys.Related,
      newsObjectUuid,
      ...rest,
    ],
  },
  currentFilter: {
    byUsage: (filterUsage: FilterUsage, ...rest: unknown[]) => [
      Keys.CurrentFilters,
      filterUsage,
      ...rest,
    ],
  },
  deliverables: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Deliverables, ...rest],
    favourite: (...rest: unknown[]) => [Keys.User, Keys.Deliverables, Keys.Favourite, ...rest],
    editions: (deliverableId: Deliverable['id'], ...rest: unknown[]) => [
      Keys.User,
      Keys.Deliverables,
      Keys.Editions,
      deliverableId,
      ...rest,
    ],
  },
  publications: {
    all: (...rest: unknown[]) => [Keys.User, Keys.Publications, ...rest],
  },
  occurrences: {
    bySourceId: (sourceId: Source['id'], ...rest: unknown[]) => [
      Keys.User,
      Keys.Occurrences,
      sourceId,
      ...rest,
    ],
  },
  // For specific endpoints used in the kiosk (feeds / newsletters)
  kiosk: {
    feeds: (...rest: unknown[]) => [Keys.User, Keys.Kiosk, Keys.Feeds, ...rest],
    newsletters: {
      all: (...rest: unknown[]) => [Keys.User, Keys.Kiosk, Keys.Newsletters, ...rest],
      infinite: (...rest: unknown[]) => [
        Keys.User,
        Keys.Kiosk,
        Keys.Infinite,
        Keys.Newsletters,
        ...rest,
      ],
    },
  },
  quickIntegrations: {
    byType: (type: QuickIntegrationParams, ...rest: unknown[]) => [
      Keys.Organization,
      Keys.QuickIntegrations,
      type,
      ...rest,
    ],
  },
  savedSearches: {
    all: (...rest: unknown[]) => [Keys.User, Keys.SavedSearches, ...rest],
    byId: (tagId: Tag['id'], ...rest: unknown[]) => [Keys.User, Keys.SavedSearches, tagId, ...rest],
  },
  searchHistory: {
    all: (...rest: unknown[]) => [Keys.User, Keys.SearchHistory, ...rest],
  },
  tagSettings: {
    byId: (tagId: Tag['id'], ...rest: unknown[]) => [Keys.User, Keys.TagSettings, tagId, ...rest],
  },
  sources: {
    all: (...rest: unknown[]) => [Keys.Sources, ...rest],
    byId: (sourceId: Source['id'], ...rest: unknown[]) => [Keys.Sources, sourceId, ...rest],
    editions: (sourceId: Source['id'], ...rest: unknown[]) => [
      Keys.Sources,
      Keys.Editions,
      sourceId,
      ...rest,
    ],
    subsources: (sourceId: Source['id'], ...rest: unknown[]) => [
      Keys.Sources,
      Keys.Subsources,
      sourceId,
      ...rest,
    ],
  },
  sourceGroups: {
    all: (...rest: unknown[]) => [Keys.SourceGroups, ...rest],
  },
  tags: {
    all: (...rest: unknown[]) => [Keys.Tags, ...rest],
    user: (...rest: unknown[]) => [Keys.User, Keys.Tags, ...rest],
    byId: (tagId: Tag['id'], ...rest: unknown[]) => [Keys.User, Keys.Tags, tagId, ...rest],
    popular: (...rest: unknown[]) => [Keys.User, Keys.Tags, Keys.Popular, ...rest],
    relatedById: (tagId: Tag['id'], ...rest: unknown[]) => [
      Keys.User,
      Keys.Tags,
      Keys.Related,
      tagId,
      ...rest,
    ],
  },
  topics: {
    all: (...rest: unknown[]) => [Keys.Topics, ...rest],
    user: (userId: User['id'], ...rest: unknown[]) => [Keys.User, userId, Keys.Topics, ...rest],
    notifications: {
      all: (userId: User['id'], ...rest: unknown[]) => [
        Keys.User,
        userId,
        Keys.Topics,
        Keys.NotificationSettings,
        ...rest,
      ],
      byId: (userId: User['id'], topicId: Topic['id'], ...rest: unknown[]) => [
        Keys.User,
        userId,
        Keys.Topics,
        topicId,
        Keys.NotificationSettings,
        ...rest,
      ],
    },
  },
  gopressArchive: {
    folders: {
      all: (...rest: unknown[]) => [Keys.GopressArchive, Keys.Folders, ...rest],
      occurrences: (folderId: Folder['id'], ...rest: unknown[]) => [
        Keys.GopressArchive,
        Keys.Folders,
        folderId,
        Keys.Occurrences,
        ...rest,
      ],
    },
    pressReviews: {
      byId: (pressReviewId: PressReview['id']) => [
        Keys.GopressArchive,
        Keys.PressReview,
        pressReviewId,
      ],
    },
    pressReviewItems: {
      newsObjects: (
        pressReviewId: PressReview['id'],
        itemId: PressReviewItem['id'],
        ...rest: unknown[]
      ) => [
        Keys.GopressArchive,
        Keys.PressReview,
        pressReviewId,
        Keys.PressReviewItem,
        itemId,
        Keys.NewsObjects,
        ...rest,
      ],
    },
    newsObjects: {
      byId: (uuid: GopressNewsObject['uuid'], ...rest: unknown[]) => [
        Keys.GopressArchive,
        Keys.NewsObject,
        uuid,
        ...rest,
      ],
      all: (...rest: unknown[]) => [Keys.NewsObjects, Keys.GopressArchive, rest],
    },
  },
  agenda: {
    all: (...rest: unknown[]) => [Keys.AgendaEvents, ...rest],
    byId: (agendaEventId: AgendaEvent['id']) => [Keys.AgendaEvents, agendaEventId],
    rubrics: (...rest: unknown[]) => [Keys.AgendaEvents, Keys.Rubrics, ...rest],
    coverages: (agendaEventId: AgendaEvent['id'], ...rest: unknown[]) => [
      Keys.AgendaEvents,
      agendaEventId,
      Keys.Coverages,
      ...rest,
    ],
  },
  strapi: {
    belgaShareUpsell: (...rest: unknown[]) => [Keys.Strapi, Keys.BelgaShareUpsell, ...rest],
  },
  // Un-nested query keys
  exploreSections: (...rest: unknown[]) => [Keys.ExploreSections, ...rest],
  mediumTypeGroups: (...rest: unknown[]) => [Keys.MediumTypeGroups, ...rest],
  maintenanceMessages: [Keys.MaintenanceMessages],
  embedVideoDetails: (url?: string) => [Keys.NewsObject, Keys.EmbedVideoDetails, url],
};
