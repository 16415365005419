import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import { Job, JobTypes } from './jobs.types';

type JobsState = {
  jobs: { type: JobTypes; id: Job['requestId'] }[];
};

export const jobQueuePersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.job-queue`,
  storage,
  whitelist: ['jobIds'],
};

const INITIAL_STATE: JobsState = {
  jobs: [],
};

const jobQueueSlice = createSlice({
  name: 'jobs',
  initialState: INITIAL_STATE,
  reducers: {
    ADD_JOB: (state, action: PayloadAction<{ type: JobTypes; id: Job['requestId'] }>) => {
      return { jobs: [...state.jobs, action.payload] };
    },
    REMOVE_JOBS: (state, action: PayloadAction<Job['requestId'][]>) => {
      return { jobs: state.jobs.filter(({ id }) => !action.payload.includes(id)) };
    },
    RESET_JOBS: () => ({ jobs: [] }),
  },
});

export const { actions: jobQueueActions } = jobQueueSlice;

export default jobQueueSlice.reducer;
