import { Box, ButtonProps, Typography, useTheme } from '@mui/material';
import React from 'react';

import { SnackbarTypes } from 'types/notification.types';

import Icon, { IconNames } from 'components/@icons';

import { ActionButton, ActionSpacer } from './Snackbar.style';

interface Props {
  title?: string | null;
  message?: string | null;
  type: SnackbarTypes;
  icon: IconNames;
  actions?: ({ message: string; buttonProps?: ButtonProps } | React.ReactNode)[];
}

const Snackbar = ({ type, message, title = null, icon, actions }: Props) => {
  const theme = useTheme();

  const colorLookup = {
    [SnackbarTypes.Success]: theme.palette.accent03[400],
    [SnackbarTypes.Warning]: theme.palette.accent02[400],
    [SnackbarTypes.Error]: theme.palette.accent01[400],
    [SnackbarTypes.Info]: theme.palette.neutral01[400],
  };
  const color = colorLookup[type];

  return (
    <Box
      bgcolor={color}
      width="100%"
      height="100%"
      display="flex"
      padding={2}
      sx={{ wordBreak: 'break-word' }}
    >
      {icon && (
        <Box flexShrink="0" textAlign="center" mr={2}>
          <Icon name={icon} color="neutral01.700" size={24} />
        </Box>
      )}
      <Box flexGrow={1} paddingTop={0.5} paddingRight={4}>
        {title && (
          <Typography variant="paragraph02" color="neutral01.700" margin="none" fontWeight={600}>
            {title}
          </Typography>
        )}
        {message && (
          <Typography variant="paragraph02" color="neutral01.700" margin="none">
            {message}
          </Typography>
        )}
        {actions && !!actions.length && (
          <Box mt={1} display="flex">
            {actions.map((action, i: number) => {
              const key = `action-${i}`;
              if (action && typeof action === 'object' && 'message' in action) {
                const { message: actionMessage, buttonProps, ...rest } = action;
                return (
                  <div key={key}>
                    {i > 0 && <ActionSpacer />}
                    <ActionButton {...buttonProps} {...rest}>
                      {actionMessage}
                    </ActionButton>
                  </div>
                );
              }
              return action;
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Snackbar;
