import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { Store } from 'redux';

import { StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService, featureFlagService } from 'services';
import { queryClient } from 'services/react-query/config';
import { intlActions } from 'store/intl/intl.slice';
import { User } from 'types/user.types';

import { UserDisabledError, UserFetchError, UserSettingsFetchError } from './errors';

const fetchCurrentUser = async () => {
  try {
    const user = await queryClient.fetchQuery({
      queryKey: QueryKeys.user.current,
      queryFn: async () => (await apiService.fetchCurrentUser()).data,
      staleTime: StaleTime.FOREVER,
    });

    if (!user.active) {
      throw new UserDisabledError('Account is not active');
    }

    Sentry.setUser({ id: user.id.toString() });

    return user;
  } catch (error) {
    if (error instanceof UserDisabledError) throw error;

    if (
      'isAxiosError' in (error as Error) &&
      (error as AxiosError).response?.status === 403 &&
      ['INACTIVE_USER', 'INACTIVE_ORGANIZATION'].includes(
        (error as AxiosError).response?.data?.exceptionReason,
      )
    ) {
      throw new UserDisabledError('Account is not active');
    }

    throw new UserFetchError((error as Error).message);
  }
};

const fetchUserSettings = async (user: User) => {
  try {
    const settings = await queryClient.fetchQuery({
      queryKey: QueryKeys.user.settings,
      queryFn: async () => (await apiService.fetchSettings(user.id)).data,
      staleTime: StaleTime.FOREVER,
    });

    // Set the feature flags for the user
    featureFlagService.setUserFeatureFlags(settings.kioskSettings.featureFlags);

    return settings;
  } catch (e) {
    throw new UserSettingsFetchError((e as Error).message);
  }
};

export const fetchUserAndSettings = async ({ store }: { store: Store }) => {
  const user = await fetchCurrentUser();
  store.dispatch(intlActions.UPDATE_LANGUAGE(user.language));

  const settings = await fetchUserSettings(user);

  return { user, settings };
};
