import { CancelToken } from 'axios';

import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import {
  Folder,
  GopressFolderSearchParams,
  GopressNewsObject,
  GopressNewsObjectSearchParams,
  NewsObjectsPaginationParams,
  OccurrenceParams,
  PressReview,
  PressReviewItem,
  PressReviewOccurrence,
  UpdateGopressTitleParams,
} from 'types/gopress-archive.types';
import { GetNewsObjectParams } from 'types/newsobject.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export default {
  fetchGopressFolders(
    this: RestApiService,
    userId: User['id'],
    params?: GopressFolderSearchParams,
  ) {
    const cleanParams = removeEmptyValuesFromParams(params);

    return this.api.get<ApiResponse<Folder[]>>(`/users/${userId}/kiosk/gopressarchive/folders`, {
      params: cleanParams,
    });
  },
  updateGopressFolderTitle(
    this: RestApiService,
    userId: User['id'],
    params: UpdateGopressTitleParams,
  ) {
    return this.api.put<Folder>(`/gopressarchive/folders/${params.id}`, params);
  },
  fetchGopressFolderOccurrences(
    this: RestApiService,
    folderId: Folder['id'],
    params: OccurrenceParams,
  ) {
    return this.api.get<ApiResponse<PressReviewOccurrence[]>>(
      `/gopressarchive/folders/${folderId}/occurrences`,
      { params },
    );
  },
  fetchGopressPressReview(this: RestApiService, pressReviewId: PressReview['id']) {
    return this.api.get<PressReview>(`/gopressarchive/pressreviews/${pressReviewId}`);
  },
  fetchGopressPressReviewItemNewsObjects(
    this: RestApiService,
    pressReviewId: PressReview['id'],
    itemId: PressReviewItem['id'],
    params?: NewsObjectsPaginationParams,
    cancelToken?: CancelToken,
  ) {
    return this.api.get<ApiResponse<GopressNewsObject[]>>(
      `/gopressarchive/pressreviews/${pressReviewId}/items/${itemId}/newsobjects`,
      { params, cancelToken },
    );
  },
  fetchGopressNewsObject(
    this: RestApiService,
    uuid: GopressNewsObject['uuid'],
    params: GetNewsObjectParams = {},
  ) {
    return this.api.get<GopressNewsObject>(`/gopressarchive/newsobjects/${uuid}`, {
      params,
    });
  },
  fetchGopressNewsObjects(this: RestApiService, params: GopressNewsObjectSearchParams) {
    return this.api.get<ApiResponse<GopressNewsObject[]>>(`/gopressarchive/newsobjects`, {
      params,
    });
  },
};
