import posthog from 'posthog-js';
import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useCurrentUser, useUserSettings } from 'queries/user';
import { useAnalytics } from 'services/analytics/AnalyticsProvider';

import { Routes } from 'pages/routes.constants';

import AppBar from 'components/@navigation/AppBar';

import { Wrap } from './ApplicationContainer.style';
import { useSetRedirectURL } from './hooks';

const ROUTES_WITHOUT_APPBAR = [
  Routes.Share,
  Routes.Maintenance,
  Routes.SessionHijacked,
  Routes.Unsubscribe,
  Routes.Register,
];

const ApplicationContainer = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { trackPageView } = useAnalytics();
  const user = useCurrentUser();
  const { settings } = useUserSettings();
  const isPublic = !!matchPath(location.pathname, { path: ROUTES_WITHOUT_APPBAR });
  useSetRedirectURL();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname, trackPageView]);

  useEffect(() => {
    if (!user || !posthog || !settings) return;

    posthog?.identify(user.id.toString(), {
      organisation: user.organization.name,
      followed_brands: user.favoriteSources?.length || 0,
      user_type: user.userType,
      featureFlags: settings?.kioskSettings.featureFlags,
      followed_topics: user.followedTopics?.length || 0,
      followed_newsrooms: user.followedNewsrooms?.length || 0,
    });
  }, [settings, user]);

  return (
    <Wrap className={isPublic ? '' : 'with-app-bar'}>
      {!isPublic && <AppBar />}
      {children}
    </Wrap>
  );
};

export default ApplicationContainer;
