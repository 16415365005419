import { SnackbarProvider } from 'notistack';
import React from 'react';

import { notificationService } from 'services';
import Notification from 'services/notifications/Notification';

import { DEFAULT_AUTO_HIDE } from './constants';
// add class to override default styles and make it responsive
import styles from './snackbar.style.module.css';

interface Props {
  children?: React.ReactNode;
}

const NotificationProvider = ({ children }: Props) => {
  return (
    <SnackbarProvider
      ref={(ref: SnackbarProvider) => notificationService?.setSnackbarRef(ref)}
      hideIconVariant={true}
      maxSnack={5}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      autoHideDuration={DEFAULT_AUTO_HIDE}
      preventDuplicate={true}
      Components={{
        default: Notification,
      }}
      classes={{
        containerRoot: styles.snackbarContainer,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotificationProvider;
