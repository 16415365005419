import { Components, Theme } from '@mui/material';

export const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    track: ({ theme, ownerState }) => ({
      backgroundColor: ownerState.color === 'secondary' ? theme.palette.common.white : undefined,
    }),
  },
};
