import * as Sentry from '@sentry/react';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import { UserLanguages } from 'types/user.types';

import MESSAGES_EN from './en.json';
import MESSAGES_FR from './fr.json';
import MESSAGES_NL from './nl.json';

export const userLanguageToLocale = (language: UserLanguages): string => {
  switch (language) {
    case UserLanguages.EN:
      return 'en-GB';
    case UserLanguages.NL:
      return 'nl';
    case UserLanguages.FR:
      return 'fr';
    default:
      return 'en-GB';
  }
};

export const localeToUserLanguage = (locale: string): UserLanguages => {
  switch (locale) {
    case 'en-GB':
      return UserLanguages.EN;
    case 'nl':
      return UserLanguages.NL;
    case 'fr':
      return UserLanguages.FR;
    default:
      return UserLanguages.EN;
  }
};

const messages: { [key in UserLanguages]: Record<I18nKey, string> } = {
  [UserLanguages.EN]: MESSAGES_EN,
  [UserLanguages.DE]: MESSAGES_EN as typeof MESSAGES_EN,
  [UserLanguages.FR]: MESSAGES_FR as unknown as typeof MESSAGES_EN,
  [UserLanguages.NL]: MESSAGES_NL as unknown as typeof MESSAGES_EN,
};

const intlCache = createIntlCache();

export const createIntlConfig = (language: UserLanguages) =>
  createIntl(
    {
      locale: userLanguageToLocale(language),
      messages: messages[language],
      onError: (error: unknown) => {
        console.error(error);
        Sentry.captureException(error);
      },
    },
    intlCache,
  );

export const intl: { [key in UserLanguages]: IntlShape } = {
  [UserLanguages.EN]: createIntlConfig(UserLanguages.EN),
  [UserLanguages.DE]: createIntlConfig(UserLanguages.DE),
  [UserLanguages.NL]: createIntlConfig(UserLanguages.NL),
  [UserLanguages.FR]: createIntlConfig(UserLanguages.FR),
};
