import 'rangy/lib/rangy-classapplier';
import 'rangy/lib/rangy-highlighter';
import 'rangy/lib/rangy-textrange';

import badlyTypedRangy from 'rangy';
import React, { useMemo, useState } from 'react';

interface RangyHighlighter {
  highlights: object[];
  addClassApplier: (classApplier: RangyClassApplier) => void;
  highlightSelection: (identifier: string, options: { containerElementId: string }) => void;
  unhighlightSelection: () => void;
  serialize: () => string;
  deserialize: (serializedHighlights: string) => void;
  removeAllHighlights: () => void;
}

type RangyClassApplier = object;

// wasn't working with module augmentation so doing it like this...
const rangy = badlyTypedRangy as typeof badlyTypedRangy & {
  createHighlighter: (
    document: Document | undefined,
    type: 'textContent' | 'TextRange',
  ) => RangyHighlighter;
  createClassApplier: (identifier: string, options: Record<string, unknown>) => RangyClassApplier;
};

const MarkTextContext = React.createContext<RangyHighlighter | null>(null);

export const useTextMarker = () => {
  const textMarker = React.useContext(MarkTextContext);

  if (!textMarker) {
    throw new Error('useMarkTextContext must be used within a MarkTextProvider');
  }

  const methods = useMemo(
    () => ({
      textMarker,
      markText: (containerElementId: string) =>
        textMarker.highlightSelection(HIGHLIGHT_CLASS_APPLIER, { containerElementId }),
      unmarkText: () => textMarker.unhighlightSelection(),
      serialize: () => textMarker.serialize(),
      deserialize: (serializedHighlights: string) => textMarker.deserialize(serializedHighlights),
      clear: () => textMarker.removeAllHighlights(),
    }),
    [textMarker],
  );

  return methods;
};

interface Props {
  children: React.ReactNode;
}

const HIGHLIGHT_CLASS_APPLIER = 'highlight';

const MarkTextProvider = ({ children }: Props) => {
  const [textMarker] = useState(() => {
    const textMarker = rangy.createHighlighter(undefined, 'TextRange');

    textMarker.addClassApplier(
      rangy.createClassApplier(HIGHLIGHT_CLASS_APPLIER, {
        ignoreWhiteSpace: true,
        elementTagName: 'em',
      }),
    );

    return textMarker;
  });

  return <MarkTextContext.Provider value={textMarker}>{children}</MarkTextContext.Provider>;
};

export default MarkTextProvider;
