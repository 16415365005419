import { Components, Theme, tooltipClasses } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    arrow: ({ theme }) => ({
      '&:before': {
        backgroundColor: theme.palette.neutral01[100],
      },
    }),
    tooltip: ({ theme }) => ({
      ...theme.typography.paragraph02,
      [`&.${tooltipClasses.arrow}`]: {
        backgroundColor: theme.palette.neutral01[100],
      },
      backgroundColor: theme.palette.neutral01[100],
      color: theme.palette.neutral01[700],
    }),
  },
};
