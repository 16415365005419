import { Components, Theme } from '@mui/material';

export const MuiList: Components<Theme>['MuiList'] = {
  styleOverrides: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
