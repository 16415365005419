import { buttonClasses, Components, Theme } from '@mui/material';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  styleOverrides: {
    root: {
      padding: 0,
      [`&.${buttonClasses.disabled}`]: {
        opacity: 0.5,
        cursor: 'not-allowed',
        pointerEvents: 'auto',
      },
    },
    colorPrimary: ({ theme }) => ({
      '& svg': {
        fill: theme.palette.neutral01[300],
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          fill: theme.palette.global01[400],
        },
      },
    }),
    colorSecondary: ({ theme }) => ({
      '& svg': {
        fill: theme.palette.global01[400],
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          fill: theme.palette.global01[300],
        },
      },
    }),
    colorError: ({ theme }) => ({
      color: theme.palette.accent01[400],
      '&::after': {
        borderBottomColor: theme.palette.accent01[400],
      },
      '& svg': {
        fill: theme.palette.accent01[400] + ' !important',
      },
      '&:hover': {
        color: theme.palette.accent01[400],
        '&::after': {
          borderBottomColor: theme.palette.accent01[400],
        },
        '& svg': {
          fill: theme.palette.accent01[400] + ' !important',
        },
      },
    }),
  },
};
