import axios, { AxiosResponse } from 'axios';

import config from 'config';

import { EnvJson } from 'services/env/EnvService';

export async function initEnv() {
  const response: AxiosResponse<EnvJson> = await axios.get('/env.json', {
    headers: { 'Content-Type': 'application/json' },
  });
  config.setRemoteConfig(response.data);
  return response.data;
}
