import { setDefaultOptions } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import { useEffect } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { RawIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { createIntlConfig, intl } from 'services/i18n/config';
import { getLanguage } from 'store/intl/intl.selectors';
import { UserLanguages } from 'types/user.types';

registerLocale('enGB', enGB);
registerLocale('nl', nl);
registerLocale('fr', fr);

const BelgaIntlProvider = ({ children }: { children: React.ReactNode }) => {
  const language = useSelector(getLanguage);

  useEffect(() => {
    switch (language) {
      case UserLanguages.EN:
        setDefaultLocale('enGB');
        setDefaultOptions({ locale: enGB });
        break;
      case UserLanguages.NL:
        setDefaultLocale('nl');
        setDefaultOptions({ locale: nl });
        break;
      case UserLanguages.FR:
        setDefaultLocale('fr');
        setDefaultOptions({ locale: fr });
        break;
      case UserLanguages.DE:
        setDefaultLocale('de');
        // We don't have German translations, default to English
        setDefaultOptions({ locale: enGB });
        break;
      default:
        // language not found, default to English
        setDefaultLocale('enGB');
        setDefaultOptions({ locale: enGB });
        break;
    }
  }, [language]);

  return (
    <RawIntlProvider value={intl?.[language] ?? createIntlConfig(UserLanguages.EN)}>
      {children}
    </RawIntlProvider>
  );
};

export default BelgaIntlProvider;
