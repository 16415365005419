import { Box, BoxProps, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props extends BoxProps {
  title: I18nKey;
}

const Chip = ({ title, ...rest }: Props) => {
  return (
    <Box
      component="span"
      display="flex"
      height="16px"
      alignItems="center"
      px={0.5}
      borderRadius={8}
      bgcolor="global01.600"
      color="global01.400"
      width="fit-content"
      {...rest}
    >
      <Typography variant="label01" textTransform="uppercase" fontWeight={600} margin="none">
        <FormattedMessage id={title} />
      </Typography>
    </Box>
  );
};

export default Chip;
