import { SxProps, Theme } from '@mui/material';

import Chip from '../Chip';

interface Props {
  sx?: SxProps<Theme>;
}

const NewChip = ({ sx }: Props) => {
  return <Chip title="common.new" sx={sx} />;
};

export default NewChip;
