import { BelgaContext, NewsObjectContext } from 'types/newsobject.types';

export const removeEmptyValuesFromParams = <T extends object>(params?: T) => {
  if (!params) return undefined;

  return (Object.keys(params) as (keyof T)[]).reduce<Record<string, string | boolean | number>>(
    (cleanParams, property) => {
      // Filter out: undefined, null and ''
      // this is because `0` will also be falsy, but, it is a valid value for a query param, for example: count=0
      if (
        // @ts-ignore
        params[property] !== '' &&
        params[property] !== null &&
        typeof params[property] !== 'undefined'
      ) {
        // @ts-ignore
        cleanParams[property] = params[property] as string | boolean | number;
      }

      return cleanParams;
    },
    {},
  );
};

export const belgaContextHeaders = (context?: NewsObjectContext) =>
  Object.entries({
    'X-Belga-Context': context?.belgaContext || BelgaContext.KIOSK,
    'X-Belga-Context-Id': context?.belgaContextId,
  }).reduce<Record<string, string>>((a, [k, v]) => (!!v ? ((a[k] = `${v}`), a) : a), {});
