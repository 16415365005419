import { ApiResponse } from 'types/api.types';
import { Newsletter } from 'types/newsletter.types';
import {
  ArticlesRead,
  ExportNewsletterRecipientsParams,
  ExportNewsletterStatisticsParams,
  ExportRecipientsParams,
  NewsletterRecipient,
  NewsletterRecipientsParams,
  NewsletterStatistic,
  NewsletterStatisticsParams,
  OpenRate,
  Recipient,
  RecipientsParams,
  StatisticsByTypeParams,
  StatisticsMeta,
  UniqueReader,
} from 'types/newsletter-statistics.types';

import RestApiService from '.';

export function fetchUniqueReaders(this: RestApiService, params: StatisticsByTypeParams) {
  return this.api.get<ApiResponse<UniqueReader[], StatisticsMeta>>(
    '/reporting/newsletterstatistics/uniquereaders',
    {
      params,
    },
  );
}

export function fetchOpenRates(this: RestApiService, params: StatisticsByTypeParams) {
  return this.api.get<ApiResponse<OpenRate[], StatisticsMeta>>(
    '/reporting/newsletterstatistics/openrates',
    {
      params,
    },
  );
}

export function fetchArticlesRead(this: RestApiService, params: StatisticsByTypeParams) {
  return this.api.get<ApiResponse<ArticlesRead[], StatisticsMeta>>(
    '/reporting/newsletterstatistics/articlesread',
    {
      params,
    },
  );
}

export function fetchNewsletterStatistics(
  this: RestApiService,
  params: NewsletterStatisticsParams,
) {
  return this.api.get<ApiResponse<NewsletterStatistic[]>>(
    '/reporting/newsletterstatistics/newsletters',
    { params },
  );
}

export function fetchRecipients(this: RestApiService, params: RecipientsParams) {
  return this.api.get<ApiResponse<Recipient[]>>(
    '/reporting/newsletterstatistics/newsletters/recipients',
    {
      params,
    },
  );
}

export function fetchNewsletterRecipients(
  this: RestApiService,
  newsletterId: Newsletter['id'],
  params: NewsletterRecipientsParams,
) {
  return this.api.get<ApiResponse<NewsletterRecipient[]>>(
    `/reporting/newsletterstatistics/newsletters/${newsletterId}/recipients`,
    { params },
  );
}

export function exportNewsletterRecipients(
  this: RestApiService,
  newsletterId: Newsletter['id'],
  params: ExportNewsletterRecipientsParams,
) {
  return this.api.post(
    `/reporting/newsletterstatistics/newsletters/${newsletterId}/recipients/export`,
    { params },
  );
}

export function exportNewsletterStatistics(
  this: RestApiService,
  params: ExportNewsletterStatisticsParams,
) {
  return this.api.post(
    '/reporting/newsletterstatistics/newsletters/export',
    {},
    {
      params,
    },
  );
}

export function exportRecipients(this: RestApiService, params: ExportRecipientsParams) {
  return this.api.post(
    '/reporting/newsletterstatistics/newsletters/recipients/export',
    {},
    {
      params,
    },
  );
}
