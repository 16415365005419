import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontSize: 14,
  fontFamily: '"Proxima Nova", sans-serif',
  heading01: {
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: 30,
    lineHeight: '32px',
    fontWeight: 600,
  },
  heading02: {
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 600,
  },
  heading03: {
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: 22,
    lineHeight: '24px',
    fontWeight: 600,
  },
  heading04: {
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 600,
  },
  heading05: {
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
  },
  heading06: {
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
  },
  paragraph01: {
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: 14,
    lineHeight: '21px',
  },

  paragraph02: {
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: 12,
    lineHeight: '16px',
  },

  button01: {
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: 13,
    lineHeight: '16px',
  },
  label01: {
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: 10,
    lineHeight: '10px',
  },
};
