import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { differenceInDays, subDays, subWeeks, subYears } from 'date-fns';
import { cloneDeep } from 'lodash-es';

import { ComparisonType, DateRange, PeriodComparison, serializeDateRange } from 'utils/date.utils';

import { CHART_COLORS } from 'constants/chart.constants';
import {
  AnalysisDataset,
  ChartDisplays,
  ChartIdentifier,
  ChartTypes,
  CheckboxFilterPayload,
  CheckboxValues,
  FiltersEnum,
  FilterState,
  RangeFilterPayload,
  RangeValues,
  SelectedSegment,
} from 'types/analysis.types';
import { Collaboration } from 'types/collaboration.types';

export type AnalysisState = {
  title?: string;
  order: string;
  collaboration?: Collaboration;
  chartType: ChartTypes;
  chartDisplay: ChartDisplays;
  selectedSegments: { [key: string]: SelectedSegment | null };
  datasets: AnalysisDataset[];
  filters: FilterState;
  comparisonType: null | ComparisonType;
  periodComparison: null | PeriodComparison;
};

const INITIAL_FILTER_STATE: FilterState = {
  [FiltersEnum.MediumTypeGroup]: {},
  [FiltersEnum.SourceGroup]: {},
  [FiltersEnum.Source]: {},
  [FiltersEnum.Sentiment]: {},
  [FiltersEnum.MediaValue]: { min: '', max: '' },
  [FiltersEnum.AudienceReach]: { min: '', max: '' },
  [FiltersEnum.Language]: {},
  [FiltersEnum.Country]: {},
  [FiltersEnum.CollapseDuplicates]: false,
  excludednewsobjectuuids: [],
};

const INITIAL_STATE: AnalysisState = {
  title: undefined,
  order: '-PUBLISHDATE',
  collaboration: undefined,
  chartType: ChartTypes.AUDIENCE_REACHED,
  chartDisplay: ChartDisplays.Bar,
  selectedSegments: {},
  datasets: [],
  filters: INITIAL_FILTER_STATE,
  comparisonType: null,
  periodComparison: PeriodComparison.PREVIOUS_PERIOD_MATCH,
};

const analysisSlice = createSlice({
  name: 'analysis',
  initialState: INITIAL_STATE,
  reducers: {
    INIT_STATE: (state, action: PayloadAction<Partial<AnalysisState>>) => {
      return { ...state, ...action.payload };
    },
    RESET_STATE: () => {
      return INITIAL_STATE;
    },
    SET_ORDER: (state, action: PayloadAction<string>) => {
      state.order = action.payload;
    },
    SET_COMPARISON_TYPE: (state, action: PayloadAction<ComparisonType | null>) => {
      state.selectedSegments = {};
      state.comparisonType = action.payload;

      if (action.payload === ComparisonType.PERIODS) {
        updatePeriodComparisonDataset(state);
      }

      if (action.payload === null) {
        state.datasets = [state.datasets[0]];
      }
    },
    SET_PERIOD_COMPARISON: (state, action: PayloadAction<PeriodComparison>) => {
      state.selectedSegments = {};
      state.periodComparison = action.payload;

      state.datasets[1].dateRange = inferNextComparisonDaterange({
        datasets: state.datasets,
        periodComparison: state.periodComparison,
      });
    },
    UPDATE_DATASET: (state, action: PayloadAction<{ index: number; dataset: AnalysisDataset }>) => {
      state.selectedSegments = {};
      state.datasets[action.payload.index] = action.payload.dataset;

      if (action.payload.index > 0) {
        state.comparisonType = ComparisonType.DATASETS;
      }

      updatePeriodComparisonDataset(state);
    },
    UPDATE_DATASET_COLOR: (state, action: PayloadAction<{ index: number; color: string }>) => {
      state.datasets[action.payload.index].hexColor = action.payload.color;
    },
    UPDATE_DATERANGE: (state, action: PayloadAction<DateRange>) => {
      state.selectedSegments = {};

      // if we're comparing datasets, update the date of all datasets
      if (!state.comparisonType || state.comparisonType === ComparisonType.DATASETS) {
        state.datasets = state.datasets.map(dataset => ({
          ...dataset,
          dateRange: serializeDateRange(action.payload),
        }));
      }

      state.datasets[0].dateRange = serializeDateRange(action.payload);
      updatePeriodComparisonDataset(state);
    },
    REMOVE_DATASET: (state, action: PayloadAction<{ index: number }>) => {
      state.selectedSegments = {};
      if (action.payload.index === 0 && state.comparisonType === ComparisonType.PERIODS) {
        state.datasets = [];
      }

      state.datasets.splice(action.payload.index, 1);

      if (state.datasets.length <= 1) {
        state.comparisonType = null;
      }
    },
    UPDATE_CHART_TYPE: (state, action: PayloadAction<ChartTypes>) => {
      state.selectedSegments = {};
      state.chartType = action.payload;
    },
    UPDATE_CHART_DISPLAY: (state, action: PayloadAction<ChartDisplays>) => {
      state.chartDisplay = action.payload;
    },
    CLEAR_SELECTED_SEGMENTS: state => {
      state.selectedSegments = {};
    },
    UPDATE_SELECTED_SEGMENT: (
      state,
      action: PayloadAction<{ chartId: ChartIdentifier; segment: SelectedSegment | null }>,
    ) => {
      state.selectedSegments[action.payload.chartId] = action.payload.segment;
      // Ensure that when we select a source group we clear the source selection
      if (action.payload.chartId === ChartIdentifier.SourceGroups) {
        state.selectedSegments[ChartIdentifier.Sources] = null;
      }
    },
    // Filters
    CLEAR_FILTERS: state => {
      state.selectedSegments = {};
      state.filters = INITIAL_FILTER_STATE;
    },
    UPDATE_CHECKBOX_FILTER: (state, action: PayloadAction<CheckboxFilterPayload>) => {
      state.selectedSegments = {};
      const { name, values } = action.payload;
      (state.filters[name] as CheckboxValues) = values;
    },
    UPDATE_DUPLICATES_FILTER: (state, action: PayloadAction<boolean>) => {
      state.selectedSegments = {};
      state.filters[FiltersEnum.CollapseDuplicates] = action.payload;
    },
    UPDATE_RANGE_FILTER: (state, action: PayloadAction<RangeFilterPayload>) => {
      state.selectedSegments = {};
      const { name, value } = action.payload;

      (state.filters[name] as RangeValues) = value;
    },
  },
});

export const { actions: analysisActions } = analysisSlice;

export default analysisSlice.reducer;

const updatePeriodComparisonDataset = (state: AnalysisState) => {
  // if we're comparing periods, update the date of the first dataset and infer the date of the second dataset
  if (state.comparisonType === ComparisonType.PERIODS) {
    state.datasets[1] = cloneDeep({
      ...state.datasets[0],
      // keep the same color if it was set, or set the color
      hexColor: state.datasets[1]?.hexColor || CHART_COLORS[1],
      // set the dateRange for the comparison dataset
      dateRange: inferNextComparisonDaterange({
        datasets: state.datasets,
        periodComparison: state.periodComparison,
      }),
    });
  }
};

function inferNextComparisonDaterange({
  datasets,
  periodComparison,
}: {
  datasets: AnalysisState['datasets'];
  periodComparison: AnalysisState['periodComparison'];
}) {
  if (datasets[0].dateRange.startDate && datasets[0].dateRange.endDate) {
    let startDate = new Date(datasets[0].dateRange.startDate);
    let endDate = new Date(datasets[0].dateRange.endDate);

    switch (periodComparison) {
      case PeriodComparison.LAST_YEAR:
        startDate = subYears(startDate, 1);
        endDate = subYears(endDate, 1);
        break;
      case PeriodComparison.LAST_YEAR_MATCH:
        startDate = subWeeks(startDate, 52);
        endDate = subWeeks(endDate, 52);
        break;
      case PeriodComparison.PREVIOUS_PERIOD:
        const difference = differenceInDays(endDate, startDate) + 1;
        startDate = subDays(startDate, difference);
        endDate = subDays(endDate, difference);
        break;
      case PeriodComparison.PREVIOUS_PERIOD_MATCH:
        while (endDate > new Date(datasets[0].dateRange.startDate)) {
          startDate = subWeeks(startDate, 1);
          endDate = subWeeks(endDate, 1);
        }
        break;
    }

    return {
      startDate: startDate.toUTCString(),
      endDate: endDate.toUTCString(),
    };
  } else {
    return { startDate: null, endDate: null };
  }
}
