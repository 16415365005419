import { checkboxClasses, Components, Theme } from '@mui/material';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      cursor: 'pointer',
      padding: 2,
      color: theme.palette.neutral01[400],
      [`&.${checkboxClasses.checked}`]: {
        color: theme.palette.global01[400],
        svg: {
          fill: theme.palette.global01[400],
        },
      },
      [`&.${checkboxClasses.disabled}`]: {
        cursor: 'not-allowed',
        '& *': {
          color: theme.palette.neutral01[600],
          fill: theme.palette.neutral01[600],
        },
      },
    }),
    colorSecondary: ({ theme }) => ({
      color: theme.palette.neutral01[700],

      [`&.${checkboxClasses.checked}`]: {
        color: theme.palette.neutral01[700],
        svg: {
          fill: theme.palette.neutral01[700],
        },
      },
    }),
  },
};
