import { CancelToken } from 'axios';

import {
  transformRequest,
  transformResponse,
} from 'services/api/axios/transformers/body-key.transformer';
import { CurrentFilter, CurrentFilters, FilterUsage } from 'types/current-filter.types';
import { User } from 'types/user.types';

import RestApiService from '.';

const parseJSON = (data: string) => {
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

interface CurrentFilterAPI {
  id: number;
  currentFilters: {
    [key: string]: string;
  };
}

const parseCurrentFilter = <T = CurrentFilters>(data: CurrentFilterAPI): CurrentFilter<T> => ({
  id: data.id,
  currentFilters: Object.keys(data.currentFilters || {}).reduce<T>((result, key) => {
    result[key as keyof T] = parseJSON(data.currentFilters[key]);
    return result;
  }, {} as T),
});

const stringifyCurrentFilter = <T = CurrentFilter>(data: CurrentFilter<T>): CurrentFilterAPI => ({
  id: data.id,
  // @ts-ignore
  currentFilters: Object.keys(data.currentFilters).reduce<CurrentFilterAPI['currentFilters']>(
    (result, key) => {
      result[key] = JSON.stringify(data.currentFilters[key as keyof T]);
      return result;
    },
    {},
  ),
});

export function fetchCurrentFilters<T = CurrentFilters>(
  this: RestApiService,
  userId: User['id'],
  filterUsage: FilterUsage,
) {
  return this.api.get<CurrentFilter<T>>(`/users/${userId}/currentfilters/${filterUsage}`, {
    transformResponse: [transformResponse, parseCurrentFilter],
  });
}

export function updateCurrentFilters<T = CurrentFilters>(
  this: RestApiService,
  userId: User['id'],
  filterUsage: FilterUsage,
  currentFilter: CurrentFilter<T>,
  cancelToken?: CancelToken,
) {
  return this.api.put<CurrentFilter<T>>(
    `/users/${userId}/currentfilters/${filterUsage}`,
    currentFilter,
    {
      cancelToken,
      transformRequest: [stringifyCurrentFilter, transformRequest],
      transformResponse: [transformResponse, parseCurrentFilter],
    },
  );
}
