import { useQuery } from '@tanstack/react-query';
import Axios from 'axios';

import { StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { MaintenanceMessage } from 'types/maintenance-message.types';

const fetchMaintenanceMessages = async () => {
  const source = Axios.CancelToken.source();
  const promise = apiService.fetchMaintenanceMessages(source.token);
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Cancelled by react-query');
  };
  const { data } = await promise;

  return data;
};

export const useMaintenanceMessages = (config: CommonQueryOptions<MaintenanceMessage> = {}) => {
  const { data, isLoading, status } = useQuery({
    queryKey: QueryKeys.maintenanceMessages,
    queryFn: fetchMaintenanceMessages,
    staleTime: StaleTime.FIVE_MIN,
    ...config,
  });

  return {
    data,
    isLoading,
    status,
  };
};
