import { isAnyOf } from '@reduxjs/toolkit';
import axios from 'axios';

import { extractEnabledKeys } from 'utils/search.utils';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService, featureFlagService } from 'services';
import { queryClient } from 'services/react-query/config';
import { startAppListening } from 'services/redux/listenerMiddleware';
import { getAllFilters, getCurrentSearchFiltersId } from 'store/search/search.selectors';
import { parseFromStateToApi } from 'store/search/search.utils';
import { FilterUsage } from 'types/current-filter.types';
import { User, UserFeatureFlags } from 'types/user.types';

import { FiltersEnum, searchActions } from './search.slice';

const UPDATE_CURRENT_SEARCH_FILTERS_PATTERN = [
  searchActions.UPDATE_PERIOD,
  searchActions.UPDATE_MEDIUM_TYPE_GROUP,
  searchActions.UPDATE_LANGUAGE,
  searchActions.UPDATE_COUNTRY,
  searchActions.UPDATE_SOURCE,
  searchActions.UPDATE_SOURCES,
  searchActions.UPDATE_SOURCEGROUP,
  searchActions.UPDATE_ORDER,
  searchActions.UPDATE_SEARCH_MODE,
  searchActions.UPDATE_EDITION,
  searchActions.UPDATE_SUBSOURCE,
  searchActions.UPDATE_ADVANCED_FILTER,
  searchActions.UPDATE_IS_MANUAL,
  searchActions.CLEAR_MEDIUM_TYPE_GROUP,
  searchActions.CLEAR_LANGUAGE,
  searchActions.CLEAR_COUNTRY,
  searchActions.CLEAR_SOURCE,
  searchActions.CLEAR_SOURCEGROUP,
  searchActions.CLEAR_ADVANCED_FILTER,
  searchActions.RESTORE_SEARCH,
  searchActions.CLEAR_FILTERS,
];

export default function startSearchListeners() {
  startAppListening({
    matcher: isAnyOf(...UPDATE_CURRENT_SEARCH_FILTERS_PATTERN),
    effect: async (_, listenerApi) => {
      // Cancel all listener effects of this instance that are currently running
      listenerApi.cancelActiveListeners();

      // Ensures we deselect editions / subsources if no sources are selected anymore
      try {
        const filters = getAllFilters(listenerApi.getState());
        const sources = extractEnabledKeys(filters[FiltersEnum.Source]);
        if (sources.length === 0) {
          listenerApi.dispatch(searchActions.CLEAR_EDITION());
          listenerApi.dispatch(searchActions.CLEAR_SUBSOURCE());
        }
      } catch (e) {
        console.error(e);
      }

      // Push current filters to API
      const source = axios.CancelToken.source();

      if (!featureFlagService.isEnabled(UserFeatureFlags.PERSONALIZATION)) return;

      try {
        const user = queryClient.getQueryData(QueryKeys.user.current) as User;

        const currentSearchFiltersId = getCurrentSearchFiltersId(listenerApi.getState());
        const filters = getAllFilters(listenerApi.getState());

        const parsedFilters = parseFromStateToApi(filters);

        apiService.updateCurrentFilters(
          user.id,
          FilterUsage.Search,
          { id: currentSearchFiltersId!, currentFilters: parsedFilters },
          source.token,
        );
      } catch (e) {
        console.error('catch', e);
      } finally {
        if (listenerApi.signal.aborted) {
          source.cancel();
        }
      }
    },
  });
}
