const stringToAbbreviation = (text: string) => {
  const initials = text.match(/\b(\w)/g);
  if (initials) return initials.join('');

  return null;
};

export const getFallbackText = (text: string) => {
  if (!text) return null;

  let fallback = stringToAbbreviation(text);

  if (!fallback) return null;

  if (fallback.length > 3) {
    const allUppercase = fallback.match(/[A-Z]+/g);
    if (allUppercase && allUppercase.length > 0) {
      fallback = allUppercase.join('').substring(0, 2);
    }
  }

  return fallback.toUpperCase();
};

export function stripHTML(str?: string | null) {
  if (!str) return;
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.body.textContent || '';
}

export function stripHtmlTags(str: string | null): string | null {
  if (!str) return null;

  // This regex finds all HTML tags except for <p> and </p> and replaces them with an empty string
  const cleanedHtml = str.replace(/<(?!\/?p(?=>|\s.*>))\/?.*?>/gi, '');
  return cleanedHtml;
}

const URL_REGEX =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export const isValidUrl = (url: string) => URL_REGEX.test(url);

export const slugify = (text: string) =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
