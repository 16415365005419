import { Components, Theme } from '@mui/material';

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  defaultProps: {
    variant: 'paragraph01',
    fontFamily: '"Proxima Nova", sans-serif',
    variantMapping: {
      heading01: 'h1',
      heading02: 'h2',
      heading03: 'h3',
      heading04: 'h4',
      heading05: 'h5',
      heading06: 'h6',
      paragraph01: 'p',
      paragraph02: 'p',
      button01: 'p',
    },
  },
  styleOverrides: {
    gutterBottom: {
      marginBottom: 8,
    },
  },
};
