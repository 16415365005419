import type { AxiosResponse } from 'axios';

import { ApiResponse } from 'types/api.types';
import { CreateClippingParams } from 'types/clipping.types';
import { NewsObject } from 'types/newsobject.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function createClipping(
  this: RestApiService,
  userId: User['id'],
  params: CreateClippingParams,
) {
  return this.api.post<CreateClippingParams, AxiosResponse<NewsObject>>(
    `/users/${userId}/clippings`,
    params,
  );
}

export function fetchRelatedClippingNewsObjects(
  this: RestApiService,
  userId: User['id'],
  newsObjectUuid: NewsObject['uuid'],
) {
  return this.api.get<ApiResponse<NewsObject[]>>(
    `/users/${userId}/clippings/${newsObjectUuid}/related`,
  );
}

export function deleteClipping(
  this: RestApiService,
  userId: User['id'],
  newsObjectUuids: NewsObject['uuid'][],
) {
  return this.api.delete(`/users/${userId}/clippings`, {
    data: newsObjectUuids,
  });
}
