import { Components, Theme } from '@mui/material';

export const MuiCollapse: Components<Theme>['MuiCollapse'] = {
  styleOverrides: {
    entered: {
      height: 'auto',
      overflow: 'visible',
    },
  },
};
