import { Components, formHelperTextClasses, Theme } from '@mui/material';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.paragraph02,
      [`&.${formHelperTextClasses.error}`]: {
        color: theme.palette.accent01[400],
      },
    }),
  },
};
