export enum SnackbarTypes {
  Info = 'Info',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}

// Notifications used for Boards / Tag Settings
export enum NotificationDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum NotificationDestinationFormat {
  DESKTOP = 'DESKTOP',
  WHATSAPP = 'WHATSAPP',
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
}

export type NotificationInterval = {
  days: NotificationDay[];
  time: string;
};

export enum NotificationResource {
  TOPICS = 'topics',
  NEWSROOMS = 'newsrooms',
  BOARDS = 'boards',
  FEEDS = 'feeds',
  SAVED_SEARCHES = 'saved_searches',
}
