import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { forwardRef } from 'react';

import Icon from 'components/@icons/Icon';

import { CloseButtonContainer, NotificationContainer } from './notification.styles';

interface Props {
  message: React.ReactNode;
  notificationKey: SnackbarKey;
}

const Notification = forwardRef<HTMLDivElement, Props>(({ message, notificationKey }, ref) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <NotificationContainer ref={ref}>
      {message}
      <CloseButtonContainer>
        <IconButton onClick={() => closeSnackbar(notificationKey)}>
          <Icon color="neutral01.700" name="IcoClose" />
        </IconButton>
      </CloseButtonContainer>
    </NotificationContainer>
  );
});

export default Notification;
