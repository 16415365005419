import { AxiosInstance } from 'axios';

import config from 'config';

import { Headers } from 'services/api/axios/headers/headers';

export const createStrapiAuthInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use(
    async request => {
      try {
        if (request.headers) {
          request.headers[Headers.Authorization] = `Bearer ${config.STRAPI_API_KEY}`;
        }

        return request;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async error => {
      return Promise.reject(error);
    },
  );
};
