import { UserFeatureFlags } from 'types/user.types';

import { useFeatures } from './hooks';

interface FeatureGateProps {
  flag: UserFeatureFlags;
  disabledFallback?: React.ReactNode;
  children: React.ReactNode;
  isInverted?: boolean;
}

export const FeatureGate = ({
  flag,
  disabledFallback,
  children,
  isInverted = false,
}: FeatureGateProps) => {
  const features = useFeatures();
  if (!!isInverted) {
    if (features.isDisabled(flag)) return <>{children}</>;
  } else {
    if (features.isEnabled(flag)) return <>{children}</>;
  }
  if (disabledFallback) return <>{disabledFallback}</>;
  return null;
};
