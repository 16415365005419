import { styled } from '@mui/material';
import BottomNavigationAction, {
  BottomNavigationActionProps,
} from '@mui/material/BottomNavigationAction';
import { LinkProps } from 'react-router-dom';

import Icon from 'components/@icons';

interface ItemProps extends BottomNavigationActionProps {
  isActive?: boolean;
  component?: React.ElementType<LinkProps>;
  to?: string;
}

export const Item = styled(BottomNavigationAction, {
  shouldForwardProp: prop => prop !== 'isActive',
})<ItemProps>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.global01[400] : theme.palette.neutral01[300],
  backgroundColor: isActive ? theme.palette.global01[700] : theme.palette.neutral01[700],
  minWidth: 75,
  padding: 0,

  '&:hover': {
    color: theme.palette.global01[400],
    backgroundColor: theme.palette.global01[700],
    svg: {
      fill: theme.palette.global01[400],
    },
  },
  '@media (max-width: 320px)': {
    minWidth: 58,
  },
}));

export const IconStyled = styled(Icon)({
  marginBottom: 2,
});
