import { styled, useTheme } from '@mui/material';
import { get } from 'lodash-es';
import React from 'react';
import { SVGProps } from 'react';

import { ColorNames } from 'theme/colors';

import DefaultIconLookup from './defaults';

/**
 * type for all the possible icon names
 */
export type IconNames = keyof typeof DefaultIconLookup;

/**
 * array with all the possible icon names
 */
export const IconNamesArray = Object.keys(DefaultIconLookup) as IconNames[];

export interface Props extends Omit<SVGProps<SVGSVGElement>, 'ref' | 'rotate'> {
  name: IconNames;
  size?: number;
  rotation?: number;
  color?: ColorNames;
}

/**
 * Simple icon lookup to return an icon based on the unique name, icons will be code splitted by loadable
 * @param name unique name of the icon you want to render
 * @param size optional size of the icon
 * @param rotation optional rotation of the icon
 * @param color optional color of the icon
 * @param SVGProps optional styling by passing SVGProps
 * @returns Icon based on the unique name
 */
const Icon = ({ name, size = 24, rotation, color, ...rest }: Props) => {
  const theme = useTheme();
  const LookUpIcon = DefaultIconLookup[name];
  const fill = color && (get(theme.palette, color) as string);

  return (
    <React.Suspense fallback={null}>
      {LookUpIcon ? (
        <LookUpIcon
          width={size}
          height={size}
          {...rest}
          style={{
            transition: 'transform 0.3s ease',
            transform: rotation ? `rotate(${rotation}deg)` : undefined,
            flexShrink: 0,
            fill,
            ...rest.style,
          }}
        />
      ) : null}
    </React.Suspense>
  );
};

export default styled(Icon)({});
