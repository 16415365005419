import { AxiosResponse } from 'axios';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import qs from 'qs';

import { runInDev } from 'utils/env.utils';

import { apiService, history } from 'services';
import { getStoreState, store } from 'store';
import { getAccessToken, getExpiresIn, getRefreshToken } from 'store/auth/auth.selector';
import { authActions } from 'store/auth/auth.slice';
import { Store } from 'store/reducers';
import { AuthResponse } from 'types/auth.types';

const isTokenExpired = (expiresIn: number) => Date.now() + 60_000 >= expiresIn * 1000;

const responseToState = (response: AxiosResponse<AuthResponse>) => {
  const { exp } = jwtDecode<JwtPayload>(response.data.accessToken);
  return { ...response.data, expiresIn: exp! };
};

/**
 * Checks if the access token is present and still valid
 * @param store {Store}
 * @returns boolean
 */
export const isAccessTokenValid = (store: Store = getStoreState()): boolean => {
  const accessToken = getAccessToken(store);
  const expiresIn = getExpiresIn(store);

  // If access token doesn't exist
  if (!accessToken) {
    runInDev(() => console.warn('---------TOKEN = UNDEFINED--------'));
    return false;
  }

  // If access token exist. Check if it's still valid.
  if (expiresIn && isTokenExpired(expiresIn)) {
    runInDev(() => console.warn('---------TOKEN = EXPIRED--------'));
    return false;
  }

  return true;
};

/**
 * Uses the refresh token to get a new access token
 * @param store {Store}
 * @returns {Promise<AuthResponse>}
 */
export const refreshAccessToken = async (store: Store = getStoreState()): Promise<AuthResponse> => {
  const refreshToken = getRefreshToken(store);

  if (!refreshToken) {
    throw new Error('No refresh token present.');
  }

  const response = await apiService.refreshAuthToken(refreshToken);
  return responseToState(response);
};

/**
 * Calls internally refreshAccesstoken and also stores it
 * @param storeState {Store}
 * * @returns {Promise<AuthResponse['accessToken']>}
 */
export const refreshAndStoreAccesstoken = async (
  storeState: Store = getStoreState(),
): Promise<AuthResponse['accessToken']> => {
  const auth = await refreshAccessToken(storeState);

  store.dispatch(authActions.SET_AUTH_RESPONSE(auth));

  return auth.accessToken;
};

/**
 * Use the code from a success callback to exchange it for access / refresh tokens
 * @returns {AuthResponse}
 */
export const exchangeCodeForTokens = async () => {
  const location = history.location;
  if (location.pathname !== '/success/') return;

  const body = qs.parse(location.hash);
  const code = (body.code as string) ?? '';
  if (code) {
    const response = await apiService.postExchangeCodeForTokens(code);
    return responseToState(response);
  } else {
    throw Error('No code in success callback');
  }
};
