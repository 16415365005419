import type { AxiosResponse } from 'axios';

import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { Label, LabelParams, LabelSearchParams, OrderLabelsParams } from 'types/label.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchUserLabels(
  this: RestApiService,
  userId: User['id'],
  dirtyParams: LabelSearchParams,
) {
  const params = removeEmptyValuesFromParams(dirtyParams);
  return this.api.get<ApiResponse<Label[]>>(`/users/${userId}/labels`, {
    params,
  });
}

export function createLabel(this: RestApiService, params: LabelParams) {
  return this.api.post<LabelParams, AxiosResponse<Label>>(`/labels`, params);
}

export function updateLabel(this: RestApiService, labelId: Label['id'], params: LabelParams) {
  return this.api.put<LabelParams, AxiosResponse<Label>>(`/labels/${labelId}`, params);
}

export function deleteLabel(this: RestApiService, labelId: Label['id'], deleteChildren?: boolean) {
  return this.api.delete<undefined>(`/labels/${labelId}`, { params: { deleteChildren } });
}

export function clearLabel(this: RestApiService, labelId: Label['id']) {
  return this.api.put<Label>(`/labels/${labelId}/clear`);
}

export function orderUserLabels(
  this: RestApiService,
  userId: User['id'],
  labelOrders: OrderLabelsParams,
) {
  return this.api.put<OrderLabelsParams, AxiosResponse<ApiResponse<Label[]>>>(
    `/users/${userId}/labels/order`,
    labelOrders,
  );
}

export function detachLabel(this: RestApiService, labelId: Label['id'], userId: User['id']) {
  return this.api.delete<null>(`/labels/${labelId}/collaborators/${userId}`);
}
