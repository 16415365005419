import { Newsroom, NewsroomLanguages } from './newsroom.types';
import { Organization } from './organization.types';
import { Topic } from './topic.types';

export enum UserTypeEnum {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  CURATOR = 'CURATOR',
  PRO_CURATOR = 'PRO_CURATOR',
  VISITOR = 'VISITOR',
  MANUAL = 'MANUAL',
  API = 'API',
}

export enum UserRoleEnum {
  ADMIN = 'ADMIN',
  USER = 'USER',
  SUPER_USER = 'SUPER_USER',
  MANUAL = 'MANUAL',
  API = 'API',
}

export enum OnboardingEnvironmentEnum {
  APP = 'APP',
  WEB = 'WEB',
}

export enum TopicsOnboardingEnum {
  DONE = 'DONE',
  SKIPPED = 'SKIPPED',
}

export enum UserLanguages {
  NL = 'NL',
  FR = 'FR',
  DE = 'DE',
  EN = 'EN',
}

export enum UserFunctionEnum {
  JOURNALIST = 'JOURNALIST',
  PR = 'PR',
  EXPERT = 'EXPERT',
  CONTENT_CREATOR = 'CONTENT_CREATOR',
  OTHER = 'OTHER',
}

export type User = {
  id: number;
  login: string;
  firstName: string;
  lastName: string;
  language: UserLanguages;
  journalistNewsroom: { id: Newsroom['id']; language: NewsroomLanguages } | null;
  newsroomId?: Newsroom['id'];
  gender: string;
  email: string;
  phone: string | null;
  active: boolean;
  doNotDisturb: boolean;
  function: UserFunctionEnum;
  from: string;
  to: string;
  organization: Organization;
  muted: string[];
  topicsOnboarding: TopicsOnboardingEnum;
  onboardedEnvironments: OnboardingEnvironmentEnum[];
  userType: UserTypeEnum;
  userRole: UserRoleEnum;
  favoriteSources?: number[];
  followedNewsrooms?: Newsroom['id'][];
  followedTopics?: Topic['id'][];
};

export type OrganizationUser = {
  userId: User['id'];
  email: string;
  firstName: string;
  lastName: string;
  organizationName: string;
};

export type SharedUser = Pick<User, 'firstName' | 'lastName' | 'email'> & {
  userId: User['id'];
  organizationName: string;
};

type settingSection = {
  name: string;
  newsQuerySuffix: string;
};

export enum UserFeatureFlags {
  PRINT = 'PRINT',
  PRINT_WITH_EMBARGO = 'PRINT_WITH_EMBARGO',
  PUBLICATION_PRINT = 'PUBLICATION_PRINT',
  ACCESS_PDF = 'ACCESS_PDF',
  LABELS = 'LABELS',
  BOARDS = 'BOARDS',
  BOARDS_AUTO_LABELING = 'BOARDS_AUTO_LABELING',
  FEEDS = 'FEEDS',
  VIEW_KEYWORDS = 'VIEW_KEYWORDS',
  EDIT_KEYWORDS = 'EDIT_KEYWORDS',
  NEWSLETTERS = 'NEWSLETTERS',
  PUBLIC_NEWSLETTER = 'PUBLIC_NEWSLETTER',
  QUICK_INTEGRATION_API = 'QUICK_INTEGRATION_API',
  CLIPPING = 'CLIPPING',
  FAST_LABEL_NEWS = 'FAST_LABEL_NEWS',
  ANALYSIS_PARAMETERS = 'ANALYSIS_PARAMETERS',
  MY_DOCUMENTS = 'MY_DOCUMENTS',
  NEWSROOMS = 'NEWSROOMS',
  JOURNALIST_NEWSROOM = 'JOURNALIST_NEWSROOM',

  ANALYSIS = 'ANALYSIS',
  ANALYSIS_PREVIEW = 'ANALYSIS_PREVIEW',

  // AI
  AI_SUMMARY_NEWSLETTER = 'AI_SUMMARY_NEWSLETTER',

  // Search related
  SEARCH = 'SEARCH',
  SAVED_SEARCH = 'SAVED_SEARCH',

  // User dropdown flags
  USER_SETTINGS = 'USER_SETTINGS',
  USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD',
  USER_FEEDBACK = 'USER_FEEDBACK',
  USER_LOGOUT = 'USER_LOGOUT',
  USER_HELP = 'USER_HELP',

  // Settings
  EDIT_CONTACT_MANAGEMENT = 'EDIT_CONTACT_MANAGEMENT',
  EDIT_BRANDS = 'EDIT_BRANDS',
  EDIT_TEMPLATES = 'EDIT_TEMPLATES',
  EDIT_USER_MANAGEMENT = 'EDIT_USER_MANAGEMENT',
  NEWSLETTER_STATISTICS = 'NEWSLETTER_STATISTICS',

  // Organization resources
  EDIT_ORGANIZATION_BOARDS = 'EDIT_ORGANIZATION_BOARDS',
  VIEW_ORGANIZATION_BOARDS = 'VIEW_ORGANIZATION_BOARDS',
  VIEW_ORGANIZATION_TAGS = 'VIEW_ORGANIZATION_TAGS',
  EDIT_ORGANIZATION_TAGS = 'EDIT_ORGANIZATION_TAGS',
  EDIT_ORGANIZATION_FEEDS = 'EDIT_ORGANIZATION_FEEDS',
  VIEW_ORGANIZATION_FEEDS = 'VIEW_ORGANIZATION_FEEDS',

  // TOPICS
  TOPICS = 'TOPICS',

  // kiosk sidebar navigation flags
  KIOSK = 'KIOSK',
  KIOSK_NEWSLETTERS = 'KIOSK_NEWSLETTERS',
  KIOSK_REPORTS = 'KIOSK_REPORTS',
  KIOSK_PUBLICATIONS = 'KIOSK_PUBLICATIONS',
  KIOSK_REALTIME = 'KIOSK_REALTIME',
  KIOSK_NEWSBRANDS = 'KIOSK_NEWSBRANDS',
  KIOSK_FEEDS = 'KIOSK_FEEDS',
  KIOSK_RECOMMENDED = 'KIOSK_RECOMMENDED',
  KIOSK_MULTIMEDIA = 'KIOSK_MULTIMEDIA',
  KIOSK_FRONTPAGE = 'KIOSK_FRONTPAGE',
  KIOSK_GOPRESS_ARCHIVE = 'KIOSK_GOPRESS_ARCHIVE',
  KIOSK_GOPRESS_ARCHIVE_EDIT = 'KIOSK_GOPRESS_ARCHIVE_EDIT',
  KIOSK_NEWSROOMS = 'KIOSK_NEWSROOMS',
  KIOSK_AGENDA = 'BELGA_AGENDA',
  KIOSK_AGENDA_PREVIEW = 'BELGA_AGENDA_PREVIEW',

  PERSONALIZATION = 'PERSONALIZATION',
  SHARE_NEWS = 'SHARE_NEWS',
  SAVED_NEWS = 'SAVED_NEWS',

  // newsobject detail
  RELATED_NEWS = 'RELATED_NEWS',
  DUPLICATE_NEWS = 'DUPLICATE_NEWS',
  ATTACHMENT_NEWS = 'ATTACHMENT_NEWS',
  MENTIONED_NEWS = 'MENTIONED_NEWS',
  PAY_PER_ARTICLE = 'PAY_PER_ARTICLE',

  // Activity reports
  ACTIVITY_REPORT = 'ACTIVITY_REPORT',

  // Belga Image
  BELGA_IMAGE = 'BELGA_IMAGE',

  // Upsell
  UPSELL_BELGA_PRESS_TO_BELGA_SHARE = 'UPSELL_BELGA_PRESS_TO_BELGA_SHARE',
}

export type UserSettings = {
  kioskSettings: {
    languages: string[];
    countries: string[];
    dates: {
      fromDate: string;
      toDate: string;
    };
    sections: settingSection[];
    featureFlags: UserFeatureFlags[];
  };
  contentSettings: {
    embargoDuration: string | null;
    perPage: number;
  };
};

export type UserSearchparams = {
  count?: number;
  offset?: number;
  order?: 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL' | 'USER_TYPE';
  searchtext?: string;
};

export type UserSettingsParams = {
  contentSettings: {
    perPage: number;
  };
};

export type UserCreateParams = {
  email: User['email'];
  firstName: User['firstName'];
  lastName: User['lastName'];
  userRole: User['userRole'];
  userType: User['userType'];
};

export type UserUpdateParams = {
  firstName?: User['firstName'];
  lastName?: User['lastName'];
  language?: User['language'];
  email?: User['email'];
  topicsOnboarding?: User['topicsOnboarding'];
  function?: User['function'];
};

export type ExportUsersParams = {
  userIds?: number[];
};
