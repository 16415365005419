import { Components, Theme } from '@mui/material';

export const MuiToggleButton: Components<Theme>['MuiToggleButton'] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderColor: theme.palette.neutral01[600],
      color: theme.palette.global01[500],
      '&& svg': {
        fill: theme.palette.global01[500],
      },
      '&:hover': {
        color: theme.palette.global01[300],
        backgroundColor: theme.palette.global01[600],

        '&& svg': {
          fill: theme.palette.global01[300],
        },
      },
      '&.Mui-disabled': {
        color: theme.palette.neutral01[400],
        backgroundColor: 'transparent',
        '&& svg': {
          fill: theme.palette.neutral01[400],
        },
        '&:hover': {
          '&& svg': {
            fill: theme.palette.neutral01[400],
          },
        },
      },
      '&.Mui-selected': {
        color: theme.palette.global01[400],
        backgroundColor: theme.palette.global01[700],
        '&& svg': {
          fill: theme.palette.global01[400],
        },
        '&:hover': {
          backgroundColor: theme.palette.global01[600],
        },
      },
    }),
  },
};
