import type { AxiosResponse } from 'axios';

import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { NewTagParams, Params, Tag } from 'types/tag.types';
import { User } from 'types/user.types';

import RestApiService from '.';

const emptyTag = {
  id: null,
  name: null,
  query: '',
  type: null,
  image: '',
  parentId: null,
  public: false,
  queryObject: {
    searchText: '',
  },
  order: 0,
};

// Tags
export default {
  fetchTags(this: RestApiService, params?: Params) {
    const cleanParams = removeEmptyValuesFromParams(params);

    return this.api.get<ApiResponse<Tag[]>>(`/tags`, { params: cleanParams });
  },

  // User tags

  fetchUserTag(this: RestApiService, userId: User['id'], tagId: Tag['id']) {
    return this.api.get<Tag>(`/users/${userId}/tags/${tagId}`);
  },

  fetchUserTags(this: RestApiService, userId: User['id'], params?: Params) {
    const cleanParams = removeEmptyValuesFromParams(params);

    return this.api.get<ApiResponse<Tag[]>>(`/users/${userId}/tags`, {
      params: cleanParams,
    });
  },

  createUserTag(this: RestApiService, userId: User['id'], tag: NewTagParams) {
    const body = {
      ...emptyTag,
      ...tag,
    };

    return this.api.post<typeof body, AxiosResponse<Tag>>(`/users/${userId}/tags`, body);
  },

  // Related tags

  fetchRelatedTags(this: RestApiService, userId: User['id'], tagId: Tag['id']) {
    return this.api.get<Tag[]>(`/users/${userId}/tags/${tagId}/related`);
  },

  // Popular tags

  fetchPopularTags(this: RestApiService, userId: User['id'], params?: Params) {
    const cleanParams = removeEmptyValuesFromParams(params);

    return this.api.get<ApiResponse<Tag[]>>(`/users/${userId}/populartags`, {
      params: cleanParams,
    });
  },

  // Tag subscription

  postSubscriptionToTag(this: RestApiService, userId: User['id'], tagId: Tag['id']) {
    return this.api.post(`/users/${userId}/tags/${tagId}/subscription`);
  },

  deleteSubscriptionToTag(this: RestApiService, userId: User['id'], tagId: Tag['id']) {
    return this.api.delete(`/users/${userId}/tags/${tagId}/subscription`);
  },

  // Newsletter tags

  fetchNewsletterTags(this: RestApiService, userId: User['id']) {
    return this.api.get<ApiResponse<Tag[]>>(`/users/${userId}/pressreviewtags`);
  },
};
