import { accordionClasses, Components, Theme } from '@mui/material';

export const MuiAccordion: Components<Theme>['MuiAccordion'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: 'none',
      boxShadow: 'none',
      background: 'none',
      borderBottom: `1px solid ${theme.palette.neutral01[600]}`,

      [`&.${accordionClasses.expanded}`]: {
        marginTop: '0 !important',
        marginBottom: '0 !important',
      },

      '&:before': {
        display: 'none',
      },
    }),
  },
};
