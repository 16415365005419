import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrap = styled(Box)(({ theme }) => ({
  userSelect: 'none',
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  padding: theme.spacing(2, 1.5),
  background: theme.palette.global01[700],
  color: theme.palette.global01[400],
  maxWidth: '200px',
  gap: theme.spacing(1),
  cursor: 'pointer',
  transition: 'background 0.1s ease-in-out',

  '&:hover': {
    background: theme.palette.global01[600],
  },
}));

export const Content = styled('span')(({ theme }) => ({
  display: 'none',
  fontSize: '13px',
  textAlign: 'right',
  colr: theme.palette.global01[500],

  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
}));
