import { AxiosResponse } from 'axios';

import RestApiService from 'services/api';
import { ALLOWED_FILE_TYPES } from 'services/api/documents.api';
import { getFormData } from 'services/api/utils/getFormData';
import { FileType } from 'types/documents.types';
import { Media, MediaFile, MediaFileType, MediaType, UploadMediaParams } from 'types/media.types';

export function uploadMedia(
  this: RestApiService,
  { file, type, onUploadProgress }: UploadMediaParams,
) {
  let fileType = ALLOWED_FILE_TYPES.find(({ mime }) => mime === file.type)?.filetype || 'UNKNOWN';
  // the API expects IMAGE_JPG in the documents api and JPG here, so we have to convert it
  if (fileType === FileType.JPG) fileType = MediaFileType.JPG;

  return this.api.post<FormData, AxiosResponse<MediaFile | Media>>(
    type === MediaType.File ? 'uploads/files' : 'uploads/media',
    getFormData({ data: file, fileType, fileName: file.name }),
    { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress },
  );
}
