import { ApiResponse } from 'types/api.types';
import {
  Deliverable,
  DeliverableParams,
  Occurrence,
  OccurrenceParams,
  Publication,
  PublicationParams,
} from 'types/publication.types';
import { Source } from 'types/source.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchDeliverables(
  this: RestApiService,
  userId: User['id'],
  params: Partial<DeliverableParams>,
) {
  return this.api.get<ApiResponse<Deliverable[]>>(`/users/${userId}/kiosk/deliverables`, {
    params,
  });
}

export function fetchPublications(
  this: RestApiService,
  userId: User['id'],
  params: PublicationParams,
) {
  return this.api.get<ApiResponse<Publication[]>>(`/users/${userId}/kiosk/publications`, {
    params,
  });
}

export function fetchFavouriteDeliverables(this: RestApiService, userId: User['id']) {
  return this.api.get<ApiResponse<Deliverable[]>>(`/users/${userId}/kiosk/deliverables/favourites`);
}

export function fetchPublicationById(
  this: RestApiService,
  userId: User['id'],
  deliverableId: number,
  publicationUuid: string,
) {
  return this.api.get<ApiResponse<Deliverable>>(
    `/users/${userId}/kiosk/deliverables/${deliverableId}/publications/${publicationUuid}`,
  );
}

export function fetchOccurrences(
  this: RestApiService,
  userId: User['id'],
  sourceId: Source['id'],
  params?: OccurrenceParams,
) {
  return this.api.get<ApiResponse<Occurrence[]>>(
    `/users/${userId}/kiosk/sources/${sourceId}/occurrences`,
    { params },
  );
}

export function fetchDeliverableEditions(
  this: RestApiService,
  userId: User['id'],
  deliverableId: Deliverable['id'],
) {
  return this.api.get<ApiResponse<string[]>>(
    `/users/${userId}/kiosk/deliverables/${deliverableId}/editions`,
  );
}
