import qs from 'query-string';

import { SearchModes, UrlParams } from 'types/search.types';

export const extractEnabledKeys = (data: { [key: string]: boolean }) =>
  Object.keys(data).filter(key => data[key]);

export const extractSubsourceKeys = (
  data: {
    [key: string]: { active: boolean; sourceId: string; label: string };
  },
  property: 'id' | 'sourceId' | 'label',
) =>
  Object.entries(data)
    .reduce((memo, [key, { active, sourceId, label }]) => {
      if (active) {
        property === 'id'
          ? memo.push(key)
          : property === 'sourceId'
            ? memo.push(sourceId)
            : memo.push(label);
      }
      return memo;
    }, [] as string[])
    .filter(Boolean);

export const createSearchUrl = (params: UrlParams) => {
  const { isManual, hasSearched, searchText, searchMode, page, fromPortfolio } = params;

  const searchParams = qs.stringify(
    {
      is_manual: isManual,
      search_mode: searchMode,
      has_searched: hasSearched,
      searchtext: searchText,
      page,
      fromPortfolio,
    },
    { skipNull: true },
  );

  return `/search?${searchParams}`;
};

export const parseSearchText = (searchtext?: string) =>
  searchtext ? searchtext.replace(/[‘’]+/g, "'").replace(/[“”]+/g, '"') : '';

export const parseToBooleanQuery = (searchtext?: string, mode?: SearchModes) => {
  if (!searchtext) return;

  const escaped = searchtext
    // eslint-disable-next-line no-useless-escape
    .replace(/["\+-:~^*?(){}\[\];]/g, '\\$&')
    .replace('&&', '\\&&')
    .replace('||', '\\||');

  const splitWords = escaped.split(' ');
  const withMarks = splitWords.map(word => `"${word}"`);

  if (mode === SearchModes.ONE_OF) {
    return withMarks.join(' OR ');
  }

  if (mode === SearchModes.ALL_OF) {
    return withMarks.join(' AND ');
  }

  return `"${searchtext}"`;
};

/**
 * Returns a sorting function that gives priority to selected values and then sorts on additional properties
 * @param selected Array of selected values
 * @param prop Matching property to check for in selected array
 * @param fallback Optional fallback property or sorting function
 */
export const sortSelectedName =
  <T extends object>(
    selected: string[],
    prop: keyof T,
    fallback?: keyof T | ((a: T, b: T) => number),
  ) =>
  (a: T, b: T) => {
    if (selected.includes(`${a[prop]}`) && !selected.includes(`${b[prop]}`)) return -1;
    if (selected.includes(`${b[prop]}`) && !selected.includes(`${a[prop]}`)) return 1;

    if (typeof fallback === 'function') {
      return fallback(a, b);
    } else if (fallback) {
      return a[fallback] > b[fallback] ? 1 : -1;
    } else {
      return 0;
    }
  };

export const arrayToFilterState = (array?: string[]) => {
  if (!array || array.length === 0) return {};
  return array.reduce<{ [key: string]: boolean }>((memo, key) => {
    memo[key] = true;
    return memo;
  }, {});
};
