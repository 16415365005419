import {
  createAuthCancelDuplicates,
  createAuthRefreshRequestInterceptor,
  createHeaderRequestInterceptor,
} from './request';
import { createResponseErrorInterceptor, createSessionHijackInterceptor } from './response';

export default [
  // Request interceptors
  createAuthCancelDuplicates,
  createAuthRefreshRequestInterceptor,
  createHeaderRequestInterceptor,
  // Response interceptors
  createSessionHijackInterceptor,
  // createAuthRefreshInterceptor,
  createResponseErrorInterceptor,
];
