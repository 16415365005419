import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DateRange,
  getLast24Hours,
  SerializableDateRange,
  serializeDateRange,
} from 'utils/date.utils';

type BoardFiltersState = {
  date: SerializableDateRange;
};

const INITIAL_STATE: BoardFiltersState = {
  date: serializeDateRange(getLast24Hours()),
};

const boardFiltersSlice = createSlice({
  name: 'boardFilters',
  initialState: INITIAL_STATE,
  reducers: {
    UPDATE_DATE: (state, action: PayloadAction<DateRange>) => {
      state.date = serializeDateRange(action.payload);
    },
  },
});

export const { actions: boardActions } = boardFiltersSlice;

export default boardFiltersSlice.reducer;
