import { Components, tabClasses, Theme } from '@mui/material';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderBottom: `1px solid ${theme.palette.neutral01[600]}`,
    }),
    indicator: ({ theme }) => ({
      backgroundColor: theme.palette.global01[400],
    }),
  },
};

export const MuiTab: Components<Theme>['MuiTab'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.paragraph01,
      minWidth: 76,
      '@media (min-width: 0px)': {
        minWidth: 76,
      },
      fontSize: 14,
      textTransform: 'none',
      '&.Mui-selected': {
        fontWeight: 'bold',
        color: theme.palette.global01[400],
      },

      [`&.${tabClasses.disabled}`]: {
        cursor: 'not-allowed',
        pointerEvents: 'auto',
      },
    }),
  },
};
