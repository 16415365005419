export const PRELOAD_ERROR = 'preload_error';

export enum PreLoadErrorType {
  CurrentSearchFiltersPreloadFail = 'current_search_filters_preload_fail',
  CurrentKioskFiltersPreloadFail = 'current_kiosk_filters_preload_fail',
}

export const CURRENT_FILTERS = 'current_filters';

export enum CurrentFiltersErrorType {
  CurrentSearchFiltersParsingFromApiFail = 'current_search_filters_parsing_from_api_fail',
  CurrentSearchFiltersParsingToApiFail = 'current_search_filters_parsing_to_api_fail',

  CurrentKioskFiltersParsingFromApiFail = 'current_kiosk_filters_parsing_from_api_fail',
  CurrentKioskFiltersParsingToApiFail = 'current_kiosk_filters_parsing_to_api_fail',
}

// errors with file imports, these usually happen right after a release when people still have the old html/js. We try to catch these by forcing a reload in useRefreshOnDeployError
export const IMPORT_ERRORS = [
  'error loading dynamically imported module',
  'Importing a module script failed.',
  'Load failed',
  'NetworkError when attempting to fetch resource.',
  'Failed to fetch dynamically imported module',
  'Loading chunk',
  'Unable to preload CSS',
];

export const BLOCKED_ERRORS = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  'Network Error',
  ...IMPORT_ERRORS,
];

export const ALLOWED_URLS = [
  /https:\/\/(web\.belga\.press)/,
  /https:\/\/((accept|staging)\.bena-0002-web-platform-frontend-web\.novemberfiveops\.co)/,
];
