import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type NewsobjectSearchState = {
  searchvalue: string;
};

const INITIAL_STATE: NewsobjectSearchState = {
  searchvalue: '',
};

const newsobjectSearchSlice = createSlice({
  name: 'newsobjectSearch',
  initialState: INITIAL_STATE,
  reducers: {
    UPDATE_SEARCH_VALUE: (state, action: PayloadAction<string>) => {
      state.searchvalue = action.payload;
    },
  },
});

export const { actions: newsobjectSearchActions } = newsobjectSearchSlice;

export default newsobjectSearchSlice.reducer;
