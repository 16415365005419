import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import { MaintenanceMessage } from 'types/maintenance-message.types';

type MaintenanceMessageState = {
  dismissedMessages: number[];
};

const INITIAL_STATE: MaintenanceMessageState = {
  dismissedMessages: [],
};

export const maintenanceMessagesPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.maintenanceMessages`,
  storage,
  whitelist: ['dismissedMessages'],
};

const maintenanceMessagesSlice = createSlice({
  name: 'maintenance-messages',
  initialState: INITIAL_STATE,
  reducers: {
    DISMISS_MESSAGE: (state, action: PayloadAction<MaintenanceMessage['id']>) => {
      state.dismissedMessages.push(action.payload);
    },
  },
});

export const { actions: maintenanceMessagesActions } = maintenanceMessagesSlice;

export default maintenanceMessagesSlice.reducer;
