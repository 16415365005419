import { AxiosResponse, CancelToken } from 'axios';

import { getFormData } from 'services/api/utils/getFormData';
import {
  belgaContextHeaders,
  removeEmptyValuesFromParams,
} from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { Newsletter } from 'types/newsletter.types';
import {
  NewsletterImageParams,
  NewsletterItem,
  NewsletterItemOrderParams,
  NewsletterItemParams,
  NewsObjectSearchParams,
  PrefillStatusResponse,
  UpdateNewsObjectParams,
  UpdateNewsObjectsParams,
} from 'types/newsletter-item.types';
import {
  LabelSummaryContext,
  NewsObject,
  NewsObjectCompact,
  NewsObjectContext,
} from 'types/newsobject.types';

import RestApiService from '.';

export default {
  createNewsletterItem(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    params: NewsletterItemParams,
  ) {
    return this.api.post<NewsletterItemParams, AxiosResponse<NewsletterItem>>(
      `/newsletters/${newsletterId}/items`,
      params,
    );
  },

  updateNewsletterItem(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    newsletterItemId: NewsletterItem['id'],
    params: NewsletterItemParams,
  ) {
    return this.api.put<NewsletterItemParams, AxiosResponse<NewsletterItem>>(
      `/newsletters/${newsletterId}/items/${newsletterItemId}`,
      params,
    );
  },

  deleteNewsletterItem(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    newsletterItemId: NewsletterItem['id'],
  ) {
    return this.api.delete<null>(`/newsletters/${newsletterId}/items/${newsletterItemId}`);
  },

  fetchNewsletterItemNewsObjects(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    newsletterItemId: NewsletterItem['id'],
    dirtyParams?: NewsObjectSearchParams,
    cancelToken?: CancelToken,
  ) {
    const params = removeEmptyValuesFromParams<NewsObjectSearchParams>(dirtyParams);
    return this.api.get<ApiResponse<NewsObjectCompact[]>>(
      `/newsletters/${newsletterId}/items/${newsletterItemId}/newsobjects`,
      { params, cancelToken },
    );
  },

  updateNewsletterItemNewsObjects(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    newsletterItemId: NewsletterItem['id'],
    params: UpdateNewsObjectsParams,
  ) {
    return this.api.put<UpdateNewsObjectsParams, AxiosResponse<ApiResponse<NewsObjectCompact[]>>>(
      `/newsletters/${newsletterId}/items/${newsletterItemId}/newsobjects`,
      params,
      { timeout: 20_000 },
    );
  },

  updateNewsletterItemNewsObject(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    newsletterItemId: NewsletterItem['id'],
    newsObjectUuid: NewsObjectCompact['uuid'],
    params: UpdateNewsObjectParams,
  ) {
    return this.api.patch<UpdateNewsObjectsParams, never>(
      `/newsletters/${newsletterId}/items/${newsletterItemId}/newsobjects/${newsObjectUuid}`,
      params,
    );
  },

  fetchNewsletterItemNewsObject(
    this: RestApiService,
    newsObjectUuid: NewsObject['uuid'],
    { newsletterId, newsletterItemId }: LabelSummaryContext,
    context: NewsObjectContext,
  ) {
    return this.api.get<NewsObject>(
      `/newsletters/${newsletterId}/items/${newsletterItemId}/newsobjects/${newsObjectUuid}`,
      { headers: belgaContextHeaders(context) },
    );
  },

  deleteNewsletterItemNewsObjects(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    newsletterItemId: NewsletterItem['id'],
    uuids: NewsObjectCompact['uuid'][],
  ) {
    return this.api.delete<NewsObjectCompact['uuid'][], null>(
      `/newsletters/${newsletterId}/items/${newsletterItemId}/newsobjects`,
      {
        data: uuids,
      },
    );
  },

  orderNewsletterItems(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    itemOrders: NewsletterItemOrderParams[],
  ) {
    return this.api.put<NewsletterItemOrderParams[], AxiosResponse<Newsletter>>(
      `/newsletters/${newsletterId}/items/order`,
      itemOrders,
    );
  },

  getPrefillNewsletterItemStatus(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    newsletterItemId: NewsletterItem['id'],
  ) {
    return this.api.get<PrefillStatusResponse>(
      `/newsletters/${newsletterId}/items/${newsletterItemId}/prefill`,
    );
  },

  prefillNewsletterItem(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    newsletterItemId: NewsletterItem['id'],
  ) {
    return this.api.put(`/newsletters/${newsletterId}/items/${newsletterItemId}/prefill`);
  },

  restoreNewsletterItemNewsObjectTitle(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    newsletterItemId: NewsletterItem['id'],
    newsObjectUuid: NewsObjectCompact['uuid'],
  ) {
    return this.api.patch<undefined, NewsObjectCompact>(
      `/newsletters/${newsletterId}/items/${newsletterItemId}/newsobjects/${newsObjectUuid}/restore`,
    );
  },

  uploadNewsletterImage(
    this: RestApiService,
    newsletterId: Newsletter['id'],
    newsletterItemId: NewsletterItem['id'],
    params: NewsletterImageParams,
  ) {
    return this.api.post(
      `/newsletters/${newsletterId}/items/${newsletterItemId}/uploads`,
      getFormData({ file: params.file }),
    );
  },
};
