import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import { devMiddlewares } from 'services/redux/devMiddlewares';
import { listenerMiddleware } from 'services/redux/listenerMiddleware';
import { analysisActions } from 'store/analysis/analysis.slice';
import { boardActions } from 'store/board-filters/board-filters.slice';

import combinedReducers, { Store } from './reducers';
import { searchActions } from './search/search.slice';

const IGNORED_ACTIONS = [
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  searchActions.UPDATE_PERIOD.type,
  searchActions.CLEAR_PERIOD.type,
  searchActions.CLEAR_FILTERS.type,
  boardActions.UPDATE_DATE.type,
  analysisActions.UPDATE_DATERANGE.type,
  analysisActions.UPDATE_DATASET.type,
];

const persistConfig = {
  key: config.LOCAL_STORAGE_ID,
  storage,
  version: 1,
  debug: true,
  whitelist: [],
};

const rootReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        // redux persist does not pass the serializable check defined
        // Why should type be a string, or at least serializable:  https://redux.js.org/faq/actions/#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants
        // issue: https://github.com/rt2zz/redux-persist/issues/988
        ignoredActions: IGNORED_ACTIONS,
      },
    })
      .prepend(listenerMiddleware.middleware)
      .concat(...devMiddlewares);
  },
  devTools: config.ENABLE_DEV_TOOLS,
});

export const persistor = persistStore(store);

/**
 * Returns the typed store from redux
 * Used for accessing the store outside of react hooks land
 * @returns Store
 */
export const getStoreState = (): Store => store.getState() as unknown as Store;
export type AppDispatch = typeof store.dispatch;
