import { AxiosInstance } from 'axios';

import { MarketingPageContent } from 'types/strapi.types';

interface Options {
  api: AxiosInstance;
}

const MARKETING_PAGE_ID = 'single-page-internal-website';

class StrapiRestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  public fetchBelgaSharePage(locale: string) {
    return this.api.get<MarketingPageContent>(`/${MARKETING_PAGE_ID}`, {
      params: {
        populate: 'deep',
        locale,
      },
    });
  }
}

export default StrapiRestApiService;
