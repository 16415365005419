import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import { AuthResponse } from 'types/auth.types';

type AuthState = {
  auth?: AuthResponse;
};

export const authPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.auth`,
  storage,
  whitelist: ['auth'],
};

const INITIAL_STATE: AuthState = {
  auth: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    SET_AUTH_RESPONSE: (state, action: PayloadAction<AuthResponse>) => {
      state.auth = action.payload;
    },
  },
});

export const { actions: authActions } = authSlice;

export default authSlice.reducer;
