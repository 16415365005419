class StorageService {
  storage: Storage | undefined;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  setItem = (keyName: string, keyValue: string) => {
    this.storage?.setItem(keyName, keyValue);
  };

  getItem = (keyName: string) => {
    return this.storage?.getItem(keyName) ?? null;
  };

  removeItem = (keyName: string) => {
    this.storage?.removeItem(keyName);
  };
}

export default StorageService;
