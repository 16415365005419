import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import {
  OrganizationGroup,
  OrganizationsForUserParams,
  SharedOrganization as ContactOrganization,
} from 'types/contact.types';
import { Organization, OrganizationUsersParams } from 'types/organization.types';
import { OrganizationUser, User } from 'types/user.types';

import RestApiService from '.';

export function fetchOrganizationUsers(
  this: RestApiService,
  organizationId: Organization['id'],
  params?: OrganizationUsersParams,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<OrganizationUser[]>>(`/organizations/${organizationId}/users`, {
    params: cleanParams,
  });
}
export function fetchOrganizationDetails(this: RestApiService, organizationId: Organization['id']) {
  return this.api.get<Organization>(`/organizations/${organizationId}`);
}

export function fetchOrganizationsForUser(
  this: RestApiService,
  userId: User['id'],
  params?: OrganizationsForUserParams,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<ContactOrganization[]>>(`/users/${userId}/organizations`, {
    params: cleanParams,
  });
}

export function fetchOrganizationGroups(this: RestApiService) {
  return this.api.get<ApiResponse<OrganizationGroup[]>>(`/organizationgroups`);
}
