import { matchPath, RouteProps } from 'react-router-dom';

import FeatureFlagService from 'services/feature-flags/FeatureFlagService';
import { UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

export const menuAccessible = (
  features: FeatureFlagService,
  item: { userFeatureFlag: UserFeatureFlags },
) => !item.userFeatureFlag || features.isEnabled(item.userFeatureFlag);

export const isActiveNavItem = (
  location: string,
  item: { route: Routes; aliasRoutes?: Routes[] },
  props: RouteProps = { exact: true },
) => {
  return !!matchPath(location, {
    path: item.aliasRoutes ? [item.route, ...item.aliasRoutes] : item.route,
    ...props,
  });
};
