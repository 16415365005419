import type { AxiosResponse } from 'axios';

import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { Brand, BrandParams, CreateBrandParams, UpdateBrandParams } from 'types/brand.types';
import { Organization } from 'types/organization.types';

import RestApiService from '.';

export function fetchOrganizationBrand(
  this: RestApiService,
  organizationId: Organization['id'],
  brandId: Brand['id'],
) {
  return this.api.get<Brand>(`/organizations/${organizationId}/brands/${brandId}`);
}

export function fetchOrganizationBrands(
  this: RestApiService,
  organizationId: Organization['id'],
  params?: BrandParams,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<Brand[]>>(`/organizations/${organizationId}/brands`, {
    params: cleanParams,
  });
}

export function createOrganizationBrand(
  this: RestApiService,
  organizationId: Organization['id'],
  brand: CreateBrandParams,
) {
  return this.api.post<FormData, AxiosResponse<Brand>>(
    `/organizations/${organizationId}/brands`,
    brand,
  );
}

export function updateOrganizationBrand(
  this: RestApiService,
  organizationId: Organization['id'],
  brandId: Brand['id'],
  brand: UpdateBrandParams,
) {
  return this.api.put<AxiosResponse<Brand>>(
    `/organizations/${organizationId}/brands/${brandId}`,
    brand,
  );
}

export function deleteOrganizationBrand(
  this: RestApiService,
  organizationId: Organization['id'],
  brandId: Brand['id'],
) {
  return this.api.delete<Brand>(`/organizations/${organizationId}/brands/${brandId}`);
}
