import Axios, { AxiosInstance, Canceler } from 'axios';

import { isAuthUrl } from './utils';

let tokenCallBusy = false;
let cancel: Canceler;

const TOKEN_URL = '/token';

export const createAuthCancelDuplicates = (axios: AxiosInstance) => {
  axios.interceptors.request.use(async request => {
    const { url, baseURL } = request;
    if (isAuthUrl(baseURL) && url === TOKEN_URL) {
      request.cancelToken = new Axios.CancelToken(c => {
        cancel = c;
      });

      if (tokenCallBusy && cancel) {
        cancel('DUPLICATE');
      }
      tokenCallBusy = true;

      return request;
    } else {
      return request;
    }
  });

  axios.interceptors.response.use(
    async response => {
      const { url, baseURL } = response.config;
      if (isAuthUrl(baseURL) && url === TOKEN_URL) {
        tokenCallBusy = false;
      }
      return response;
    },
    async error => {
      if (error?.request?.responseURL.includes('token')) {
        tokenCallBusy = false;
      }
      return Promise.reject(error);
    },
  );
};
