import { useQuery } from '@tanstack/react-query';

import { StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { useCurrentUser } from 'queries/user/useCurrentUser';
import { apiService } from 'services';
import { User } from 'types/user.types';

const fetchSettings = async (userId: User['id']) => {
  const { data } = await apiService.fetchSettings(userId);
  return data;
};

export const useUserSettings = () => {
  const user = useCurrentUser();
  const { data } = useQuery({
    queryKey: QueryKeys.user.settings,
    queryFn: () => fetchSettings(user.id),
    enabled: !!user,
    staleTime: StaleTime.FOREVER,
  });

  return {
    settings: data,
    kioskSettings: data?.kioskSettings,
    kioskDates: data?.kioskSettings?.dates,
    contentSettings: data?.contentSettings,
  };
};
