import axios, { AxiosRequestConfig } from 'axios';
import qs from 'query-string';

import config from 'config';

import {
  transformRequest,
  transformResponse,
} from 'services/api/axios/transformers/body-key.transformer';
import { Dictionary } from 'types/util.types';

import { REQUEST_TIMEOUT_DURATION } from './constants';
import interceptors from './interceptors';
import { createStrapiAuthInterceptor } from './interceptors/request/strapi-auth.interceptor';

const lowerCaseKeys = (object: Dictionary<string>) =>
  Object.fromEntries(Object.entries(object).map(([k, v]) => [k.toLowerCase(), v]));

const DEFAULT_CONFIG: AxiosRequestConfig = {
  baseURL: config.API_BASE,
  headers: {
    Accept: 'application/vnd.api+json, text/plain, */*;',
    'Content-Type': 'application/json',
  },
  timeout: REQUEST_TIMEOUT_DURATION,
  // Encodes parameters and lowercase the query parameter keys
  paramsSerializer: params => qs.stringify(lowerCaseKeys(params), { arrayFormat: 'comma' }),
  transformResponse: [transformResponse],
  transformRequest: [transformRequest],
};
/**
 * Creates an Axios Instance that can talk with the API without authentication
 * @param config AxiosRequestConfig
 */
const createPublicManager = (config?: AxiosRequestConfig) =>
  axios.create({ ...DEFAULT_CONFIG, ...config });

const createStrapiManager = (config?: AxiosRequestConfig) => {
  const manager = axios.create({ ...DEFAULT_CONFIG, ...config });
  createStrapiAuthInterceptor(manager);
  return manager;
};

const createManager = (config?: AxiosRequestConfig) => {
  const manager = createPublicManager(config);

  // Interceptors
  interceptors.forEach(interceptor => interceptor(manager));

  return manager;
};

export { createManager, createPublicManager, createStrapiManager };
