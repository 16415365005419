import { AxiosInstance } from 'axios';

import { getStoreState } from 'store';
import { getAccessToken } from 'store/auth/auth.selector';

import { isApiUrl } from './utils';

export const createHeaderRequestInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use(async request => {
    const accessToken = getAccessToken(getStoreState());
    const { baseURL } = request;
    // Only inject the access token for api urls
    if (isApiUrl(baseURL)) {
      request.headers = request.headers ?? {};
      request.headers.Authorization = `Bearer ${accessToken}`;
    }

    return request;
  });
};
