import type { AxiosResponse } from 'axios';

import { ApiResponse } from 'types/api.types';
import {
  NewSavedSearchParams,
  Params,
  SavedSearch,
  UpdateSavedSearchParams,
} from 'types/saved-search.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchSavedSearches(this: RestApiService, userId: User['id'], params: Params) {
  return this.api.get<ApiResponse<SavedSearch[]>>(`/users/${userId}/savedsearches`, { params });
}

export function fetchSavedSearch(
  this: RestApiService,
  userId: User['id'],
  savedSearchId: SavedSearch['id'],
) {
  return this.api.get<SavedSearch>(`/users/${userId}/savedsearches/${savedSearchId}`);
}

export function createSavedSearch(
  this: RestApiService,
  userId: User['id'],
  params: NewSavedSearchParams,
) {
  return this.api.post<NewSavedSearchParams, AxiosResponse<SavedSearch>>(
    `/users/${userId}/savedsearches`,
    params,
  );
}

export function updateSavedSearch(
  this: RestApiService,
  userId: User['id'],
  savedSearchId: SavedSearch['id'],
  params: UpdateSavedSearchParams,
) {
  return this.api.put<UpdateSavedSearchParams, AxiosResponse<SavedSearch>>(
    `/users/${userId}/savedsearches/${savedSearchId}`,
    params,
  );
}

export function deleteSavedSearch(
  this: RestApiService,
  userId: User['id'],
  savedSearchId: SavedSearch['id'],
) {
  return this.api.delete<SavedSearch>(`/users/${userId}/savedsearches/${savedSearchId}`);
}
