import humps from 'humps';

import { Dictionary } from 'types/util.types';

type Convert = (key: string) => string;

const ignoreUppercaseFields = (key: string, convert: Convert) =>
  /^[A-Z]+$/.test(key) ? key : convert(key);

export const transformRequest = (data: Dictionary<unknown>) => {
  // convert all body objects to strings
  return typeof data === 'object' && !(data instanceof FormData) ? JSON.stringify(data) : data;
};

export const transformResponse = (data: string) => {
  if (data) {
    try {
      // convert all body keys to camelCase
      return humps.camelizeKeys(JSON.parse(data), ignoreUppercaseFields);
    } catch (error) {
      // We couldn't parse the response from JSON
      return data;
    }
  }

  return null;
};
