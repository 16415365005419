import { Box, Button, Typography } from '@mui/material';
import { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';

import { isDev } from 'utils/env.utils';

import Icon, { IconNames } from 'components/@icons';

import { Description, ErrorWrap, Wrap } from './ErrorMessage.style';

interface ErrorMessageProps {
  errorIcon?: IconNames;
  title: I18nKey;
  titleValues?: ComponentProps<typeof FormattedMessage>['values'];
  description: I18nKey;
  descriptionValues?: ComponentProps<typeof FormattedMessage>['values'];
  primaryCta?: {
    label: I18nKey;
    icon?: IconNames;
    values?: ComponentProps<typeof FormattedMessage>['values'];
    onClick: () => void;
  };
  secondaryCta?: {
    label: I18nKey;
    icon?: IconNames;
    values?: ComponentProps<typeof FormattedMessage>['values'];
    onClick: () => void;
  }[];
  error?: Error;
  fullscreen?: boolean;
  maxWidth?: number;
}

const ErrorMessage = ({
  errorIcon = 'IcoWarningFilled',
  title,
  titleValues,
  description,
  descriptionValues,
  primaryCta,
  secondaryCta,
  error,
  fullscreen = false,
  maxWidth = 560,
}: ErrorMessageProps) => {
  return (
    <Wrap
      sx={{ ...(fullscreen ? { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 } : {}) }}
    >
      <ErrorWrap maxWidth={maxWidth}>
        <Box color="accent01.400">
          <Icon size={48} name={errorIcon} />
        </Box>
        <Box>
          <Typography align="center" variant="heading03" fontWeight="700" color="neutral01.200">
            <FormattedMessage id={title} values={titleValues} />
          </Typography>
          <Description align="center" variant="paragraph01" color="neutral01.300">
            <FormattedMessage id={description} values={descriptionValues} />
          </Description>
        </Box>
        {primaryCta && (
          <Button
            variant="contained"
            onClick={primaryCta.onClick}
            startIcon={primaryCta.icon && <Icon name={primaryCta.icon} />}
          >
            <FormattedMessage id={primaryCta.label} values={primaryCta.values} />
          </Button>
        )}
        {secondaryCta &&
          secondaryCta.map(({ label, icon, values, onClick }, index) => (
            <Button
              key={index}
              onClick={onClick}
              startIcon={icon && <Icon name={icon} />}
              variant="text"
              color="primary"
            >
              <Typography variant="paragraph01">
                <FormattedMessage id={label} values={values} />
              </Typography>
            </Button>
          ))}
      </ErrorWrap>

      {isDev() && error && (
        <Box mt={2}>
          <pre>{error.message || '-'}</pre>
          <pre>{error.stack}</pre>
        </Box>
      )}
    </Wrap>
  );
};

export default ErrorMessage;
