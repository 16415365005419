import type { AxiosResponse } from 'axios';

import { ApiResponse } from 'types/api.types';
import { Tag, TagSettings, TagSettingsParams } from 'types/tag.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function createTagSettings(
  this: RestApiService,
  userId: User['id'],
  tagId: Tag['id'],
  tagSettings: TagSettingsParams,
) {
  return this.api.post<TagSettingsParams, AxiosResponse<TagSettings>>(
    `/users/${userId}/tags/${tagId}/settings`,
    tagSettings,
  );
}

export function fetchTagSettings(this: RestApiService, userId: User['id'], tagId: Tag['id']) {
  return this.api.get<ApiResponse<TagSettings[]>>(`/users/${userId}/tags/${tagId}/settings`);
}

export function updateTagSettings(
  this: RestApiService,
  userId: User['id'],
  tagId: Tag['id'],
  tagSettingId: TagSettings['id'],
  params: TagSettingsParams,
) {
  return this.api.put<TagSettingsParams, AxiosResponse<TagSettings>>(
    `/users/${userId}/tags/${tagId}/settings/${tagSettingId}`,
    params,
  );
}

export function deleteTagSettings(
  this: RestApiService,
  userId: User['id'],
  tagId: Tag['id'],
  tagSettingId: TagSettings['id'],
) {
  return this.api.delete(`/users/${userId}/tags/${tagId}/settings/${tagSettingId}`);
}
