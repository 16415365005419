import type { AxiosResponse } from 'axios';

import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { Analysis, SavedAnalysisBody, SavedAnalysisResponse } from 'types/analysis.types';

import RestApiService from '.';

export function createGraph(this: RestApiService, params: SavedAnalysisBody) {
  return this.api.post<SavedAnalysisBody, AxiosResponse<SavedAnalysisResponse>>(
    `/newslettergraphs`,
    removeEmptyValuesFromParams(params),
  );
}

export function updateGraph(
  this: RestApiService,
  analysisId: Analysis['id'],
  params: SavedAnalysisBody,
) {
  return this.api.put<SavedAnalysisBody, AxiosResponse<SavedAnalysisResponse>>(
    `/newslettergraphs/${analysisId}`,
    params,
  );
}

export function fetchGraphById(this: RestApiService, graphId: Analysis['id']) {
  return this.api.get<SavedAnalysisResponse>(`/newslettergraphs/${graphId}`);
}
