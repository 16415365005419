import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import config from 'config';

import { isDev, isProd, isStag, runInDev } from 'utils/env.utils';

import AnalyticsProvider from 'services/analytics/AnalyticsProvider';
import initializeApp from 'services/boot/boot';
import { FeatureGateProvider } from 'services/feature-flags/context';
import BelgaIntlProvider from 'services/i18n/BelgaIntlProvider';
import MarkTextProvider from 'services/markText/MarkTextProvider';
import NotificationProvider from 'services/notifications/NotificationProvider';
import PosthogProvider from 'services/Posthog/PosthogProvider';
import { persistOptions, queryClient } from 'services/react-query/config';
import ThemeProvider from 'services/Theme';
import { persistor, store } from 'store';
import startSearchListeners from 'store/search/search.listeners';

import Pages from 'pages/Pages';

import { GenericErrorBoundary, RootBoundary } from 'components/@boundaries';
import { LoadingState } from 'components/@state/LoadingState';

if (isProd() || isStag()) Sentry.init(config.SENTRY);

runInDev(() => {
  // Enable to start mocking!
  // const { worker } = require('./mocks/browser');
  // worker.start({ onUnhandledRequest: 'bypass' });
});

const App = () => {
  return (
    <RootBoundary>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
          <ThemeProvider>
            <ReduxProvider store={store}>
              <PersistGate
                loading={<LoadingState />}
                persistor={persistor}
                onBeforeLift={() => {
                  startSearchListeners();
                  return initializeApp(store);
                }}
              >
                <BelgaIntlProvider>
                  <GenericErrorBoundary boundary="providers" fullscreen>
                    <DndProvider backend={HTML5Backend}>
                      <HelmetProvider>
                        <NotificationProvider>
                          <AnalyticsProvider trackingId={config.ANALYTICS_GOOGLE_TRACKING_ID}>
                            <FeatureGateProvider>
                              <PosthogProvider>
                                <MarkTextProvider>
                                  <Pages />
                                </MarkTextProvider>
                              </PosthogProvider>
                            </FeatureGateProvider>
                          </AnalyticsProvider>
                        </NotificationProvider>
                      </HelmetProvider>
                    </DndProvider>
                  </GenericErrorBoundary>
                </BelgaIntlProvider>
              </PersistGate>
            </ReduxProvider>
          </ThemeProvider>

          {isDev() && <ReactQueryDevtools initialIsOpen={false} position="bottom" />}
        </PersistQueryClientProvider>
      </LocalizationProvider>
    </RootBoundary>
  );
};

export default App;
