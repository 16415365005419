import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrap = styled(Stack)(({ theme }) => ({
  zIndex: 1250,
  backgroundColor: theme.palette.neutral01[700],
  width: '100%',
}));

export const InnerContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',

  zIndex: 1251,
  boxShadow: `1px 1px 6px ${theme.palette.boxShadow}`,
  height: theme.mixins.appBar?.mobileHeight,
}));
