import { OptionsObject, SnackbarKey, SnackbarProvider } from 'notistack';

import DefaultNotification from './DefaultNotification';
import { NotificationWithType } from './types';

/**
 * This is only used in places where we don't have access to hooks
 * A very hacky way, passing around refs like this will cause for mem leaks
 * @deprecated useNotificationService hook or properly implement this in redux state
 */
class NotificationService {
  snackbarRef: SnackbarProvider | undefined;
  public setSnackbarRef(ref: SnackbarProvider | null) {
    if (ref) {
      this.snackbarRef = ref;
    }
  }

  public add(notification: NotificationWithType, config?: OptionsObject) {
    if (this.snackbarRef) {
      return this.snackbarRef.enqueueSnackbar(<DefaultNotification notification={notification} />, {
        key: notification.title + notification.message,
        ...config,
      });
    }
  }

  public close(notificationId?: SnackbarKey) {
    if (this.snackbarRef && !!notificationId) {
      this.snackbarRef.closeSnackbar(notificationId);
    }
  }
}

export default NotificationService;
