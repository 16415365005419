import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import {
  ExportInsightsParams,
  insightsParams,
  PurchasedArticlesInsights,
} from 'types/purchased-articles-insights.types';

import RestApiService from '.';

export function fetchPurchasedArticlesInsights(this: RestApiService, params: insightsParams) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<PurchasedArticlesInsights>('/commerce/purchases/newsobjects/insights', {
    params: cleanParams,
  });
}

export async function fetchExportPurchasedArticles(
  this: RestApiService,
  params?: ExportInsightsParams,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<Blob>(`/commerce/purchases/newsobjects/export`, {
    headers: { Accept: 'text/csv', 'Content-Type': 'text/csv' },
    data: null, // need to set data to explicitly because otherwise axios does not send the content-type header
    responseType: 'blob',
    params: cleanParams,
  });
}
