import { AxiosResponse } from 'axios';

import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import {
  Board,
  BoardAutolabels,
  BoardAutolabelsParams,
  BoardOrderParams,
  BoardParams,
  BoardSearchParams,
  BoardSection,
  BoardSourceSettings,
  BoardSourceSettingsParams,
  MinimalBoard,
  NotificationParams,
  NotificationSettings,
} from 'types/board.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export default {
  fetchBoards(this: RestApiService, userId: User['id'], dirtyParams: BoardSearchParams) {
    const params = removeEmptyValuesFromParams(dirtyParams);
    return this.api.get<ApiResponse<MinimalBoard[]>>(`/users/${userId}/boards`, { params });
  },

  fetchBoard(this: RestApiService, boardUuid: Board['uuid']) {
    return this.api.get<Board>(`/boards/${boardUuid}`);
  },

  createBoard(this: RestApiService, params: BoardParams) {
    return this.api.post<BoardParams, AxiosResponse<Board>>(`/boards`, params);
  },

  deleteBoard(this: RestApiService, boardUuid: Board['uuid']) {
    return this.api.delete(`/boards/${boardUuid}`);
  },

  updateBoard(this: RestApiService, boardUuid: Board['uuid'], params: BoardParams) {
    return this.api.put<BoardParams, AxiosResponse<Board>>(`/boards/${boardUuid}`, params);
  },

  orderUserBoards(this: RestApiService, userId: User['id'], boardOrders: BoardOrderParams[]) {
    return this.api.put(`/users/${userId}/boards/order`, boardOrders);
  },

  detachBoard(this: RestApiService, boardUuid: Board['uuid'], userId: User['id']) {
    return this.api.delete(`/boards/${boardUuid}/collaborators/${userId}`);
  },

  fetchBoardSections(this: RestApiService) {
    return this.api.get<ApiResponse<BoardSection[]>>('/sections');
  },

  fetchBoardNotificationSettings(
    this: RestApiService,
    userId: User['id'],
    boardUuid: Board['uuid'],
  ) {
    return this.api.get<NotificationSettings>(
      `/users/${userId}/boards/${boardUuid}/notificationsettings`,
    );
  },

  updateBoardNotificationSettings(
    this: RestApiService,
    userId: User['id'],
    boardUuid: Board['uuid'],
    params: NotificationParams,
  ) {
    return this.api.put<NotificationParams, AxiosResponse<NotificationSettings>>(
      `/users/${userId}/boards/${boardUuid}/notificationsettings`,
      params,
    );
  },

  fetchBoardAutolabels(this: RestApiService, userId: User['id'], boardUuid: Board['uuid']) {
    return this.api.get<BoardAutolabels>(`/users/${userId}/boards/${boardUuid}/autolabels`);
  },

  updateBoardAutoLabels(
    this: RestApiService,
    userId: User['id'],
    boardUuid: Board['uuid'],
    params: BoardAutolabelsParams,
  ) {
    return this.api.put<BoardAutolabelsParams, AxiosResponse<BoardAutolabels>>(
      `/users/${userId}/boards/${boardUuid}/autolabels`,
      params,
    );
  },

  fetchBoardSourceSettings(this: RestApiService, boardUuid: Board['uuid']) {
    return this.api.get<BoardSourceSettings>(`/boards/${boardUuid}/sourcesettings`);
  },

  updateBoardSourceSettings(
    this: RestApiService,
    boardUuid: Board['uuid'],
    params: BoardSourceSettingsParams,
  ) {
    return this.api.put<BoardSourceSettingsParams, AxiosResponse<BoardSourceSettings>>(
      `/boards/${boardUuid}/sourcesettings`,
      params,
    );
  },
};
