import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import { getPrioritizedLanguages } from 'utils/newsroom.utils';

import { NewsroomLanguages } from 'types/newsroom.types';

type TopicFiltersState = {
  languages: NewsroomLanguages[];
};

const INITIAL_STATE: TopicFiltersState = {
  languages: getPrioritizedLanguages(Object.values(NewsroomLanguages)),
};

export const topicFiltersPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.topic-filters`,
  version: 1,
  storage,
};

const topicFiltersSlice = createSlice({
  name: 'topicFilters',
  initialState: INITIAL_STATE,
  reducers: {
    TOGGLE_LANGUAGE: (state, action: PayloadAction<NewsroomLanguages>) => {
      const language = action.payload;
      if (state.languages.includes(language)) {
        state.languages = getPrioritizedLanguages(state.languages.filter(l => l !== language));
      } else {
        state.languages = getPrioritizedLanguages([...state.languages, language]);
      }
    },
    SET_LANGUAGES: (state, action: PayloadAction<NewsroomLanguages[]>) => {
      state.languages = action.payload;
    },
  },
});

export const { actions: topicActions } = topicFiltersSlice;

export default topicFiltersSlice.reducer;
