import { useQuery } from '@tanstack/react-query';

import { StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';

const fetchCurrentUser = async () => {
  const { data } = await apiService.fetchCurrentUser();
  return data;
};

export const useCurrentUser = () => {
  const { data } = useQuery({
    queryKey: QueryKeys.user.current,
    queryFn: fetchCurrentUser,
    staleTime: StaleTime.FOREVER,
  });

  return data!;
};
