import { Box, CircularProgress } from '@mui/material';
import { PropsWithChildren } from 'react';

const LoadingState = ({ children }: PropsWithChildren) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexGrow="1"
    height="100%"
    overflow="hidden"
    flexDirection="column"
  >
    <CircularProgress />
    {children}
  </Box>
);

export default LoadingState;
