import { colorSchemes } from '@nivo/colors';

import { palette } from 'theme/colors';

export const CHART_COLORS = [
  palette.graph['000'],
  palette.graph['600'],
  palette.graph['300'],
  palette.graph['400'],
  palette.graph['700'],
  palette.graph['800'],
  palette.graph['100'],
  palette.graph['500'],
  palette.graph['200'],
  palette.graph['900'],
  // adding a bunch of other colors in case we have a lot of different data points (eg in a source chart with lots of different sources)
  ...colorSchemes.set3,
  ...colorSchemes.set2,
  ...colorSchemes.set1,
  ...colorSchemes.category10,
];

export const NEWSLETTER_STATISTICS_CHART_COLORS = [CHART_COLORS[0], CHART_COLORS[9]];
