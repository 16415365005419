import { AiResponse } from 'types/ai-toolkit.types';

import RestApiService from '.';

export function improveWriting(this: RestApiService, text: string) {
  return this.api.post<AiResponse>('/ai/improve', { text });
}
export function lengthenText(this: RestApiService, text: string) {
  return this.api.post<AiResponse>('/ai/lengthen', { text });
}
export function shortenText(this: RestApiService, text: string) {
  return this.api.post<AiResponse>('/ai/shorten', { text });
}
export function spellCheck(this: RestApiService, text: string) {
  return this.api.post<AiResponse>('/ai/spellcheck', { text });
}
export function translate(this: RestApiService, text: string, language: string) {
  return this.api.post<AiResponse>('/ai/translate', { text, language });
}
