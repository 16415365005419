export const getFormData = <T extends { [index: string]: string | Blob | number[] | string[] }>(
  object: T,
) =>
  Object.keys(object).reduce((formData, key) => {
    const value = object[key];
    if (!value) return formData;

    // Ensure array fiels are comma seperated
    Array.isArray(value) ? formData.append(key, value.join(',')) : formData.append(key, value);

    return formData;
  }, new FormData());
