import { Components, tableRowClasses, Theme } from '@mui/material';

export const MuiTableRow: Components<Theme>['MuiTableRow'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [`&.${tableRowClasses.hover}`]: {
        '&:hover': {
          backgroundColor: theme.palette.global01[700],
        },
      },

      [`${tableRowClasses.selected}`]: {
        backgroundColor: 'unset',
        ':hover': {
          backgroundColor: theme.palette.global01[700],
        },
        '& td': {
          color: theme.palette.primary.dark,
          fontWeight: 600,
        },
      },
    }),
    head: () => ({
      backgroundColor: 'unset',
    }),
  },
};
