import { Grid, List, ListItem, styled } from '@mui/material';
import { NavLinkProps } from 'react-router-dom';

export const Wrap = styled(Grid)(({ theme }) => ({
  display: 'flex',
  minHeight: 32,
  maxHeight: 32,
  backgroundColor: theme.palette.global01[700],
  ...theme.typography.paragraph02,

  '@media print': {
    display: 'none',
  },
}));

export const Nav = styled(List)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  padding: 0,
});

export const NavItem = styled(ListItem)<NavLinkProps>(({ theme }) => ({
  color: theme.palette.neutral01[300],
  paddingTop: 7,
  paddingBottom: 7,
  width: 'auto',
  display: 'flex',

  '&:hover': {
    color: theme.palette.neutral01[200],
  },

  '&.active': {
    color: theme.palette.neutral01[200],
    fontWeight: 600,
  },
}));
