import { useState } from 'react';

interface Props {
  onError?: (error?: unknown) => void;
  onSuccess?: () => void;
}

const useCopyToClipboard = ({ onError, onSuccess }: Props = {}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copy = async (text: string): Promise<boolean> => {
    if (isCopied) return false;
    if (!navigator?.clipboard) {
      onError?.();
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);

      onSuccess?.();

      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);

      return true;
    } catch (error) {
      onError?.(error);
      return false;
    }
  };

  return { isCopied, copy };
};

export default useCopyToClipboard;
