import { browserTracingIntegration, extraErrorDataIntegration } from '@sentry/react';

import EnvService from 'services/env/EnvService';
import { ALLOWED_URLS, BLOCKED_ERRORS } from 'services/sentry/constants';
import { UserFeatureFlags, UserLanguages } from 'types/user.types';

export default new EnvService({
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',
  BUILD_ENV: process.env.REACT_APP_BUILD_ENV || '',
  ENABLE_DEV_TOOLS: process.env.REACT_APP_ENABLE_DEV_TOOLS
    ? process.env.REACT_APP_ENABLE_DEV_TOOLS === 'true'
    : true,
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY || 'EUR',
  DEFAULT_LOCALE: (process.env.REACT_APP_DEFAULT_LOCALE as UserLanguages) || UserLanguages.EN,
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE || 'en',
  // storage
  SESSION_STORAGE_ID_REDIRECT: process.env.REACT_APP_SESSION_STORAGE_ID_REDIRECT || '',
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID || 'nf-bena-0002',
  LOCAL_REACT_QUERY_STORAGE_ID:
    process.env.REACT_APP_LOCAL_REACT_QUERY_STORAGE_ID || 'nf-bena-0002-react-query',
  // error tracking
  SENTRY: {
    dsn: 'https://cd65f07d941c46f18925ca4c5084d787@o190235.ingest.sentry.io/5264252',
    environment: process.env.REACT_APP_BUILD_ENV,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    integrations: [extraErrorDataIntegration(), browserTracingIntegration()],
    tracesSampleRate: 0.01,
    normalizeDepth: 6,
    allowUrls: ALLOWED_URLS,
    ignoreErrors: BLOCKED_ERRORS,
    beforeSend: (event, hint) => {
      const errorMessage = (hint?.originalException as { message?: string })?.message;
      if (
        errorMessage &&
        BLOCKED_ERRORS.some(blockedError => errorMessage && errorMessage.includes(blockedError))
      ) {
        return null;
      }

      if (
        event?.message &&
        BLOCKED_ERRORS.some(blockedError => event?.message && event.message.includes(blockedError))
      ) {
        return null;
      }

      if (
        event?.exception?.values?.some(exception =>
          BLOCKED_ERRORS.some(
            blockedError => exception?.value && blockedError.includes(exception.value),
          ),
        )
      ) {
        return null;
      }

      return event;
    },
    beforeBreadcrumb: (breadcrumb, hint) => {
      // If the clicked element (or one of its parents) has a data-testid, use that as the message instead of the selector
      if (breadcrumb.category === 'ui.click') {
        const testid = hint?.event?.target?.closest('[data-testid]')?.dataset?.testid;
        if (testid) {
          breadcrumb.message = testid;
        }
      }

      return breadcrumb;
    },
  },
  // Feature Flags
  API_FEATURE_FLAGS: Object.values(UserFeatureFlags),
});
